import {QueryClient, QueryObserverOptions, useQueries, useQuery, useQueryClient} from "@tanstack/react-query";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {GraphUserData, Guid} from "common";
import {GraphApi} from "../../apis/Graph/GraphApi";
import hash from "object-hash";
import {useCallback, useMemo} from "react";

export const usersCacheKey = "users";

const getUserQueryArgs = (id: Immutable<Guid> | undefined): QueryObserverOptions<Immutable<GraphUserData> | undefined> => ({
    queryKey: [usersCacheKey, id ?? "null"],
    queryFn: () => GraphApi.getUser(id!),
    staleTime: Infinity,
    enabled: !!id,
});

export const useUsersCache = (ids: Immutable<Array<Guid> | undefined> | undefined) => {
    const queryClient = useQueryClient();
    const validIds = useMemo(() => ids?.filter(id => typeof id === "string" && id) as Immutable<Guid>[], [ids]);

    const results = useQueries({
        queries: validIds?.map(getUserQueryArgs) ?? []
    });

    const isLoading = results.some(r => r.isLoading || r.isFetching);
    const itemsData = !validIds ? undefined : results.map(r => r.data).filter(Boolean) as Immutable<GraphUserData>[];
    const itemsHash = hash.sha1(itemsData || null);

    const users = useMemo(() => itemsData?.filter(Boolean), [itemsHash]);

    const whenReady = useCallback(() => new Promise<Immutable<GraphUserData>[]>(resolve => {
        if (!validIds || validIds.length === 0) return resolve([]);
        if (users?.length === validIds.length) return resolve(users);
        const interval = setInterval(() => {
            const localUsers = validIds.map(id => getLocalUserData(queryClient, id));
            if (!localUsers?.every(Boolean)) return;
            clearInterval(interval);
            resolve(localUsers as Immutable<GraphUserData>[]);
        }, 500);
    }), [validIds]);

    return {
        users,
        isLoading,
        whenReady,
    };
};

export const useUserCache = (id: Immutable<Guid> | undefined) => {
    const queryClient = useQueryClient();
    const {data: user, isLoading} = useQuery(getUserQueryArgs(id));

    const whenReady = useCallback(() => new Promise<Immutable<GraphUserData> | undefined>(resolve => {
        if (!id) return resolve(undefined);
        if (user) return resolve(user);
        const interval = setInterval(() => {
            const localUser = getLocalUserData(queryClient, id);
            if (!localUser) return;
            clearInterval(interval);
            resolve(localUser);
        }, 500);
    }), [id]);

    return {user, isLoading, whenReady};
}

const getLocalUserData = (queryClient: QueryClient, userId: Immutable<Guid> | undefined) => {
    return queryClient.getQueryData<GraphUserData>(getUserQueryArgs(userId).queryKey);
}