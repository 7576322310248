import {QueryClient, useQuery} from "@tanstack/react-query";
import {getConfig} from "services/ConfigurationService/ConfigurationService";
import {usePropertySelectorRendering} from "../usePropertySelectorRendering";
import {msTeamsCacheKey} from "../useMsTeams";
import {Configuration} from "common";

const configurationCacheKey = "app_confguration";

export const useConfigurationCache = () => {
    const {data: config} = useQuery({
        queryKey: [configurationCacheKey],
        queryFn: getConfig,
        staleTime: Infinity
    });

    return {config};
};

const initialData: Configuration = {
    apiVersion: "",
    appInsightKey: "",
    applicationVersion: "",
    clientId: "",
    graphScopes: "",
    manifestId: "",
    helpPageUrl: "",
    iDidItFormEmailFieldId: "",
    iDidItFormBPFieldId: "",
    appBaseUrl: "",
    feedbackPageUrl: "",
    iDidItFormUrl: "",
    legalNoticesPageUrl: "",
    sharepointSiteBaseUrl: "",
}

export const useConfigurationSelector = <ARG extends (keyof Configuration)[]>(...args: ARG) => {
    return usePropertySelectorRendering({args, getLocalData, initialData, cacheKey: msTeamsCacheKey});
}

const getLocalData = (queryClient: QueryClient) => {
    return queryClient.getQueryData([configurationCacheKey]) as Configuration | undefined;
}

const setLocalData = (queryClient: QueryClient, data: Partial<Configuration>) => {
    const localData = getLocalData(queryClient);
    queryClient.setQueryData([configurationCacheKey], {...localData, ...data});
}