import {NavbarItem, Props, State} from "./Navbar.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {useNavigate} from "react-router-dom";
import {MutableRefObject} from "react";
import {HistoryService} from "services/HistoryService/HistoryService.hook";
import {AppView} from "../../../types/AppView/AppView";
import {AppViews} from "../../../const/AppViews";
import {translations} from "../../../translations";
import {bestPracticesViewCacheKey} from "../../../views/BestPractices/BestPractices";
import {trainingsViewCacheKey} from "../../../views/Trainings/Trainings";
import {homeViewCacheKey} from "../../../views/Home/Home.reducer";
import {getAppConfiguration} from "../../../services/ConfigurationService/ConfigurationService";
import {ScreenModule} from "../../../modules/Screen.module";

export const initialState = (props: Props): State => (
    HistoryService.getComponentState("navbar") ?? {
        activeItem: props.initialActiveItem ?? AppView.Home,
        smallButtons: showSmallButtons(),
    }
);

export const reducer = (config: {
    props: Props,
    buttonsParentContainerRef: MutableRefObject<HTMLDivElement | null>,
    buttonsContainerRef: MutableRefObject<HTMLDivElement | null>,
    isOnMobile: boolean,
}) => ({
    onMount: (reducerData, __, navigate: ReturnType<typeof useNavigate>) => {
        reducer(config).handleNavigateToView(reducerData, undefined, {navigate, id: reducerData.state.activeItem});
    },
    handleMenuItemSelected: (reducerData, [item]: [AppView], navigate: ReturnType<typeof useNavigate>) => {
        reducer(config).handleNavigateToView(reducerData, undefined, {navigate, id: item});
    },
    handleNavigateToView: ({state, setState}, __, extraArg: {
        navigate: ReturnType<typeof useNavigate>,
        id: AppView,
        clearCachedStates?: boolean
    }) => {
        const {navigate, id, clearCachedStates} = extraArg;
        const path = AppViews[id].path;
        if (path === window.location.pathname) {
            config.props.onRefreshLocation?.();
        } else {
            setState({activeItem: id});
            navigate(path);
            if (clearCachedStates) removeCachedStates(id);
            HistoryService.saveComponentState("navbar", state, 100);
        }
    },
    openHelpPage: () => {
        let url = getAppConfiguration()?.helpPageUrl ?? "";
        if (config.isOnMobile) url = "ms-sharepoint://" + encodeURIComponent(url);
        window.open(url, "_blank");
    },
    windowResize: ({state, setState}) => {
        const {buttonsContainerRef, buttonsParentContainerRef} = config;
        zoomOutButtonsContainer({buttonsContainerRef, buttonsParentContainerRef});
        const smallButtons = showSmallButtons();
        if (smallButtons === state.smallButtons) return;
        setState({smallButtons});
    }
}) satisfies MagicReducerObject<State>;

export const zoomOutButtonsContainer = ({buttonsParentContainerRef, buttonsContainerRef}: {
    buttonsParentContainerRef: MutableRefObject<HTMLDivElement | null>,
    buttonsContainerRef: MutableRefObject<HTMLDivElement | null>,
}) => {
    const {width: parentContainerWidth} = ScreenModule.getElementRealSize(buttonsParentContainerRef.current);
    const {width: containerWidth} = ScreenModule.getElementRealSize(buttonsContainerRef.current);
    if (containerWidth < parentContainerWidth) return;
    let zoom = parentContainerWidth / containerWidth;
    buttonsContainerRef.current?.style.setProperty("zoom", zoom.toString());
}

const showSmallButtons = () => {
    const {width} = ScreenModule.getWindowRealSize();
    return width <= 1050;
}

export const generateItems = (isSmallScreen: boolean, isAdmin: boolean | undefined, isCommunityManager: boolean | undefined): Array<NavbarItem> => {
    const views = [];
    if (isSmallScreen) views.push(AppView.Home);
    views.push(AppView.Dashboard, AppView.BestPractices, AppView.Trainings);
    if (isSmallScreen) views.push(AppView.Launchpad);
    if (isAdmin) views.push(AppView.Reporting, AppView.Notifications);
    return views.map((id): NavbarItem => {
        const view = AppViews[id];
        return {
            id,
            icon: view.icon,
            label: translations.get(view.titleId),
        }
    })
}

const removeCachedStates = (currentView: AppView) => {
    switch (currentView) {
        case AppView.Home:
            HistoryService.clearAllStates(bestPracticesViewCacheKey);
            HistoryService.clearAllStates(trainingsViewCacheKey);
            break;
        case AppView.BestPractices:
            HistoryService.clearAllStates(homeViewCacheKey);
            HistoryService.clearAllStates(trainingsViewCacheKey);
            break;
        case AppView.Trainings:
            HistoryService.clearAllStates(bestPracticesViewCacheKey);
            HistoryService.clearAllStates(homeViewCacheKey);
            break;
    }
}