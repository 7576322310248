import React, {ReactElement, useMemo} from "react";
import {BestPracticesModule} from "../../modules/BestPractices.module";
import {translations} from "../../translations";
import {useBestPracticesCache} from "../../hooks/cache/bestPracticesCache";
import {ItemsView} from "../ItemsView/ItemsView";

export const bestPracticesViewCacheKey = "best-practices-view";

export const BestPractices = (): ReactElement => {
    const {bestPractices} = useBestPracticesCache();

    const filterItems = useMemo(() => {
        return BestPracticesModule.generateFilterItems(bestPractices);
    }, [bestPractices]);

    return (
        <ItemsView
            title={translations.get("BestPractices")}
            subtitle={translations.get("BestPracticesSubtitle")}
            items={bestPractices}
            cacheKey={bestPracticesViewCacheKey}
            filterItems={filterItems}
        />
    )
}