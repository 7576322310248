import * as microsoftTeams from "@microsoft/teams-js";
import React, {useCallback, useEffect, useMemo} from "react";
import {App} from "./App";
import {MsTeamsModule} from "./hooks/useMsTeams";

export const ScaledApp = () => {
    const [isAppFromTeams, setIsAppFromTeams] = React.useState<boolean>();
    const [isPhoneOrTablet, setIsPhoneOrTablet] = React.useState<boolean>();
    const [scale, setScale] = React.useState<number>();

    const handleResize = useCallback(() => {
        setScale(getAppScale());
    }, []);

    const handleInitializeTeams = useCallback((interval: NodeJS.Timeout) => {
        MsTeamsModule.isAppFromTeams().then(result => {
            clearInterval(interval);
            setIsAppFromTeams(result);
            if (result) MsTeamsModule.isPhoneOrTablet().then(setIsPhoneOrTablet);
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            handleInitializeTeams(interval);
        }, 300);
        handleInitializeTeams(interval);
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isScaled = useMemo(isAppScaled, [window.location.search]);

    const loaded = isAppFromTeams !== undefined && scale !== undefined;

    useEffect(() => {
        if (loaded && isAppFromTeams) microsoftTeams.app.notifySuccess().then();
    }, [loaded]);

    if (!loaded) return null;

    const showApp = isPhoneOrTablet || !isAppFromTeams || isScaled || scale === 1;

    return showApp ? <App/> : renderScaledApp(scale);
}

const isAppScaled = () => {
    const isScaled = new URLSearchParams(window.location.search).get("isScaled");
    return Boolean(isScaled);
}

const renderScaledApp = (scale: number) => {
    return <iframe
        style={{
            border: "0",
            height: "100%",
            width: "100%",
            zoom: scale
        }}
        src={window.location.origin + "?isScaled=true"}
    />
}

const getAppScale = () => {
    const width = window.innerWidth;
    if (width > 1400) return 1;
    if (width > 1200 && width < 1400) return 0.9;
    if (width > 1000 && width < 1200) return 0.8;
    if (width > 800 && width < 1000) return 0.7;
    if (width > 750 && width < 800) return 0.6;
    return 1;
}