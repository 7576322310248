import {TrainingApi} from "../../apis/Training/TrainingApi";
import {ItemData, ItemDataType, SharepointComment} from "common";
import {BestPracticeApi} from "../../apis/BestPractice/BestPracticeApi";
import {ObjectModule} from "../../modules/Object.module";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {bestPracticesCacheKey} from "./bestPracticesCache";
import {trainingsCacheKey} from "./trainingsCache";
import {QueryClient, useQuery, useQueryClient} from "@tanstack/react-query";
import {UserReportCacheActions} from "./userReportCache";
import {MentionModule} from "../../modules/Mention.module";

export const commentsCacheKey = "comments";

export const useItemCommentsCache = (type: ItemDataType | undefined, id: string | undefined) => {
    const queryClient = useQueryClient();

    const {data: comments, refetch} = useQuery({
        queryKey: [commentsCacheKey, type, id],
        queryFn: fetchItemComments(type, id),
        staleTime: 300000,
        enabled: type !== undefined && id !== undefined,
    });

    return {
        comments,
        publishComment: publishComment(queryClient, refetch)
    };
};

const fetchItemComments = (type: ItemDataType | undefined, id: string | undefined) => async () => {
    if (type === undefined || id === undefined) return undefined;
    let comments = new Array<SharepointComment>();
    switch (type) {
        case ItemDataType.BestPractice:
            comments = await BestPracticeApi.getComments(id);
            break;
        case ItemDataType.Training:
            comments = await TrainingApi.getComments(id);
            break;
    }
    comments.sort((a, b) => new Date(b.createdDate!).getTime() - new Date(a.createdDate!).getTime());
    return comments;
}

const publishComment = (queryClient: QueryClient, refetch: () => Promise<any>) => async (type: ItemDataType | undefined, itemId: string, comment: string) => {
    const formattedComment = formatCommentToSharepoint(comment);
    let isSuccess = false;
    switch (type) {
        case ItemDataType.BestPractice:
            isSuccess = await BestPracticeApi.postComment(itemId, formattedComment);
            break;
        case ItemDataType.Training:
            isSuccess = await TrainingApi.postComment(itemId, formattedComment);
            break;
    }
    if (!isSuccess) return;
    UserReportCacheActions.incrementReportValue("comments");
    incrementCommentsCount(queryClient)(type, itemId);
    await refetch();
}

const incrementCommentsCount = (queryClient: QueryClient) => (type: ItemDataType | undefined, id: string | undefined) => {
    if (type === undefined || id === undefined) return;
    switch (type) {
        case ItemDataType.BestPractice:
            const bestPractices = ObjectModule.deepClone(queryClient.getQueryData<Immutable<Array<ItemData>>>([bestPracticesCacheKey]));
            if (!bestPractices) return;
            const bestPractice = bestPractices.find(item => item.id === id);
            if (!bestPractice) return;
            bestPractice.comments++;
            queryClient.setQueryData([bestPracticesCacheKey], bestPractices);
            break;
        case ItemDataType.Training:
            const trainings = ObjectModule.deepClone(queryClient.getQueryData<Immutable<Array<ItemData>>>([trainingsCacheKey]));
            if (!trainings) return;
            const training = trainings.find(item => item.id === id);
            if (!training) return;
            training.comments++;
            queryClient.setQueryData([trainingsCacheKey], trainings);
            break;
    }
}

const convertHtmlToPlainText = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
};

const formatCommentToSharepoint = (comment: string): string => {
    const extractedMentions = MentionModule.extractMentions(comment);
    let plainText = convertHtmlToPlainText(comment);
    extractedMentions.forEach(mention => {
        const mentionRegex = new RegExp("@" + mention.mentionText, "g");
        const replaceText = `<a data-sp-mention-user-id="${mention.mentionId}">${mention.mentionText}</a>`;
        plainText = plainText.replace(mentionRegex, replaceText);
    });
    return plainText;
}