import {isValidElement, ReactElement} from "react";
import {Immutable} from "@witivio_teamspro/use-reducer";

function areValuesEqual<T>(val1: T, val2: T, deepComparison: boolean = false): boolean {
    if (!val1 && !val2) return true;
    if (!val1 || !val2) return false;
    if (typeof val1 !== typeof val2) return false;
    if (isReactElement(val1, val2))
        return areReactElementsEqual(val1 as unknown as ReactElement, val2 as unknown as ReactElement);
    if (val1 instanceof HTMLElement && val2 instanceof HTMLElement)
        return val1.isEqualNode(val2);
    if (Array.isArray(val1) && Array.isArray(val2))
        return areArraysEqual(val1, val2, deepComparison);
    if (isDate(val1, val2))
        return areDatesEqual(val1 as unknown as Date, val2 as unknown as Date);
    if (isObject(val1, val2))
        return areObjectsEqual(val1, val2, deepComparison);
    return val1 === val2;
}

function isReactElement(val1: unknown, val2: unknown): boolean {
    return isValidElement(val1) && isValidElement(val2);
}

function areReactElementsEqual(reactElement1: ReactElement, reactElement2: ReactElement): boolean {
    const isSameKey = reactElement1.key === reactElement2.key;
    const isSameType = reactElement1.type === reactElement2.type;
    return isSameKey && isSameType && areObjectsEqual(reactElement1.props, reactElement2.props);
}

function isDate(val1: unknown, val2: unknown): boolean {
    return val1 instanceof Date && val2 instanceof Date;
}

function areDatesEqual(date1: Date, date2: Date): boolean {
    return date1.toISOString() === date2.toISOString();
}

function isObject(val1: unknown, val2: unknown): boolean {
    return typeof val1 === 'object' && typeof val2 === 'object';
}

function areObjectsEqual<T extends object | null | undefined>(obj1: T, obj2: T, deepComparison: boolean = false): boolean {
    if (areBothNullish(obj1, obj2)) return true;
    if (isOnlyOneNullish(obj1, obj2)) return false;
    if (!areSameType(obj1, obj2)) return false;
    if (!haveSameKeys(obj1, obj2)) return false;
    return compareObjectKeys(obj1, obj2, deepComparison);
}

function areBothNullish<T>(obj1: T, obj2: T): boolean {
    return !obj1 && !obj2;
}

function isOnlyOneNullish<T>(obj1: T, obj2: T): boolean {
    return !obj1 || !obj2;
}

function areSameType<T>(obj1: T, obj2: T): boolean {
    return typeof obj1 === typeof obj2;
}

function haveSameKeys<T extends object | null | undefined>(obj1: T, obj2: T): boolean {
    const keys1 = Object.keys(obj1 ?? {});
    const keys2 = Object.keys(obj2 ?? {});
    return keys1.length === keys2.length;
}

function compareObjectKeys<T extends object | null | undefined>(obj1: T, obj2: T, deepComparison: boolean): boolean {
    for (const key in obj1) {
        if (obj1?.hasOwnProperty(key)) {
            const val1 = obj1?.[key as never] as any;
            const val2 = obj2?.[key as never] as any;
            if (!areValuesEqual(val1, val2, deepComparison)) return false;
        }
    }
    return true;
}

function areArraysEqual(
    arr1: Immutable<any[]> | null | undefined,
    arr2: Immutable<any[]> | null | undefined,
    deepComparison: boolean = false
): boolean {
    if (areBothNullish(arr1, arr2)) return true;
    if (isOnlyOneNullish(arr1, arr2)) return false;
    if (!haveSameLength(arr1, arr2)) return false;
    if (isEmptyArray(arr1, arr2)) return true;
    if (deepComparison && isObjectArray(arr1)) return deepCompareArrays(arr1, arr2);
    return shallowCompareArrays(arr1, arr2);
}

function haveSameLength(arr1: readonly any[] | null | undefined, arr2: readonly any[] | null | undefined): boolean {
    return arr1?.length === arr2?.length;
}

function isEmptyArray(arr1: readonly any[] | null | undefined, arr2: readonly any[] | null | undefined): boolean {
    return (arr1?.length ?? 0) === 0 && (arr2?.length ?? 0) === 0;
}

function isObjectArray(arr: readonly any[] | null | undefined): boolean {
    return typeof arr?.[0] === 'object';
}

function deepCompareArrays(
    arr1: Immutable<any[]> | null | undefined,
    arr2: Immutable<any[]> | null | undefined
): boolean {
    const visitedIndices = new Set<number>();
    for (let i = 0; i < (arr1?.length ?? 0); i++) {
        const val1 = arr1?.[i];
        let foundMatch = false;
        for (let j = 0; j < (arr2?.length ?? 0); j++) {
            if (visitedIndices.has(j)) continue;
            const val2 = arr2?.[j];
            if (areObjectsEqual(val1, val2, true)) {
                visitedIndices.add(j);
                foundMatch = true;
                break;
            }
        }
        if (!foundMatch) return false;
    }
    return true;
}

function shallowCompareArrays(
    arr1: Immutable<any[]> | null | undefined,
    arr2: Immutable<any[]> | null | undefined
): boolean {
    const sortedArr1 = [...(arr1 ?? [])].sort(compareElements);
    const sortedArr2 = [...(arr2 ?? [])].sort(compareElements);
    for (let i = 0; i < sortedArr1.length; i++) {
        const val1 = sortedArr1[i];
        const val2 = sortedArr2[i];
        if (val1 !== val2) return false;
    }
    return true;
}

function compareElements(a: any, b: any): number {
    if (typeof a === 'number' && typeof b === 'number') return a - b;
    if (typeof a === 'string' && typeof b === 'string') return a.localeCompare(b);
    if (typeof a === 'boolean' && typeof b === 'boolean') return (a === b) ? 0 : a ? 1 : -1;
    if (a instanceof Date && b instanceof Date) return a.getTime() - b.getTime();
    return 0;
}

export const CompareModule = {
    areValuesEqual,
    areObjectsEqual,
    areArraysEqual,
}