import {State} from "./ItemsGrid.types";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {VirtualizedGrid} from "../VirtualizedGrid/VirtualizedGrid";

export const initialState: State = {}

export const reducer = (config: {
    virtualizedGridRef: MagicReducerRef<typeof VirtualizedGrid>
}) => ({
    scrollTop: ({}) => {
        config.virtualizedGridRef.dispatch("scrollTop")();
    }
}) satisfies MagicReducerObject<State>;