import {Immutable} from "@witivio_teamspro/use-reducer";
import moment from "moment/moment";
import {translations} from "../translations";
import {FilterItemsData} from "../components/others/Filter/Filter.types";
import {DeeplinkContext, DeeplinkContextType, ItemData, ItemDataType} from "common";
import {useUserDataCache} from "../hooks/cache/userDataCache";
import {getAppConfiguration} from "../services/ConfigurationService/ConfigurationService";
import {ErrorModule} from "../components/others/ErrorBoundary/ErrorBoundary";
import {SearchAlgorithm} from "../const/SearchAlgorithm";
import {useMyManagerRecommendationsCache} from "../hooks/cache/recommendationsCache";

const getCheckedItems = (filterItems: Immutable<FilterItemsData> | undefined, filterKey: string) => {
    if (!filterItems) return [];
    return Object.keys(filterItems[filterKey] ?? {})
        // @ts-ignore
        .filter(key => filterItems[filterKey]?.[key].isChecked);
}

const removeEmojis = (str: string): string => {
    return str.replace(/\p{Emoji}/gu, "").replace(/^\W+/g, "");
};

const sortItemsByTitle = (items: Immutable<Array<ItemData>>) => {
    return [...items].sort((a, b) => removeEmojis(a.title).localeCompare(removeEmojis(b.title)));
}

function normalizeString(str: string): string {
    let formattedString = str
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9]/gi, ' ')
        .replace(/\s+/g, ' ')
        .trim();
    const wordEquivalence = SearchAlgorithm.wordsEquivalences[formattedString as never] + "";
    if (wordEquivalence !== "undefined") formattedString = wordEquivalence;
    return formattedString;
}

function levenshteinDistance(a: string, b: string): number {
    const matrix: number[][] = [];
    const lenA = a.length;
    const lenB = b.length;
    for (let i = 0; i <= lenA; i++) {
        matrix[i] = [i];
    }
    for (let j = 0; j <= lenB; j++) {
        matrix[0][j] = j;
    }
    for (let i = 1; i <= lenA; i++) {
        for (let j = 1; j <= lenB; j++) {
            const cost = a[i - 1] === b[j - 1] ? 0 : 1;
            matrix[i][j] = Math.min(
                matrix[i - 1][j] + 1,
                matrix[i][j - 1] + 1,
                matrix[i - 1][j - 1] + cost
            );
        }
    }
    return matrix[lenA][lenB];
}

function approximateIncludes(
    sentence: string,
    query: string,
    threshold: number
): number {
    if (!query || !sentence) return 0;
    for (let i = 0; i <= sentence.length - query.length; i++) {
        const fragment = sentence.slice(i, i + query.length);
        const distance = levenshteinDistance(fragment, query);
        if (distance <= threshold) return distance;
    }
    return 999999;
}

type ItemWithScore = {
    item: Immutable<ItemData>;
    exactScores: Array<number>;
    approximateScores: Array<number>;
    exactScore: number;
    approximateScore: number;
    validKeywordsCount: number;
};

type ItemScoreResult = {
    exactScores: Array<number>;
    approximateScores: Array<number>;
    validKeywordsCount: number;
}

type SearchItemsResult = {
    items: Immutable<Array<ItemData>>,
    areSuggestions: boolean,
}

const searchItems = (items: Immutable<Array<ItemData>> | undefined, query: string | undefined): SearchItemsResult => {
    if (!items || !query) return {items: items ?? [], areSuggestions: false};
    let normalizedQuery = normalizeString(query);
    const keywords = new Array<string>();
    Object.values(SearchAlgorithm.wordsEquivalences).forEach(word => {
        if (normalizedQuery.includes(word)) {
            keywords.push(word);
            normalizedQuery = normalizedQuery.replace(word, "");
        }
    });
    keywords.push(...normalizedQuery.split(" ").filter(word => word.length > 0));

    const getLevenshteinScore = (str: string | undefined, keyword: string, weight: number = 1) => {
        const exact = (approximateIncludes(normalizeString(str ?? ""), keyword, 0) * weight) - weight;
        const approximate = exact <= 0 ? 99999 : (approximateIncludes(normalizeString(str ?? ""), keyword, 1) * weight) - weight;
        return {exact, approximate};
    };

    const calculateItemScores = (item: Immutable<ItemData>): ItemScoreResult => {
        let exactScores = new Array<number>();
        let approximateScores = new Array<number>();
        const scoredKeywords = new Set<string>();

        const accumulateScores = (str: string | undefined, weight: number = 1) => {
            keywords.forEach((keyword) => {
                const {exact, approximate} = getLevenshteinScore(str, keyword, weight);
                exactScores.push(exact);
                approximateScores.push(approximate);
                if (exact <= 0) scoredKeywords.add(keyword);
            }, 0);
        };

        const searchProperties = Object.keys(SearchAlgorithm.weights);

        searchProperties.forEach(prop => {
            const itemProp = item[prop as never] as string | Array<string> | undefined;
            const weight = SearchAlgorithm.weights[prop as never];
            if (itemProp && typeof itemProp === "string") {
                accumulateScores(itemProp, weight);
            } else if (itemProp && Array.isArray(itemProp)) {
                itemProp.forEach(i => accumulateScores(i, weight));
            }
        });

        return {
            exactScores: exactScores.filter(s => s <= 0),
            approximateScores,
            validKeywordsCount: scoredKeywords.size,
        }
    };

    const scoredItems: ItemWithScore[] = items.map(item => {
        const {exactScores, approximateScores, validKeywordsCount} = calculateItemScores(item);
        let exactScore = exactScores.reduce((acc, score) => acc + score, 0);
        let approximateScore = approximateScores.reduce((acc, score) => acc + score, 0);
        exactScore -= validKeywordsCount * SearchAlgorithm.validKeywordBonus;
        return {item, exactScores, approximateScores, exactScore, approximateScore, validKeywordsCount};
    });

    const exactItems = scoredItems
        .filter(i => i.validKeywordsCount === keywords.length)
        .sort((a, b) => a.exactScore - b.exactScore)
        .map(entry => entry.item);

    if (exactItems.length > 0) return {items: exactItems, areSuggestions: false};

    const suggestedItems = scoredItems
        .sort((a, b) => a.approximateScore - b.approximateScore)
        .slice(0, SearchAlgorithm.maxSuggestions)
        .map(entry => entry.item);

    return {items: suggestedItems, areSuggestions: true};
};

const filterItems = (data: {
    items: Immutable<Array<ItemData>> | undefined,
    userUpn: string,
    selectedCategoryKey?: string | undefined,
    query?: string | undefined,
    filterItems?: Immutable<FilterItemsData> | undefined,
    hasUserVisitedItem?: ReturnType<typeof useUserDataCache>["hasUserVisitedItem"],
    managerRecommendations?: ReturnType<typeof useMyManagerRecommendationsCache>["managerRecommendations"],
}): SearchItemsResult => {
    const {items, selectedCategoryKey, filterItems, userUpn} = data;
    if (!items) return {items: [], areSuggestions: false};
    let filteredItems = [...items];
    if (selectedCategoryKey) filteredItems = filteredItems.filter(bp => bp.topics.includes(selectedCategoryKey));
    const searchResult = searchItems(filteredItems, data.query);
    filteredItems = [...searchResult.items];
    if (!filterItems) return {items: filteredItems, areSuggestions: searchResult.areSuggestions};
    const checkedAffiliates = getCheckedItems(filterItems, "affiliates");
    if (checkedAffiliates.length > 0) filteredItems = filteredItems.filter(bp => checkedAffiliates.includes(bp.affiliate ?? ""));
    const checkedTopics = getCheckedItems(filterItems, "topic");
    if (checkedTopics.length > 0) filteredItems = filteredItems.filter(bp => bp.topics?.some(t => checkedTopics.includes(t)));
    const checkedJobFamilies = getCheckedItems(filterItems, "job-family");
    if (checkedJobFamilies.length > 0) filteredItems = filteredItems.filter(bp => bp.jobFamilies?.some(t => checkedJobFamilies.includes(t)));
    const checkedTypes = getCheckedItems(filterItems, "type");
    if (checkedTypes.length > 0) filteredItems = filteredItems.filter(bp => bp.types?.some(t => checkedTypes.includes(t)));
    const checkedRatings = getCheckedItems(filterItems, "rating");
    if (checkedRatings.length > 0) filteredItems = filteredItems.filter(bp => checkedRatings.includes(bp.rating?.toString()));
    const checkedSources = getCheckedItems(filterItems, "source");
    if (checkedSources.length > 0) filteredItems = filteredItems.filter(bp => checkedSources.includes(bp.source ?? ""));
    const checkedBrands = getCheckedItems(filterItems, "brand");
    if (checkedBrands.length > 0) filteredItems = filteredItems.filter(bp => checkedBrands.includes(bp.brand ?? ""));
    const checkedFromDate = (filterItems["from-date"]?.isChecked ? filterItems["from-date"].inputValue : "") as string;
    if (checkedFromDate) filteredItems = filteredItems
        .filter(bp => moment(bp.creationDate).startOf("day").valueOf() >= moment(checkedFromDate).startOf("day").valueOf());
    const checkedToDate = (filterItems["to-date"]?.isChecked ? filterItems["to-date"].inputValue : "") as string;
    if (checkedToDate) filteredItems = filteredItems
        .filter(bp => moment(bp.creationDate).startOf("day").valueOf() <= moment(checkedToDate).startOf("day").valueOf());

    if (userUpn && filterItems["already-liked-or-consulted"]?.isChecked) {
        filteredItems = filteredItems.filter(bp => data.hasUserVisitedItem?.(bp.type, bp.id) || bp.likes.includes(userUpn));
    }

    if (filterItems["most-liked"]?.isChecked) {
        filteredItems = filteredItems.filter(bp => bp.likes.length > 0);
        filteredItems.sort((a, b) => b.likes.length - a.likes.length);
    }

    if (filterItems["management-recommendations"]?.isChecked) {
        filteredItems = filteredItems.filter(i => {
            if (i.type === ItemDataType.BestPractice) {
                return data.managerRecommendations?.bestPractices?.includes(i.id);
            } else {
                return data.managerRecommendations?.trainings?.includes(i.id);
            }
        });
    }

    return {
        items: filteredItems,
        areSuggestions: searchResult.areSuggestions,
    }
}

const generateCategories = (items: Immutable<Array<ItemData>> | undefined) => {
    if (!items) return undefined;
    const categories = Array.from(new Set(items.map(i => i.topics).flat()));
    const categoryItems = categories.map(c => ({key: c, content: c}));
    categoryItems.sort((a, b) => a.content.localeCompare(b.content));
    return [
        {key: "all", content: translations.get("All")},
        ...categoryItems,
    ]
}

const mapItemsToSearchDataItems = (items: Immutable<Array<ItemData>> | undefined, type: ItemDataType) => {
    if (!items) return [];
    return items?.map(i => ({
        id: i.id,
        title: i.title,
        description: i.description,
        link: i.link,
        type,
    }))
}

const getSharingLink = (itemLink: string | undefined) => {
    if (!itemLink) return;
    const appConfiguration = getAppConfiguration();
    if (!appConfiguration) {
        ErrorModule.showErrorAlert("App configuration is not available");
        return;
    }
    let deeplink = `https://teams.microsoft.com/l/entity/${appConfiguration.manifestId}/0`;
    const deeplinkContext: DeeplinkContext = {
        type: DeeplinkContextType.ShowItem,
        data: itemLink,
    }
    const context = encodeURIComponent(JSON.stringify({subEntityId: JSON.stringify(deeplinkContext)}));
    deeplink += `?context=${context}`;
    return deeplink;
}

export const ItemDataModule = {
    filterItems,
    generateCategories,
    mapItemsToSearchDataItems,
    sortItemsByTitle,
    removeEmojis,
    getSharingLink,
}