import {memo, ReactElement, useEffect} from "react";
import {Props} from "./RenameFileDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./RenameFileDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Button, EditIcon, Flex} from "@fluentui/react-northstar";
import "./RenameFileDialog.styles.scss";
import {useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "../../../translations";

export const RenameFileDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    useEffect(() => {
        form.setFieldsInitialValues({name: state.file?.name});
        form.reset();
    }, [state.file]);

    const form = useForm({
        disabled: state.isRenaming,
        items: {
            name: {
                required: true,
                type: "input",
                inputMode: "text",
                label: translations.get("Title"),
                placeholder: translations.get("EnterATitle"),
                minLength: 3,
                maxLength: 80,
            }
        }
    });

    const {name} = form.state.data;

    const saveButton = (
        <Button
            primary
            loading={state.isRenaming}
            disabled={!form.isValid || !form.hasChanged || state.isRenaming}
            icon={<EditIcon outline/>}
            content={translations.get("Rename")}
            onClick={dispatch("rename", name)}
        />
    )

    return (
        <Dialog
            externalRef={dialogRef}
            width={500}
            onClose={dispatch("reset", form.reset)}
            title={translations.get("RenameFile")}
            content={form.renderForm()}
            footer={
                <Flex fill hAlign={"end"}>
                    {saveButton}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);