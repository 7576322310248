import {HomeLogic} from "../Home.interfaces";
import {Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../translations";
import React, {useEffect, useRef, useState} from "react";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {ItemData, ItemDataType} from "common/dist/src";
import {ItemsCarousel} from "../../../components/others/ItemsCarousel/ItemsCarousel";
import {ItemsGrid} from "../../../components/others/ItemsGrid/ItemsGrid";
import {ScreenModule} from "../../../modules/Screen.module";
import {NoResultBanner} from "../../../components/others/NoResultBanner/NoResultBanner";

export const SearchResults = (logic: HomeLogic) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [visibleItems, setVisibleItems] = useState<number>(0);

    useEffect(() => {
        if (!containerRef.current) return;
        const categoryTitleHeight = 42;
        const gap = 20;
        const containerHeight = containerRef.current.clientHeight - (categoryTitleHeight * 2) - gap;
        const itemHeight = 66;
        let visibleItems = Math.floor(Math.floor(containerHeight / itemHeight) / 2) - 1;
        if (visibleItems < 6) visibleItems = 6;
        setVisibleItems(visibleItems);
    }, [logic.showSearchResults]);

    if (!logic.showSearchResults) return null;

    const showAllItems = logic.selectedSearchResultsCategoryIndex === 0;
    const showBestPracticesSearchResults = [0, 1].includes(logic.selectedSearchResultsCategoryIndex);
    const showTrainingsSearchResults = [0, 2].includes(logic.selectedSearchResultsCategoryIndex);

    const bestPracticesSearchResults = logic.searchResults?.filter(i => i.type === ItemDataType.BestPractice);
    const trainingsSearchResults = logic.searchResults?.filter(i => i.type === ItemDataType.Training);

    const bestPracticesResults = renderResultsPart({
        type: ItemDataType.BestPractice,
        title: translations.get("BestPractices"),
        results: bestPracticesSearchResults,
        showAllItems,
        visibleItems,
        logic,
    })

    const trainingsResults = renderResultsPart({
        type: ItemDataType.Training,
        title: translations.get("Trainings"),
        results: trainingsSearchResults,
        showAllItems,
        visibleItems,
        logic,
    });

    const isSmallScreen = ScreenModule.isSmallScreen();

    const styles = {
        gap: isSmallScreen ? "0" : "15px",
        paddingBottom: showAllItems ? "30px" : "0",
    }

    const className = [
        "home-view-search-results",
        showAllItems ? "overflow-scroll" : "overflow-hidden",
    ].join(" ");

    return (
        <Flex fill column styles={styles} className={className} ref={containerRef}>
            {logic.areSearchResultsSuggestions && <NoResultBanner/>}
            {showBestPracticesSearchResults && bestPracticesResults}
            {showTrainingsSearchResults && trainingsResults}
            {showAllItems && <div style={{minHeight: "5px"}}/>}
        </Flex>
    )
}

const renderResultsPart = (data: {
    type: ItemDataType,
    results: Immutable<Array<ItemData>> | undefined,
    title: string,
    showAllItems: boolean,
    visibleItems: number,
    logic: HomeLogic,
}) => {
    const {results, title, showAllItems, visibleItems, logic} = data;
    const visibleSearchResultsItems = showAllItems ? visibleItems : undefined;
    const visibleResults = logic.showSkeletons ?
        Array.from({length: visibleSearchResultsItems ?? 3}).map(() => undefined) : results?.slice(0, visibleSearchResultsItems);
    const hasResults = visibleResults && visibleResults.length > 0;
    const multipleResults = results && results.length > 1;
    const itemsCountLabel = `(${results?.length} ${multipleResults ? translations.get("Items") : translations.get("Item")})`.toLowerCase();
    const overflowClassName = !showAllItems ? "overflow-hidden" : "";
    const isSmallScreen = ScreenModule.isSmallScreen();
    return (
        <Flex column className={overflowClassName} fill={!showAllItems}>
            <Flex className={"no-shrink w-100 cursor-pointer " + overflowClassName} vAlign={"center"}
                  gap={"gap.smaller"}
                  styles={{padding: isSmallScreen ? "10px 20px" : "0 20px 10px 20px"}}
                  onClick={data.logic.dispatch("showSearchMoreItems", data.type)}>
                <Text
                    className={"home-view-search-part-title"}
                    size={"large"}
                    weight={"bold"}
                    content={title}
                />
                <Text className={"primary-color"} styles={{marginBottom: isSmallScreen ? "-10px" : "0"}}
                      content={itemsCountLabel}/>
            </Flex>
            <Flex fill column gap={"gap.small"} className={"overflow-scroll"}>
                {hasResults ? (
                    !showAllItems ?
                        <ItemsGrid items={data.results}/>
                        :
                        <ItemsCarousel
                            items={data.results}
                            cacheKey={"home-best-practices-search-results"}
                            itemDialogRef={logic.itemDialogRef}
                        />
                ) : (
                    <Text styles={{color: "darkgray"}} content={translations.get("NoResults")}/>
                )}
            </Flex>
        </Flex>
    )
}