import {BestPracticeApi} from "../../apis/BestPractice/BestPracticeApi";
import {ItemData, ItemDataType} from "common";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {useQuery} from "@tanstack/react-query";
import {TrainingApi} from "../../apis/Training/TrainingApi";

export const useThumbnailsCache = (item: Immutable<ItemData> | undefined) => {
    const {data: imageUrl} = useQuery({
        queryKey: ["thumbnails" + item?.id + item?.type],
        queryFn: async () => {
            if (!item?.id) return undefined;
            if (item?.type === ItemDataType.Training) return await TrainingApi.getThumbnail(item?.id);
            return await BestPracticeApi.getThumbnail(item?.id);
        },
        staleTime: Infinity,
        enabled: item?.id !== undefined && item?.type !== undefined
    });

    return {imageUrl};
};