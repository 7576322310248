import React, {memo, ReactElement, useEffect, useMemo} from "react";
import {Props} from "./ItemsCarousel.interfaces";
import {
    Immutable,
    MagicDispatch,
    MagicReducerRef,
    useMagicReducer,
    useMagicReducerRef
} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ItemsCarousel.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ItemsCarousel.styles.scss";
import {Button, ChevronEndIcon, ChevronStartIcon, Flex, Text} from "@fluentui/react-northstar";
import {AutoWidthMenu} from "../AutoWidthMenu/AutoWidthMenu";
import {ItemCard} from "../ItemCard/ItemCard";
import {Carousel} from "../Carousel/Carousel";
import {useMyRecommendationsCache} from "../../../hooks/cache/recommendationsCache";
import {ScreenModule} from "../../../modules/Screen.module";
import {ItemData} from "common";
import {ItemDialog} from "../../dialogs/ItemDialog/ItemDialog";
import {translations} from "../../../translations";

export const ItemsCarousel = memo((props: Props): ReactElement | null => {
    const carouselRef = useMagicReducerRef(Carousel);
    const autoWidthMenuRef = useMagicReducerRef(AutoWidthMenu);
    const [state, dispatch, render] = useMagicReducer(reducer({
        props,
        carouselRef
    }), initialState(props), props.externalRef);
    const {canRecommendItem} = useMyRecommendationsCache();

    useEffect(() => {
        window.addEventListener("resize", dispatch("windowResize"));
        return () => window.removeEventListener("resize", dispatch("windowResize"));
    }, []);

    const hideRecommendationButton = props.hideRecommendationButton ?? !canRecommendItem;
    const isSmallScreen = ScreenModule.isSmallScreen();

    const showHeader = props.title || props.subtitle || props.menuItems;

    return (
        <Flex column className={"items-carousel"}>
            {showHeader &&
                <Flex vAlign={"center"} styles={props.headerStyles}>
                    <Flex column fill vAlign={"center"}
                          styles={{marginBottom: isSmallScreen ? "15px" : "0"}}>
                        {props.title &&
                            <Text
                                className={"items-carousel-content-title"}
                                size={"larger"}
                                weight={"bold"}
                                content={props.title}
                            />
                        }
                        {props.subtitle &&
                            <Text
                                className={"items-carousel-content-subtitle"}
                                content={props.subtitle}
                            />
                        }
                        <Flex className={"w-100"} styles={{marginBottom: isSmallScreen ? "0" : "10px"}}>
                            <AutoWidthMenu
                                externalRef={autoWidthMenuRef}
                                defaultMenuIndex={props.defaultMenuIndex}
                                items={props.menuItems}
                                onIndexChange={dispatch("handleClickMenuItem")}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            }
            {renderCarousel({
                items: props.items,
                itemDialogRef: props.itemDialogRef,
                carouselRef,
                slidesPerView: state.slidesPerView,
                render,
                dispatch,
                hideRecommendationButton,
            })}
        </Flex>
    )
}, CompareModule.areObjectsEqual);

const renderCarousel = (config: {
    items: Immutable<Array<ItemData>> | undefined,
    itemDialogRef: MagicReducerRef<typeof ItemDialog>,
    carouselRef: MagicReducerRef<typeof Carousel>,
    render: () => void,
    slidesPerView: number,
    dispatch: MagicDispatch<typeof reducer>,
    hideRecommendationButton: boolean
}) => {
    const items = useMemo(() => (
        !config.items ?
            Array.from({length: 10}).map((_, i) =>
                <ItemCard key={"skeleton" + i} item={undefined} hideRecommendButton={true}/>
            )
            :
            config.items.map(b => (
                <ItemCard
                    key={b.id}
                    item={b}
                    itemDialogRef={config.itemDialogRef}
                    hideRecommendButton={config.hideRecommendationButton}
                />
            ))
    ), [config.items, config.hideRecommendationButton]);

    const hasItems = items.length > 0;

    const className = [
        "items-carousel-container",
        !hasItems && "items-carousel-container-empty"
    ].filter(i => i).join(" ");

    const isSmallScreen = ScreenModule.isSmallScreen();

    const showButtons = config.slidesPerView > 1;

    return (
        <Flex className={className} vAlign={"center"} hAlign={"center"} ref={config.dispatch("containerRef")}>
            {showButtons &&
                <Button
                    text icon={<ChevronStartIcon size={isSmallScreen ? "large" : "largest"}/>} iconOnly
                    onClick={config.carouselRef.dispatch("showPrev")}
                    disabled={!hasItems || (config.carouselRef.state?.isPrevButtonDisabled ?? false)}
                    style={{marginLeft: "-5px"}}
                />
            }
            <Flex column className={"h-100 w-100 overflow-hidden"} vAlign={"center"} hAlign={"center"}
                  styles={{padding: "0 1px"}}>
                {!hasItems ?
                    <Text content={translations.get("NoItemsAvailable")} size={"larger"}/>
                    :
                    config.slidesPerView &&
                    <Carousel
                        className={"items-carousel-carousel"}
                        onLoaded={config.render}
                        onButtonStateChanged={config.render}
                        externalRef={config.carouselRef}
                        slidesPerView={config.slidesPerView}
                        slidesPerGroup={config.slidesPerView}
                        spaceBetween={showButtons ? 25 : -30}
                        cssMode={!showButtons}
                        items={items}
                    />
                }
            </Flex>
            {showButtons &&
                <Button
                    text icon={<ChevronEndIcon size={isSmallScreen ? "large" : "largest"}/>} iconOnly
                    onClick={config.carouselRef.dispatch("showNext")}
                    disabled={!hasItems || (config.carouselRef.state?.isNextButtonDisabled ?? false)}
                    style={{marginRight: "-5px"}}
                />
            }
        </Flex>
    )
}