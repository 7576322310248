import React, {memo, ReactElement, useEffect, useMemo} from "react";
import {Props} from "./NewsCarousel.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./NewsCarousel.reducer";
import {CompareModule} from "modules/Compare.module";
import "./NewsCarousel.styles.scss";
import {NewsCard} from "../NewsCard/NewsCard";
import {ScreenModule} from "../../../modules/Screen.module";
import {Button, ChevronEndIcon, ChevronStartIcon, Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../translations";
import {Carousel} from "../Carousel/Carousel";
import {NewsModule} from "../../../modules/News.module";

export const NewsCarousel = memo((props: Props): ReactElement | null => {
    const carouselRef = useMagicReducerRef(Carousel);
    const [state, dispatch, render] = useMagicReducer(reducer, initialState);

    useEffect(() => {
        window.addEventListener("resize", dispatch("windowResize"));
        return () => window.removeEventListener("resize", dispatch("windowResize"));
    }, []);

    const items = useMemo(() => (
        !props.items ?
            Array.from({length: 10}).map((_, i) => <NewsCard key={"skeleton" + i} newsId={undefined}/>)
            :
            NewsModule.sortNews(props.items)
                .filter(i => i.show)
                .map(b => <NewsCard key={b.id.toString()} newsId={b.id}/>)
    ), [props.items]);

    const hasItems = items.length > 0;

    const className = [
        "items-carousel-container",
        !hasItems && "items-carousel-container-empty"
    ].filter(i => i).join(" ");

    const isSmallScreen = ScreenModule.isSmallScreen();

    const showButtons = state.slidesPerView > 1;

    return (
        <Flex className={className} vAlign={"center"} hAlign={"center"} ref={dispatch("containerRef")}>
            {showButtons &&
                <Button
                    text icon={<ChevronStartIcon size={isSmallScreen ? "large" : "largest"}/>} iconOnly
                    onClick={carouselRef.dispatch("showPrev")}
                    disabled={!hasItems || (carouselRef.state?.isPrevButtonDisabled ?? false)}
                    style={{marginLeft: "-5px"}}
                />
            }
            <Flex column className={"h-100 w-100 overflow-hidden"} vAlign={"center"} hAlign={"center"}
                  styles={{padding: "0 1px"}}>
                {!hasItems ?
                    <Text content={translations.get("NoNewsAvailable")} size={"larger"}/>
                    :
                    state.slidesPerView &&
                    <Carousel
                        className={"items-carousel-carousel"}
                        onLoaded={render}
                        onButtonStateChanged={render}
                        externalRef={carouselRef}
                        slidesPerView={state.slidesPerView}
                        slidesPerGroup={state.slidesPerView}
                        spaceBetween={showButtons ? 25 : -30}
                        cssMode={!showButtons}
                        items={items}
                    />
                }
            </Flex>
            {showButtons &&
                <Button
                    text icon={<ChevronEndIcon size={isSmallScreen ? "large" : "largest"}/>} iconOnly
                    onClick={carouselRef.dispatch("showNext")}
                    disabled={!hasItems || (carouselRef.state?.isNextButtonDisabled ?? false)}
                    style={{marginRight: "-5px"}}
                />
            }
        </Flex>
    )
}, CompareModule.areObjectsEqual);