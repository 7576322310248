import {DialogMenuItem, Props, State} from "./Dialog.types";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React from "react";
import {MenuItemProps} from "@fluentui/react-northstar";
import {ScreenModule} from "../../../modules/Screen.module";

export const initialState: State = {
    initialized: false,
    isOpen: false,
    hideBlurMask: true,
    transitionTimeout: undefined,
    mouseDownTargetClassName: undefined,
    maxContentHeight: "80vh",
    content: null,
    showContentScrollbar: false,
    headerHeight: 0,
    footerHeight: 0,
    activeMenuItemId: "",
}

export const reducer = (props: Props) => ({
    open: ({setState}) => {
        setState({
            isOpen: true,
            hideBlurMask: false,
            maxContentHeight: initialState.maxContentHeight,
            headerHeight: 0,
            footerHeight: 0,
            activeMenuItemId: props.defaultMenuActiveItemId ?? props.menuItems?.[0]?.id ?? "",
        });
    },
    maskMouseDown: ({setState}, [e]: [React.SyntheticEvent]) => {
        const className = ((e.target as HTMLElement).className ?? "") + "";
        setState({mouseDownTargetClassName: className}, false);
    },
    maskMouseUp: (reducerData) => {
        const {state} = reducerData;
        if (!state.mouseDownTargetClassName?.includes("dialog-mask")) return;
        reducer(props).close(reducerData);
    },
    close: async ({state, setState}, _?: any, callback?: () => void) => {
        const close = () => {
            if (state.transitionTimeout) {
                clearTimeout(state.transitionTimeout);
                callback?.();
            }
            const transitionTimeout = setTimeout(() => {
                setState({hideBlurMask: true});
                callback?.();
            }, getTransitionDelay(props));
            setState({isOpen: false, transitionTimeout});
        }
        if (!props.onBeforeClose) return close();
        props.onBeforeClose(canClose => {
            if (!canClose) return;
            close();
        });
    },
    initialize: ({state, setState}) => {
        if (state.initialized) return;
        setState({initialized: true}, false);
    },
    headerRef: ({state, setState}, [ref]: [HTMLDivElement | null]) => {
        if (!ref) return;
        const headerHeight = ref.clientHeight;
        if (state.headerHeight === headerHeight) return;
        setState({headerHeight});
    },
    contentRef: ({setState}, [ref]: [HTMLDivElement | null]) => {
        setState({content: ref}, false);
    },
    footerRef: ({state, setState}, [ref]: [HTMLDivElement | null]) => {
        if (!ref) return;
        const footerHeight = ref.clientHeight;
        if (state.footerHeight === footerHeight) return;
        setState({footerHeight});
    },
    contentResize: ({state, setState}) => {
        if (!state.content) return;
        const contentHeight = state.content?.clientHeight ?? 0;
        const childHeight = state.content?.firstElementChild?.clientHeight ?? 0;
        const hasOverflow = contentHeight < childHeight;
        if (state.showContentScrollbar === hasOverflow) return;
        setState({showContentScrollbar: hasOverflow});
    },
    updateMaxContentHeight: ({state, setState}) => {
        const maxContentHeight = getContentHeight({
            footerHeight: state.footerHeight,
            headerHeight: state.headerHeight,
            dialogHeight: props.height,
            fullscreen: props.fullscreen,
        });
        if (state.maxContentHeight === maxContentHeight) return;
        setState({maxContentHeight});
    },
    switchTab: ({setState}, [, data]: [React.SyntheticEvent, MenuItemProps | undefined]) => {
        const tabId = (data as DialogMenuItem | undefined)?.id;
        if (tabId === undefined) return;
        setState({activeMenuItemId: tabId});
        props.onMenuItemChange?.(tabId);
    },
}) satisfies MagicReducerObject<State>;

const getTransitionDelay = (props: Props) => {
    if (props.popup) return 250;
    return 150;
}

const getContentHeight = (config: {
    headerHeight: number,
    footerHeight: number,
    dialogHeight?: number,
    fullscreen?: boolean
}) => {
    const {dialogHeight, headerHeight, footerHeight, fullscreen} = config;
    let maxContentHeight: string;
    if (dialogHeight || fullscreen) {
        const height = (dialogHeight && !fullscreen) ? dialogHeight + "px" : "100%";
        maxContentHeight = `calc(${height} - ${headerHeight}px - ${footerHeight}px)`;
    } else {
        const {height} = ScreenModule.getWindowRealSize();
        maxContentHeight = `calc(${height * 0.7}px - ${footerHeight}px)`;
    }
    return maxContentHeight;
}