import {Flex, Text} from "@fluentui/react-northstar";
import React, {memo} from "react";
import {NavbarItem} from "../../Navbar.interfaces";

interface SideMenuItemProps {
    item: NavbarItem,
    isSelected: boolean,
    onItemSelected: () => void,
}

const propsAreEqual = (prevProps: Readonly<SideMenuItemProps>, nextProps: Readonly<SideMenuItemProps>) => {
    return prevProps.item === nextProps.item && prevProps.isSelected === nextProps.isSelected;
}

const SideMenuItem = memo((props: SideMenuItemProps) => {
    const className = [
        "menu-item",
        props.isSelected ? "menu-item-selected" : "",
    ].join(" ");

    const Icon = props.item.icon;

    return (
        <Flex onClick={props.onItemSelected} key={props.item.label} gap={"gap.small"} className={className}
              vAlign={"center"}>
            <Flex className={"no-shrink"}>
                <Icon fontSize={30}/>
            </Flex>
            <Text size={"large"} content={props.item.label}/>
        </Flex>
    )
}, propsAreEqual)

export default SideMenuItem;