import {BestPracticeApi} from "../../apis/BestPractice/BestPracticeApi";
import {useQuery} from "@tanstack/react-query";

export const bestPracticesCacheKey = "bestPractices";

export const useBestPracticesCache = () => {
    const {data, refetch, isRefetching, isLoading} = useQuery({
        queryKey: [bestPracticesCacheKey],
        queryFn: fetchBestPractices,
        staleTime: Infinity,
    });

    const bestPractices = (isRefetching || isLoading) ? undefined : data;

    return {
        bestPractices,
        refetch,
    };
};

const fetchBestPractices = async () => {
    const items = await BestPracticeApi.getAll();
    const ranking = await BestPracticeApi.getMyRanking();
    return items?.sort((a, b) => ranking.findIndex(r => r === a.id) - ranking.findIndex(r => r === b.id));
}