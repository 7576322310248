import React, {ReactElement, useEffect} from "react";
import {Props} from "./Dialog.types";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Dialog.reducer";
import "./Dialog.styles.scss";
import {Button, CloseIcon, ComponentSlotStyle, Flex, Menu, Text} from "@fluentui/react-northstar";
import {ScreenModule} from "../../../modules/Screen.module";

const defaultStyles: ComponentSlotStyle = {
    borderRadius: "10px",
    minWidth: "200px",
    maxWidth: "calc(100% - 50px)",
    maxHeight: "calc(100% - 50px)",
}

const popupStyles: ComponentSlotStyle = {
    borderRadius: "10px",
    width: "100%",
    maxHeight: "calc(100% - 50px)",
}

const fullScreenStyles: ComponentSlotStyle = {
    width: "100%",
    height: "100%",
}

export const Dialog = (props: Props): ReactElement | null => {
    const isSmallScreen = ScreenModule.isSmallScreen();
    const forceFullScreen = isSmallScreen && !!props.width && props.width >= 500;
    const isFullScreen = props.fullscreen || forceFullScreen;

    const [state, dispatch] = useMagicReducer(reducer({
        ...props,
        fullscreen: isFullScreen
    }), initialState, props.externalRef);

    useEffect(function onBlurMaskVisibilityChange() {
        if (state.initialized) {
            if (state.hideBlurMask) props.onClose?.();
            else props.onOpen?.();
        }
        dispatch("initialize")();
    }, [state.hideBlurMask]);

    useEffect(() => {
        if (!state.content) return;
        const contentResizeObserver = new ResizeObserver(dispatch("contentResize"));
        const windowResizeHandler = dispatch("updateMaxContentHeight");
        contentResizeObserver.observe(state.content as HTMLDivElement);
        window.addEventListener("resize", windowResizeHandler);
        windowResizeHandler();
        return () => {
            contentResizeObserver.disconnect()
            window.removeEventListener("resize", windowResizeHandler)
        }
    }, [state.isOpen]);

    useEffect(function onHeaderOrFooterHeightChange() {
        if (!state.isOpen) return;
        dispatch("updateMaxContentHeight")();
    }, [state.headerHeight, state.footerHeight]);

    const renderDialog = state.isOpen || !state.hideBlurMask;
    if (!renderDialog) return null;

    const hideHeader = !props.title && !props.icon && !props.headerElement;

    const dialogStyles: ComponentSlotStyle = isFullScreen ? fullScreenStyles : props.popup ? popupStyles : defaultStyles;
    if (!isFullScreen) {
        dialogStyles.width = (props.width ?? 200) + "px";
        dialogStyles.height = !props.height ? "fit-content" : props.height + "px";
    }

    const dialogClassName = [
        "dialog",
        "no-select",
        props.className,
    ].filter(Boolean).join(" ");

    const dialogContentClassName = [
        "dialog-content",
        state.showContentScrollbar && "overflow-scroll",
        props.noPadding && "dialog-content-no-padding",
        !props.noPadding && hideHeader && "dialog-content-top-padding",
        !props.noPadding && !props.footer && "dialog-content-bottom-padding",
    ].filter(Boolean).join(" ");

    const dialogMaskClassName = [
        "dialog-mask",
        props.popup && "dialog-mask-popup",
        !state.isOpen && "dialog-mask-close"
    ].filter(Boolean).join(" ");

    const dialogBlurMaskClassName = [
        "dialog-blur-mask",
        !state.isOpen && "dialog-blur-mask-hide",
    ].filter(Boolean).join(" ");

    const dialogHeaderLeftPartClassName = [
        "overflow-hidden",
        "no-shrink",
        !props.headerElement && "w-100"
    ].filter(Boolean).join(" ");

    const dialogHeaderClassName = [
        "dialog-header",
        props.headerShadow && "dialog-header-shadow",
    ].filter(Boolean).join(" ");

    const dialogFooterClassName = [
        "dialog-footer",
        props.footerShadow && "dialog-footer-shadow",
    ].filter(Boolean).join(" ");

    const icon = !props.icon ? null : typeof props.icon === "function" ?
        <props.icon width={24} height={24}/> : props.icon;

    const menu = !props.menuItems ? null : (
        <Menu
            className={"dialog-menu"}
            activeIndex={props.menuItems.findIndex(i => i.id === state.activeMenuItemId)}
            items={props.menuItems}
            underlined
            primary
            onItemClick={dispatch("switchTab")}
        />
    )

    const dialogHeader = hideHeader ? null : (
        <Flex column ref={dispatch("headerRef")}>
            <Flex vAlign={"center"} className={dialogHeaderClassName} styles={props.headerStyles ?? {}}>
                <Flex fill gap={"gap.small"} vAlign={"center"} className={"overflow-hidden"}>
                    <Flex gap={"gap.small"} vAlign={"center"} className={dialogHeaderLeftPartClassName}>
                        {icon}
                        <Text truncated className={"dialog-title"} size={"large"} weight={"semibold"}
                              content={props.title}/>
                    </Flex>
                    {props.headerElement}
                </Flex>
                {!props.noCloseButton &&
                    <Button onClick={dispatch("close")} styles={{marginRight: "-5px"}} icon={<CloseIcon/>} iconOnly
                            text/>
                }
            </Flex>
            {menu}
        </Flex>
    )

    const dialog = (
        <Flex column styles={dialogStyles}
              className={dialogClassName} {...(props.closeOnClick && {onClick: dispatch("close")})}>
            <Flex column fill>
                {dialogHeader}
                <Flex fill={(isFullScreen || !!props.height) ?? false} className={dialogContentClassName}
                      ref={dispatch("contentRef")}
                      styles={{maxHeight: state.maxContentHeight}}>
                    {props.content}
                </Flex>
                {!props.footer ? null : (
                    <Flex column className={dialogFooterClassName} ref={dispatch("footerRef")}>
                        {props.footer}
                    </Flex>
                )}
            </Flex>
        </Flex>
    )

    return <>
        <div className={dialogBlurMaskClassName}/>
        <Flex className={dialogMaskClassName}
              {...(props.closeOnOutsideClick === false ? {} : {
                  onMouseDown: dispatch("maskMouseDown"),
                  onMouseUp: dispatch("maskMouseUp"),
              })}>
            {dialog}
        </Flex>
    </>
}