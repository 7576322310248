import React, {memo, ReactElement} from "react";
import {Props} from "./ItemDialog.interfaces";
import {Immutable, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ItemDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ItemDialog.styles.scss";
import {RichTextInput, useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "translations";
import {Button, ChatIcon, ComponentSlotStyle, Divider, Flex, Loader, SendIcon, Text} from "@fluentui/react-northstar";
import {Dialog} from "../Dialog/Dialog";
import scssVariables from "../../../variables.module.scss";
import {useMyRecommendationsCache} from "../../../hooks/cache/recommendationsCache";
import {ScreenModule} from "../../../modules/Screen.module";
import {MentionModule} from "../../../modules/Mention.module";
import {useItemCommentsCache} from "../../../hooks/cache/commentsCache";
import {ItemData, SharepointComment} from "common";
import {ItemCard} from "../../others/ItemCard/ItemCard";
import {CommentCard} from "../../others/CommentCard/CommentCard";

export const ItemDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const {canRecommendItem} = useMyRecommendationsCache();

    const isSmallScreen = ScreenModule.isSmallScreen();

    const form = useForm({
        locale: "en",
        fluid: true,
        items: {
            comment: RichTextInput({
                required: true,
                placeholder: translations.get("WriteYourComment"),
                height: isSmallScreen ? "100px" : "150px",
                maxLength: 300,
                showMentions: true,
                onSuggestMentions: MentionModule.fetchSuggestions,
                mentionTextColor: scssVariables.orangeColor,
                hideToolbar: true,
            }),
        }
    });

    const [state, dispatch] = useMagicReducer(reducer(form.reset, dialogRef), initialState, props.externalRef);

    const {comments, publishComment} = useItemCommentsCache(state.item?.type, state.item?.id);

    const canPublish = !state.isPublishing && form.isValid;

    return (
        <Dialog
            width={800}
            title={translations.get("NewComment")}
            externalRef={dialogRef}
            content={
                <Flex fill column={isSmallScreen} className={"overflow-hidden"}>
                    <Flex fill={!isSmallScreen} column gap={"gap.medium"}
                          className={"overflow-hidden x-padding " + (isSmallScreen ? "no-shrink" : "")}>
                        <ItemCard inline item={state.item as ItemData} hideActions
                                  hideRecommendButton={!canRecommendItem}/>
                        <Flex styles={{marginTop: "-10px"}}>
                            {form.renderForm()}
                        </Flex>
                    </Flex>
                    {isSmallScreen ?
                        <Divider className={"y-padding"}/>
                        :
                        <Divider vertical={!isSmallScreen}/>
                    }
                    {renderCommentsPart(comments, isSmallScreen)}
                </Flex>
            }
            footer={
                <Flex className={"w-100"} hAlign={"end"}>
                    <Button
                        fluid={isSmallScreen}
                        primary icon={<SendIcon outline/>}
                        content={translations.get("Publish")}
                        onClick={dispatch("handlePublish", {comment: form.state.data.comment ?? "", publishComment})}
                        disabled={!canPublish}
                        loading={state.isPublishing}
                    />
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);

const renderCommentsPart = (comments: Immutable<Array<SharepointComment>> | undefined, isSmallScreen: boolean) => {
    const containerStyles: ComponentSlotStyle = {
        height: isSmallScreen ? "auto" : "350px",
        marginTop: isSmallScreen ? "-10px" : "0",
    }
    return (
        <Flex fill column className={"overflow-hidden"} gap={"gap.small"}
              styles={containerStyles}>
            <Text className={"x-padding"} weight={"semibold"} size={"large"}
                  content={translations.get("RecentComments")}/>
            <Flex fill column gap={"gap.medium"} styles={{padding: "5px 15px 15px 15px"}}
                  className={"overflow-scroll"}>
                {!comments ?
                    <Loader className={"h-100"}/>
                    :
                    comments.length === 0 ?
                        <Flex fill column vAlign={"center"} hAlign={"center"} gap={"gap.smaller"}
                              styles={{padding: "20px", opacity: 0.3}}>
                            <ChatIcon styles={{color: "darkgray"}} size={"larger"} outline/>
                            <Text styles={{color: "darkgray"}} content={translations.get("NoComments")}/>
                        </Flex>
                        :
                        comments.slice(0, isSmallScreen ? 5 : 15).map(c => <CommentCard key={c.id} comment={c}/>)
                }
            </Flex>
        </Flex>
    )
}