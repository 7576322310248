import {ArrowDownIcon, ArrowUpIcon, Button, FilterIcon, Flex, Input, Text} from "@fluentui/react-northstar";
import React, {useMemo} from "react";
import {IListColumn, IListColumnLabelFunc, IListItem, ListColumnFilterType, Logic} from "../List.types";
import {PopupMenuButton} from "components/buttons/PopupMenuButton/PopupMenuButton";
import {translations} from "translations";

export const useListHeader = <T, >(logic: Logic<T>): Array<Record<string, unknown>> => {
    return useMemo(() => (
        generateItems(logic)
    ), [logic.columnSort, logic.sortAscending, logic.columns, logic.showActionColumn, logic.columnFilters]);
}

const generateItems = <T, >(logic: Logic<T>) => {
    const items = logic.columns.map(formatColumn<T>(logic));
    if (logic.showActionColumn) items.push(generateActionColumn<T>(logic));
    return items;
}

const generateActionColumn = <T, >(logic: Logic<T>) => ({
    content: <></>,
    styles: {zIndex: -1, minWidth: logic.actionColumnWidth + "px", maxWidth: logic.actionColumnWidth + "px"},
    key: "header-action",
});

const formatColumn = <T, >(logic: Logic<T>) => (column: IListColumn<IListItem<T>>): Record<string, unknown> => {
    return {
        content: renderColumn(logic, column),
        truncateContent: true,
        className: column.labelClassName,
        styles: {
            minWidth: column.minWidth,
            maxWidth: `calc(${column.maxWidth} - ${logic.actionColumnWidth / logic.columns.length}px)`,
            ...column.labelStyles,
        },
        key: "header-" + column.field,
    }
}

const renderColumn = <T, >(logic: Logic<T>, column: IListColumn<IListItem<T>>) => {
    const isStringLabel = typeof column.label === "string";
    const sortIndicator = getSortIndicator(logic, column.field);
    return (
        <Flex fill className={"list-header-item"} title={isStringLabel ? column.label as string : ""}
              vAlign={"center"}>
            <Flex fill className={"overflow-hidden cursor-pointer"} styles={{gap: "3px"}}
                  onClick={logic.dispatch("handleSortColumn", {columns: logic.columns, columnName: column.field})}
                  vAlign={"center"}>
                <Flex fill className={"overflow-hidden"} vAlign={"center"}>
                    {column.icon}
                    {isStringLabel ?
                        <Text size={"medium"} weight={"semibold"} truncated content={column.label as string}/>
                        :
                        (column.label as IListColumnLabelFunc)(sortIndicator)
                    }
                    {sortIndicator && isStringLabel && <Flex>{sortIndicator}</Flex>}
                </Flex>
            </Flex>
            {(!logic.hideFilters && column.getFilterValue) &&
                <Flex>
                    {renderColumnFilter(logic, column.field, column.filterPlaceholder)}
                </Flex>
            }
        </Flex>
    )
}

const renderColumnFilter = <T, >(logic: Logic<T>, field: string, placeholder?: string) => {
    const filterData = logic.columnFilters.get(field) ?? {type: ListColumnFilterType.Contains, text: ""};
    const text = filterData.text;
    return (
        <PopupMenuButton
            position={"below"}
            align={"start"}
            offset={[0, -3]}
            on={"hover"}
            trigger={<Button primary={!!text} icon={<FilterIcon outline/>} iconOnly text/>}
            className={"table-filter"}
            menu={[
                {
                    key: "header",
                    disabled: true,
                    className: "table-filter-header",
                    children: (
                        <Flex fill gap={"gap.small"} space={"between"} vAlign={"center"}>
                            <Text className={"header-title"} weight={"semibold"}
                                  content={translations.get("Filter")}/>
                            <Button
                                styles={{minWidth: "0px"}}
                                onClick={logic.dispatch("handleResetColumnFilters", field)}
                                primary text
                                content={translations.get("Reset")}
                            />
                        </Flex>
                    ),
                    tabIndex: -1,
                },
                {
                    key: "divider-1",
                    kind: "divider",
                    styles: {margin: "5px 0"},
                    tabIndex: -1,
                },
                {
                    key: "filter-input",
                    kind: "divider",
                    className: "overflow-visible",
                    children: (
                        <Flex fill column styles={{width: "200px", padding: ".5rem"}}>
                            <Input
                                fluid
                                onChange={logic.dispatch("handleChangeColumnFilterText", field)}
                                placeholder={placeholder ?? translations.get("EnterTextToFilter")}
                                styles={{width: "100%"}}
                                value={text}
                            />
                        </Flex>
                    )
                }
            ]}
        />
    )
}

const getSortIndicator = <T, >(logic: Logic<T>, column: string) => {
    if (logic.columnSort !== column) return null;
    const icon = logic.sortAscending ? <ArrowDownIcon outline/> : <ArrowUpIcon outline/>;
    return <Button className={"table-sort-indicator"} styles={{marginRight: "-6px"}} icon={icon} iconOnly text/>;
}