import {Immutable} from "@witivio_teamspro/use-reducer";

const generalNewsId = "General";

const generateMenuItems = (communities: Immutable<Array<string>> | undefined) => {
    if (!communities) return;
    return Array.from(new Set(communities)).map(c => ({
        key: c,
        id: c,
        content: c,
    }));
}

export const CommunityModule = {
    generalNewsId,
    generateMenuItems,
}