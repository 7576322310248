const isSmallScreen = () => {
    return window.innerWidth <= 750;
}

const getElementRealSize = (element: HTMLElement | null | undefined) => {
    if (!element) return {height: 0, width: 0};
    const root = document.getElementById("root");
    if (!root) return {height: 0, width: 0};
    const styles = getComputedStyle(root) as any;
    const zoomLevel = styles.zoom ? parseFloat(styles.zoom) : 1;
    return {
        height: element.clientHeight / zoomLevel,
        width: element.clientWidth / zoomLevel,
    }
};

const getWindowRealSize = () => {
    const root = document.getElementById("root");
    if (!root) return {height: 0, width: 0};
    const styles = getComputedStyle(root) as any;
    const zoomLevel = styles.zoom ? parseFloat(styles.zoom) : 1;
    return {
        height: window.innerHeight / zoomLevel,
        width: window.innerWidth / zoomLevel,
    }
};

export const ScreenModule = {
    isSmallScreen,
    getWindowRealSize,
    getElementRealSize,
}