import * as microsoftTeams from "@microsoft/teams-js";
import {FrameContexts, HostClientType, UserTeamRole} from "@microsoft/teams-js";
import {mergeThemes, teamsDarkTheme, teamsTheme} from "@fluentui/react-northstar";
import {Guid, TokenResult} from "common";
import {QueryClient, useQuery, useQueryClient} from "@tanstack/react-query";
import React, {useEffect, useRef} from "react";
import moment from "moment/moment";
import {translations} from "../translations";
import {ErrorModule} from "../components/others/ErrorBoundary/ErrorBoundary";
import {ThemeCustomizations} from "../const/ThemeCustomizations";
import {MsTeamsData, ThemeClass} from "../types/MsTeams/MsTeamsData";
import {usePropertySelectorRendering} from "./usePropertySelectorRendering";

const initialData: MsTeamsData = {
    loaded: false,
    locale: "en",
    fullLocale: "en-US",
    themeClass: ThemeClass.Default,
    groupId: "" as Guid,
    channelId: "",
    teamId: "",
    teamName: "",
    tenantId: "" as Guid,
    channelName: "",
    userMail: "",
    userName: "",
    userId: "" as Guid,
    hostClientType: undefined,
    isConfiguringApp: false,
    isDeletingApp: false,
    isOnMobile: false,
    isInPersonalApp: false,
    subEntityId: "",
    isInTaskModule: false,
    entityId: "",
    isInMeeting: false,
    meetingId: "",
    isInMeetingSidePanel: false,
    userRole: UserTeamRole.User,
    isTouchScreen: false,
    isTeamsIframe: true,
    isLightTheme: true,
    theme: mergeThemes(teamsTheme, ThemeCustomizations("light")),
    isTeamsApp: undefined,
}

window.document.body.id = ThemeClass.Default;

export const msTeamsCacheKey = "msTeams";

export const useMsTeams = () => {
    const queryClient = useQueryClient();
    const isTouchScreenRef = useRef<boolean>(false);

    const {data} = useQuery({
        queryKey: [msTeamsCacheKey],
        queryFn: async (): Promise<MsTeamsData> => ({
            ...initialData,
            isTeamsApp: await isAppFromTeams(),
        }),
        staleTime: Infinity,
    });

    useEffect(function initializeTouchscreenListener() {
        const handler = handleMouseMove(queryClient, isTouchScreenRef);
        window.document.body.addEventListener("mousemove", handler);
        return () => window.document.body.removeEventListener("mousemove", handler);
    }, []);

    return {
        data: data ?? initialData,
        initialize: initializeMSTeams(queryClient),
    }
}

export const useMsTeamsSelector = <ARG extends (keyof MsTeamsData)[]>(...args: ARG) => {
    return usePropertySelectorRendering({args, getLocalData, initialData, cacheKey: msTeamsCacheKey});
}

const getLocalData = (queryClient: QueryClient) => {
    return queryClient.getQueryData([msTeamsCacheKey]) as MsTeamsData | undefined;
}

const setLocalData = (queryClient: QueryClient, data: Partial<MsTeamsData>) => {
    const localData = getLocalData(queryClient);
    queryClient.setQueryData([msTeamsCacheKey], {...localData, ...data});
}

const isAppFromTeams = () => new Promise<boolean>(resolve => {
    microsoftTeams.app.initialize().then(() => resolve(true)).catch(() => resolve(false));
});

const setTheme = (queryClient: QueryClient, theme: string) => {
    let themeClass = ThemeClass.Default;
    switch (theme) {
        case "dark":
            themeClass = ThemeClass.Dark;
            break;
        case "contrast":
            themeClass = ThemeClass.HighContrast;
            break;
        default:
            break;
    }
    window.document.body.id = themeClass;
    setLocalData(queryClient, {
        theme: getCurrentTheme(themeClass),
        themeClass,
        isLightTheme: themeClass === ThemeClass.Default
    });
}

const initializeMSTeams = (queryClient: QueryClient) => async (data: {
    isTeamsApp: boolean,
    accessToken: TokenResult
}) => {
    let state;
    if (data.isTeamsApp) {
        state = await initializeFromTeams(data.accessToken);
        microsoftTeams.app.registerOnThemeChangeHandler((theme) => setTheme(queryClient, theme));
        const context = await microsoftTeams.app.getContext();
        setTheme(queryClient, context.app.theme);
    } else {
        state = await initializeOutsideOfTeams(data.accessToken);
        setTheme(queryClient, "light");
    }
    if (data.isTeamsApp) microsoftTeams.app.notifySuccess();
    setLocalData(queryClient, state);
}

const handleMouseMove = (queryClient: QueryClient, isTouchScreenRef: React.MutableRefObject<boolean>) => (e: MouseEvent) => {
    const isTouchScreen = (e as MouseEvent & { sourceCapabilities?: { firesTouchEvents: boolean } })
        .sourceCapabilities?.firesTouchEvents;
    if (isTouchScreen === undefined || isTouchScreen === isTouchScreenRef.current) return;
    isTouchScreenRef.current = isTouchScreen;
    setLocalData(queryClient, {isTouchScreen});
}

const initializeFromTeams = async (accessToken: TokenResult | undefined) => {
    const context = await microsoftTeams.app.getContext();
    const locale = context.app.locale;
    moment.locale(locale);
    const stateUpdate: Partial<MsTeamsData> = {
        loaded: !!accessToken,
        locale: locale.substring(0, 2).toLowerCase(),
        fullLocale: locale,
        groupId: context.team?.groupId ? Guid(context.team?.groupId) : undefined,
        channelId: context.channel?.id as string,
        hostClientType: context.app.host.clientType,
        teamId: context.team?.internalId as string,
        teamName: context.team?.displayName as string,
        tenantId: context.user?.tenant?.id ? Guid(context.user?.tenant?.id) : undefined,
        channelName: context.channel?.displayName as string,
        userMail: (context.user?.loginHint as string).toLowerCase(),
        userId: context.user?.id ? Guid(context.user?.id) : undefined,
        isOnMobile: [HostClientType.ios, HostClientType.android, HostClientType.ipados]
            .includes(context.app.host.clientType),
        isInPersonalApp: context.page.id === "personnal",
        subEntityId: context.page.subPageId as string,
        isConfiguringApp: context.page.frameContext === FrameContexts.settings,
        isDeletingApp: context.page.frameContext === FrameContexts.remove,
        isInTaskModule: context.page.frameContext === microsoftTeams.FrameContexts.task,
        userName: !accessToken ? "" : await getUserName(accessToken),
        meetingId: context.meeting?.id ?? "",
        isInMeeting: !!context.meeting?.id,
        isInMeetingSidePanel: context.page.frameContext === FrameContexts.sidePanel,
        userRole: context.team?.userRole ?? UserTeamRole.User,
        isTeamsIframe: true,
    }
    translations.locale = stateUpdate.locale ?? "en";
    if (stateUpdate.isOnMobile) window.document.body.className = "on-mobile";
    stateUpdate.isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    if (stateUpdate.isConfiguringApp) stateUpdate.entityId = await getEntityId();
    return stateUpdate;
}

const initializeOutsideOfTeams = async (accessToken: TokenResult | undefined): Promise<Partial<MsTeamsData>> => {
    const fullLocale = window.navigator.language;
    const locale = fullLocale.substring(0, 2).toLowerCase();
    translations.locale = locale;
    moment.locale(fullLocale);
    const parentUrl = (window.location != window.parent.location) ? window.document.referrer : window.document.location.href;
    const isTeamsIframe = parentUrl.includes("teams.microsoft.com") || parentUrl.includes("teams.cloud.microsoft");
    return {
        loaded: !!accessToken,
        isTeamsIframe,
        tenantId: Guid(accessToken?.tenantId!),
        userId: Guid(accessToken?.userId!),
        userMail: accessToken?.userMail?.toLowerCase() ?? "",
        userName: accessToken?.userName ?? "",
        fullLocale,
        locale,
    };
}

const getEntityId = async () => {
    const settings = await microsoftTeams.pages.getConfig();
    return settings.entityId ?? "";
}

const getUserName = async (accessToken: TokenResult) => {
    if (!accessToken.token) {
        console.error("Can't get user name");
        return "";
    }
    const tokenData = parseJWTToken(accessToken.token);
    return tokenData.name;
};

const parseJWTToken = (token: string) => {
    const base64Url = token.split('.')[1];
    if (!base64Url) return ErrorModule.showErrorAlert("Token base64 url is undefined");
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

const getCurrentTheme = (themeClass: ThemeClass) => {
    let theme: "light" | "dark" = "light";
    let currentTheme = teamsTheme;
    switch (themeClass) {
        case ThemeClass.HighContrast:
        case ThemeClass.Dark:
            theme = "dark";
            currentTheme = teamsDarkTheme;
            break;
    }
    return mergeThemes(currentTheme, ThemeCustomizations(theme));
}

const isPhoneOrTablet = async () => {
    const context = await microsoftTeams.app.getContext();
    const hosts = [HostClientType.ios, HostClientType.android, HostClientType.ipados];
    return hosts.includes(context.app.host.clientType);
}

export const MsTeamsModule = {
    isAppFromTeams,
    isPhoneOrTablet,
}