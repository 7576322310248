import {UserSettingsDialogStep, UserSettingsStepData} from "../UserSettingsDialog.types";
import {useBestPracticesCache} from "../../../../hooks/cache/bestPracticesCache";
import {useEffect, useMemo, useState} from "react";
import {BestPracticesModule} from "../../../../modules/BestPractices.module";
import {translations} from "../../../../translations";
import {useUserDataCache} from "../../../../hooks/cache/userDataCache";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {HistoryService} from "../../../../services/HistoryService/HistoryService.hook";

const selectedItemsCacheKey = "useInterestsStep-selectedItems";

export const useInterestsStep = (
    jobFamilies: Immutable<Array<string>>,
    channels: Immutable<Array<string>>,
    topics: Immutable<Array<string>>
): UserSettingsStepData => {
    const {userData} = useUserDataCache();
    const {bestPractices} = useBestPracticesCache();
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    useEffect(() => {
        const items = userData?.interests?.tags ?? HistoryService.getComponentState<Array<string>>(selectedItemsCacheKey) ?? [];
        setSelectedItems([...items]);
    }, [userData?.interests?.tags]);

    useEffect(() => {
        HistoryService.saveComponentState<Array<string>>(selectedItemsCacheKey, selectedItems);
    }, [selectedItems]);

    const items = useMemo(() => {
        const filteredBestPractices = bestPractices?.filter(bp => (
            jobFamilies.some(jf => bp.jobFamilies.includes(jf)) &&
            channels.some(c => bp.channels.includes(c)) &&
            topics.some(t => bp.topics.includes(t))
        ));
        return BestPracticesModule.getTags(filteredBestPractices);
    }, [bestPractices, jobFamilies, channels, topics]);

    const maxSelectedItems = 20;

    return {
        step: UserSettingsDialogStep.Interests,
        isValid: selectedItems.length > 0 && selectedItems.length <= maxSelectedItems,
        items,
        selectedItems,
        title: translations.get("UserSettingsInterestsTitle"),
        subtitle: translations.get("UserSettingsInterestsSubtitle"),
        toggleItem: (item) => setSelectedItems((prev) => prev.includes(item) ? prev.filter((t) => t !== item) : [...prev, item]),
        maxSelectedItems,
        searchPlaceholder: translations.get("SearchInterest"),
        selectedItemsLabel: translations.get("YouHaveSelectedXInterests", {count: selectedItems.length + "/" + maxSelectedItems}),
    }
}