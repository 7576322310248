import {FormItem, Input, useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "../../translations";
import {MentionModule} from "../../modules/Mention.module";
import scssVariables from "../../variables.module.scss";
import {ScreenModule} from "../../modules/Screen.module";
import {News, NewsButton} from "common";
import {Immutable, ImmutableObject} from "@witivio_teamspro/use-reducer";
import {useEffect, useMemo} from "react";

export const useEditMessageForm = (
    message: ImmutableObject<News> | undefined,
    buttons: Immutable<Array<NewsButton>>,
    disabled: boolean,
) => {
    const isSmallScreen = ScreenModule.isSmallScreen();

    useEffect(() => {
        messageForm.setFieldsInitialValues({
            message: message?.message,
            visible: !message ? true : message?.show,
        });
        messageForm.reset();
    }, [message]);

    useEffect(() => {
        //@ts-ignore
        buttonsForm.setFieldsInitialValues(getButtonsInitialValues(buttons));
        buttonsForm.reset();
    }, [buttons]);

    const messageForm = useForm({
        disabled,
        items: {
            message: {
                type: "richTextInput",
                placeholder: translations.get("WriteYourMessage"),
                height: "100%",
                maxHeight: "240px",
                maxLength: 400,
                showMentions: true,
                onSuggestMentions: MentionModule.fetchSuggestions,
                mentionTextColor: scssVariables.primaryColor,
            },
            visible: {
                type: "checkbox",
                toggle: true,
                label: translations.get(isSmallScreen ? "Show" : "ShowMessage"),
            },
        }
    });

    const buttonsItems = useMemo(() => generateButtonsItems(buttons), [buttons]);

    const buttonsForm = useForm({
        disabled,
        items: buttonsItems,
    });

    return {
        messageForm,
        buttonsForm,
    }
}

const generateButtonsItems = (buttons: readonly ImmutableObject<NewsButton>[] | undefined) => {
    const inputs: Array<Record<string, FormItem>> | undefined = buttons?.map((button) => ({
        [button.id + "_title"]: Input({
            inputMode: "text",
            placeholder: translations.get("EnterTitle"),
            initialValue: button.title,
            maxLength: 50,
        }),
        [button.id + "_link"]: Input({
            inputMode: "url",
            placeholder: translations.get("EnterLink"),
            initialValue: button.link,
            maxLength: 500,
        })
    }));
    const items: Record<string, FormItem> = {};
    inputs?.forEach(input => Object.assign(items, input));
    return items;
}

const getButtonsInitialValues = (buttons: readonly ImmutableObject<NewsButton>[]) => {
    const initialValues: Record<string, string> = {};
    buttons.forEach(button => {
        initialValues[button.id + "_title"] = button.title;
        initialValues[button.id + "_link"] = button.link;
    });
    return initialValues;
}