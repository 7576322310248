import React, {memo, ReactElement, useLayoutEffect, useMemo, useRef} from "react";
import {DateRangeType, Props, State} from "./DateRangeSelector.types";
import {MagicDispatch, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {DateRangeSelectorHelpers, initialState, reducer} from "./DateRangeSelector.reducer";
import {CompareModule} from "modules/Compare.module";
import "./DateRangeSelector.styles.scss";
import {ArrowRightIcon, Button, DatepickerCalendar, Flex, Input, PopperRefHandle} from "@fluentui/react-northstar";
import moment from "moment/moment";
import {WeekDaysModule} from "modules/WeekDays.module";
import {translations} from "translations";
import {useMsTeamsSelector} from "hooks/useMsTeams";
import {PopupMenuButton} from "../../buttons/PopupMenuButton/PopupMenuButton";

export const DateRangeSelector = memo((props: Props): ReactElement | null => {
    const datePickerPopperRef = useRef<PopperRefHandle | null>(null);
    const popupContentRef = useRef<HTMLDivElement | null>(null);
    const [state, dispatch] = useMagicReducer(reducer({
        props,
        datePickerPopperRef,
        popupContentRef
    }), initialState(props), props.externalRef);

    useLayoutEffect(function onMount() {
        props.onDateRangeChange?.(state.selectedDate, state.selectedRange);
    }, []);

    const {fullLocale} = useMsTeamsSelector("fullLocale");

    const shortDaysNames = useMemo(() => DateRangeSelectorHelpers.getShortDaysNames(fullLocale), []);
    const monthsNames = useMemo(() => DateRangeSelectorHelpers.getMonthsNames(fullLocale), []);
    const firstDayOfWeek = useMemo(() => WeekDaysModule.getFirstDayOfWeek(), []);

    const selectedStartDate = moment(state.selectedRange.startDate).toDate();
    const selectedEndDate = moment(state.selectedRange.endDate).toDate();

    const isCustomSelectionMode = state.selectionMode === DateRangeType.Custom;
    const pickerSelectedDate = isCustomSelectionMode && state.isCustomEndDateSelected ? selectedEndDate : selectedStartDate;

    const dateTitle = renderDateTitle(state.selectionMode, selectedStartDate, selectedEndDate);

    const trigger = props.trigger?.(dateTitle) ?? (props.displayAsInput ? (
        <Input className={"w-100"} fluid readOnly disabled={props.disabled ?? false} value={dateTitle}/>
    ) : (
        <Button
            className={"no-shrink"}
            text
            content={dateTitle}
            onClick={dispatch("handleClickTrigger")}
        />
    ))

    const datePicker = (
        <DatepickerCalendar
            shortDays={shortDaysNames}
            months={monthsNames}
            formatMonthDayYear={date => moment(date).locale(fullLocale).format("L")}
            header={{
                onPreviousClick: dispatch("handleChangeMonth", "previous"),
                onNextClick: dispatch("handleChangeMonth", "next"),
            }}
            firstDayOfWeek={firstDayOfWeek as number}
            onDateChange={dispatch("handleChangeDate")}
            dateRangeType={isCustomSelectionMode ? DateRangeType.Day : state.selectionMode as number}
            selectedDate={pickerSelectedDate}
            navigatedDate={pickerSelectedDate}
        />
    )

    return (
        <PopupMenuButton
            className={"date-range-selector-picker " + (props.className ?? "")}
            position={"below"}
            align={"center"}
            on={"click"}
            trigger={{trigger, fill: props.displayAsInput ?? false}}
            closeOnClick={false}
            menu={{
                className: "no-select",
                items: [
                    {
                        key: "selectMode",
                        disabled: true,
                        children: renderModesButtons(state, dispatch, props.availableTypes),
                    },
                    {
                        key: "divider-1",
                        kind: "divider",
                        styles: {margin: "5px 0"},
                    },
                    ...(state.selectionMode === DateRangeType.Custom ? [{
                        key: "customDateRange",
                        disabled: true,
                        children: renderCustomDateButtons(state, dispatch, selectedStartDate, selectedEndDate),
                    }] : []),
                    {
                        key: "date-picker",
                        children: datePicker,
                        className: "no-select"
                    }
                ]
            }}
        />
    )
}, CompareModule.areObjectsEqual);

const renderDateTitle = (selectionMode: State["selectionMode"], date: Date, endDate: Date) => {
    switch (selectionMode) {
        case DateRangeType.Day:
            return date.toLocaleDateString(moment.locale(), {weekday: "short", day: "numeric", month: "long"});
        case DateRangeType.Week:
            return moment(date).startOf("week").format("L") + " - " + moment(date).endOf("week").format("L");
        case DateRangeType.Month:
            return date.toLocaleDateString(moment.locale(), {month: "long", year: "numeric"});
        case DateRangeType.Custom:
            return moment(date).format("L") + " - " + moment(endDate).format("L");
        default:
            return "";
    }
}

const renderModesButtons = (state: State, dispatch: MagicDispatch<typeof reducer>, availableTypes: Props["availableTypes"]) => {
    let modes = [DateRangeType.Day, DateRangeType.Week, DateRangeType.Month, DateRangeType.Custom];
    if (availableTypes) modes = modes.filter(mode => availableTypes.includes(mode));
    else modes = modes.slice(0, 3);
    return (
        <Flex fill gap={"gap.small"} vAlign={"center"}>
            {modes.map(mode => (
                <Button
                    fluid
                    key={"button-mode" + mode}
                    primary={state.selectionMode === mode}
                    content={translations.get(DateRangeType[mode])}
                    onClick={dispatch("handleSwitchMode", mode)}
                />
            ))}
        </Flex>
    )
}

const renderCustomDateButtons = (
    state: State,
    dispatch: MagicDispatch<typeof reducer>,
    selectedDate: Date,
    selectedEndDate: Date
) => {
    return (
        <Flex fill gap={"gap.small"} vAlign={"center"}>
            <Button
                primary={!state.isCustomEndDateSelected}
                fluid
                content={renderDateTitle(DateRangeType.Day, selectedDate, selectedEndDate)}
                onClick={dispatch("handleToggleSelectedCustomDate", false)}
            />
            <ArrowRightIcon/>
            <Button
                primary={state.isCustomEndDateSelected}
                fluid
                content={renderDateTitle(DateRangeType.Day, selectedEndDate, selectedEndDate)}
                onClick={dispatch("handleToggleSelectedCustomDate", true)}
            />
        </Flex>
    )
}