import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./ItemCard.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer, stopPropagation} from "./ItemCard.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ItemCard.styles.scss";
import {
    Button,
    ChatIcon,
    Flex,
    Image,
    LikeIcon,
    ShareGenericIcon,
    Skeleton,
    StarIcon,
    Text
} from "@fluentui/react-northstar";
import {translations} from "translations";
import {useMsTeamsSelector} from "hooks/useMsTeams";
import {useItemLikeCache} from "../../../hooks/cache/likesCache";
import {useUserVisitedItemCache} from "../../../hooks/cache/userDataCache";
import {useItemRecommendationCache, useMyManagerRecommendationsCache} from "../../../hooks/cache/recommendationsCache";
import {PopupMenuButton} from "../../buttons/PopupMenuButton/PopupMenuButton";
import {CheckboxCheckedFilled, StarFilled} from "@fluentui/react-icons";
import {MegaphoneLoud} from "../../../assets/icons";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {ItemDataType} from "common";
import {useThumbnailsCache} from "hooks/cache/thumbnailsCache";

export const ItemCard = memo((props: Props): ReactElement | null => {
    const {
        userId,
        userMail,
        isOnMobile,
        fullLocale
    } = useMsTeamsSelector("userId", "userMail", "isOnMobile", "fullLocale");
    const {isLiked, toggleLike} = useItemLikeCache(userMail, props.item?.type, props.item?.id);
    const {isVisited, markItemVisited} = useUserVisitedItemCache(props.item?.type, props.item?.id);
    const {isRecommended, toggleRecommendation} = useItemRecommendationCache(props.item?.type, props.item?.id);
    const {managerRecommendations} = useMyManagerRecommendationsCache();
    const {iDidItDialog} = useDialogContext();
    const {imageUrl} = useThumbnailsCache(props.item);
    const [state, dispatch] = useMagicReducer(reducer(props, toggleLike, userId, markItemVisited, fullLocale, isOnMobile, userMail, iDidItDialog), initialState);

    const hideSharepointActions = !props.item?.canInteract;
    const hideCommentButton = hideSharepointActions || !props.itemDialogRef;
    const hideReplicatedBadge = !props.item?.isReplicated;
    const isBPRecommendedForUser = managerRecommendations?.bestPractices.includes(props.item?.id ?? "");

    const pictureUrl = useMemo(() => {
        if (props.overrideImage || !props.item?.pictureUrl) return props.overrideImage;
        return imageUrl;
    }, [props.overrideImage, imageUrl]);

    const replicatedBadge = hideReplicatedBadge ? null : (
        <Flex hAlign={"center"} vAlign={"center"} className={"item-card-badge item-card-replicated-badge"}>
            <CheckboxCheckedFilled/>
            <Text truncated content={translations.get("Replicated")} size={"small"} weight={"semibold"}/>
        </Flex>
    );

    const recommendedBadge = !isBPRecommendedForUser ? null : (
        <Flex className={"item-card-badge item-card-starred-badge"}>
            <StarFilled/>
            <Text truncated content={translations.get("Recommended")} size={"small"} weight={"semibold"}/>
        </Flex>
    );

    const picture = (
        <Flex className={"item-card-picture-container"}>
            {!props.item ?
                <Skeleton.Shape className={"item-card-picture"}/>
                :
                <Image
                    key={pictureUrl}
                    className={"item-card-picture"}
                    src={pictureUrl}
                    styles={{opacity: state.imageLoaded ? 1 : 0}}
                    onLoad={dispatch("handleImageLoaded")}
                />
            }
            <Flex hAlign={"end"} className={"item-card-badges-container"}>
                {replicatedBadge}
                {recommendedBadge}
            </Flex>
        </Flex>
    )

    const content = (
        <Flex column fill className={"item-card-content"}>
            {!props.item ?
                <Skeleton.Line height={"20px"} width={"100%"}/>
                :
                <Text size={"large"} weight={"semibold"}
                      className={"item-card-title " + (isVisited ? "item-card-title-visited" : "")}
                      title={props.item.title}
                      content={props.item.title}/>
            }
            {!props.item ?
                <Skeleton.Line height={"30px"} width={"100%"}/>
                :
                <Text className={"item-card-description"} size={"small"} content={props.item.description}/>
            }
        </Flex>
    )

    const footer = props.hideActions ? null : (
        <Flex className={"item-card-footer"} onClick={stopPropagation}>
            {!props.item ?
                <Flex fill wrap styles={{gap: "15px"}}>
                    {!hideSharepointActions && <Skeleton.Shape height={"25px"} width={"50px"}/>}
                    {!hideCommentButton && <Skeleton.Shape height={"25px"} width={"100px"}/>}
                    {!hideSharepointActions && <Skeleton.Shape height={"25px"} width={"80px"}/>}
                </Flex>
                :
                <Flex fill vAlign={"center"} className={"overflow-hidden"}>
                    <Flex fill vAlign={"center"} className={"overflow-hidden"}>
                        {!hideSharepointActions &&
                            <Button
                                text
                                className={"item-card-action"}
                                icon={<LikeIcon className={isLiked ? "northstar-icon-filled" : ""}/>}
                                content={props.item.likes.length + ""}
                                onClick={dispatch("handleToggleLike")}
                                title={translations.get("Like")}
                            />
                        }
                        {!hideCommentButton &&
                            <Button
                                text
                                className={"item-card-action"}
                                icon={<ChatIcon outline/>}
                                content={props.item.comments + ""}
                                onClick={dispatch("handleAddComment")}
                                title={translations.get("Comment")}
                            />
                        }
                        {props.item.type === ItemDataType.BestPractice &&
                            <Button
                                text
                                className={"item-card-action"}
                                icon={<MegaphoneLoud/>}
                                onClick={dispatch("handleIDidIt")}
                                content={translations.get("IDidIt")}
                                title={translations.get("IDidIt")}
                            />
                        }
                    </Flex>
                    <PopupMenuButton
                        trigger={
                            <Button
                                text
                                className={"item-card-action " + (isRecommended ? "item-card-share-recommended" : "")}
                                icon={<ShareGenericIcon outline={false}/>}
                                title={translations.get("Share")}
                            />
                        }
                        menu={[
                            ...(props.hideRecommendButton ? [] : [{
                                key: "recommend",
                                content: translations.get("RecommendToYourTeam"),
                                icon: <StarIcon className={"recommendation-button"} outline={!isRecommended}/>,
                                onClick: toggleRecommendation,
                            }]),
                            {
                                key: "share",
                                content: translations.get("ShareInDirectMessage"),
                                icon: <ShareGenericIcon outline/>,
                                onClick: dispatch("handleShare"),
                            }
                        ]}
                    />
                </Flex>
            }
        </Flex>
    )

    const className = [
        "item-card",
        props.inline && "item-card-inline"
    ].filter(i => i).join(" ");

    return (
        <Skeleton animation={"pulse"} className={className} onClick={dispatch("handleClick")}>
            <Flex className={"overflow-hidden"} fill column gap={"gap.small"}>
                {picture}
                {content}
                {footer}
            </Flex>
        </Skeleton>
    )
}, CompareModule.areObjectsEqual);