import {FilePickerDialogProps, State} from "./FilePickerDialog.types";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {GraphApi} from "../../../apis/Graph/GraphApi";
import {FileData, SearchDriveItemsType} from "common";
import {IFilePickerFile} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/FilePicker/FilePicker";

export const initialState: State = {
    onFileSelected: () => 0,
    searchTimeout: undefined,
    files: [],
    isSearching: true,
    initialFiles: [],
    selectedFileId: undefined,
    search: undefined,
    upload: undefined,
    isUploading: false,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: async ({setState}, _, props: FilePickerDialogProps) => {
        setState({...initialState, ...props});
        config.dialogRef.dispatch("open")();
        const initialFiles = await searchFiles("", props.types as Array<SearchDriveItemsType> | undefined, props.search);
        setState({initialFiles, files: initialFiles, isSearching: false});
    },
    searchQueryChanged: ({state, setState}, _, searchQuery: string | undefined) => {
        if (state.searchTimeout) clearTimeout(state.searchTimeout);
        setState({
            isSearching: true,
            searchTimeout: setTimeout(async () => {
                if (!searchQuery) return setState({isSearching: false, files: state.initialFiles});
                setState({isSearching: true});
                const files = await searchFiles(searchQuery, state.types as Array<SearchDriveItemsType> | undefined, state.search);
                setState({files: files, isSearching: false});
            }, !searchQuery ? 0 : 800)
        })
    },
    selectFile: ({setState}, [file]: [Immutable<FileData>]) => {
        setState({selectedFileId: file.id + ""});
    },
    confirmSelection: ({state}) => {
        const selectedFile = state.files.find(file => file.id + "" === state.selectedFileId);
        if (!selectedFile) return;
        state.onFileSelected(selectedFile);
        config.dialogRef.dispatch("close")();
    },
    uploadFile: async ({state, setState}, _, file: IFilePickerFile) => {
        if (!state.upload) return;
        setState({isUploading: true});
        const uploadedFile = await state.upload(file);
        setState({
            isUploading: false,
            files: uploadedFile ? [uploadedFile, ...state.files] : state.files,
            initialFiles: uploadedFile ? [uploadedFile, ...state.initialFiles] : state.initialFiles,
        });
    },
}) satisfies MagicReducerObject<State>;

const searchFiles = async (query: string, types: Array<SearchDriveItemsType> | undefined, search?: State["search"]) => {
    if (search) return search(query);
    return await GraphApi.searchDriveItems({query, types, take: 30});
}