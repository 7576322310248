import React, {memo, ReactElement} from "react";
import {Props} from "./NewsCard.types";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./NewsCard.reducer";
import {CompareModule} from "modules/Compare.module";
import "./NewsCard.styles.scss";
import {Button, Flex, Skeleton} from "@fluentui/react-northstar";
import MarkdownView from "react-showdown";
import {ColorModule} from "../../../modules/Color.module";
import {GraphVideoPlayer} from "../GraphVideoPlayer/GraphVideoPlayer";
import {useNewsCache} from "../../../hooks/cache/useNewsCache";
import {ScreenModule} from "../../../modules/Screen.module";

export const NewsCard = memo((props: Props): ReactElement | null => {
    const {news} = useNewsCache(props.newsId);
    const [, dispatch] = useMagicReducer(reducer, initialState);

    const isSmallScreen = ScreenModule.isSmallScreen();

    const showButtons = (news?.buttons?.length ?? 0) > 0;

    return (
        <Skeleton animation={"pulse"} className={"news-card"}>
            <Flex fill className={"overflow-hidden"} column={isSmallScreen}>
                <Flex fill column style={{gap: "10px", padding: "10px 0 10px 10px"}} className={"overflow-hidden"}>
                    {!news ?
                        <Flex fill column gap={"gap.smaller"}>
                            <Skeleton.Line height={"20px"} width={"80%"}/>
                            <Skeleton.Line height={"20px"} width={"60%"}/>
                            <Skeleton.Line height={"20px"} width={"30%"}/>
                        </Flex>
                        :
                        <MarkdownView style={{maxHeight: "200px", overflow: "scroll"}} className={"markdown-view"}
                                      markdown={news?.message ?? ""}/>
                    }
                    {showButtons &&
                        <Flex gap={"gap.small"}>
                            {news?.buttons?.map(b => (
                                <Button
                                    key={b.id}
                                    primary
                                    className={"custom-button"}
                                    styles={{
                                        backgroundColor: b.color + " !important",
                                        color: ColorModule.getContrastingTextColor(b.color) + " !important",
                                    }}
                                    content={b.title}
                                    onClick={dispatch("handleOpenLink", b.link)}
                                />
                            ))}
                        </Flex>
                    }
                </Flex>
                {news?.selectedMedia &&
                    <GraphVideoPlayer
                        {...news.selectedMedia}
                        key={news.id.toString()}
                        height={"200px"} width={"300px"}
                        style={{borderRadius: "0"}}
                    />
                }
            </Flex>
        </Skeleton>
    )
}, CompareModule.areObjectsEqual);