import * as microsoftTeams from "@microsoft/teams-js";
import {getAppConfiguration} from "../services/ConfigurationService/ConfigurationService";

const isUrlSharepointPage = (url: string) => RegExp(/https:\/\/.*?\.sharepoint\.com\/sites\/.*\.aspx/g).test(url);

const addSharepointPageParameters = (url: string, fullLocale?: string) => {
    const pageUrlWithParameters = new URL(url);
    pageUrlWithParameters.searchParams.set("useTeamsAuth", "true");
    pageUrlWithParameters.searchParams.set("env", "Embedded");
    if (fullLocale) pageUrlWithParameters.searchParams.set("locale", fullLocale);
    return pageUrlWithParameters.href;
}

const generateTeamsAuthUrl = (url: string, loginHint: string) => {
    const origin = new URL(url.startsWith("http") ? url : "https://" + url).origin;
    return `${origin}/_layouts/15/teamslogon.aspx?spfx=true&login_hint=${encodeURIComponent(loginHint)}&dest=${encodeURIComponent(url)}`;
}

const openUrlInVivaConnections = async (url: string, title?: string) => {
    const vivaConnectionsAppId = "d2c6f111-ffad-42a0-b65e-ee00425598aa";
    const context = {
        name: title,
        contentUrl: url,
        websiteUrl: url,
        fallbackUrl: url
    };
    const encodeContext = encodeURIComponent(JSON.stringify(context));
    const deeplink = `https://teams.microsoft.com/l/stage/${vivaConnectionsAppId}/0?context=${encodeContext}`;
    await microsoftTeams.app.openLink(deeplink);
}

const openPageInStageView = async (isOnMobile: boolean, pageUrl: string, userUpn: string, fullLocale?: string, title?: string) => {
    if (!pageUrl) return;
    if (!SharepointModule.isUrlSharepointPage(pageUrl)) return window.open(pageUrl, "_blank");
    const pageUrlWithParameters = addSharepointPageParameters(pageUrl, fullLocale);
    if (isOnMobile) return window.open("ms-sharepoint://" + encodeURIComponent(pageUrlWithParameters), "_blank");
    const teamsAuthUrl = generateTeamsAuthUrl(pageUrlWithParameters, userUpn);
    await openUrlInVivaConnections(teamsAuthUrl, title);
}

const getAuthUrl = (sharepointSiteBaseUrl: string, loginHint: string) => {
    const url = new URL(sharepointSiteBaseUrl.startsWith("https") ? sharepointSiteBaseUrl : `https://${sharepointSiteBaseUrl}`);
    return `${url.origin}/_layouts/15/Authenticate.aspx?login_hint=${encodeURIComponent(loginHint)}&source=${encodeURIComponent(window.location.origin)}}`;
}

const silentAuth = (loginHint: string) => new Promise<void>((resolve) => {
    const appConfig = getAppConfiguration();
    if (!appConfig) throw new Error("Configuration not found");
    const authUrl = SharepointModule.getAuthUrl(appConfig.sharepointSiteBaseUrl, loginHint);
    const iframe = document.createElement("iframe");
    iframe.src = authUrl;
    iframe.style.display = "none";
    iframe.onload = () => {
        iframe.remove();
        resolve();
    };
    document.body.appendChild(iframe);
});

export const SharepointModule = {
    openPageInStageView,
    isUrlSharepointPage,
    getAuthUrl,
    silentAuth,
    generateTeamsAuthUrl,
}