import {memo, ReactElement, useLayoutEffect, useRef, useState} from "react";
import {Props} from "./Configuration.interfaces";
import {Immutable, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Configuration.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Configuration.styles.scss";
import {Button, Divider, Flex, SaveIcon, SendIcon, Text} from "@fluentui/react-northstar";
import {translations} from "../../../translations";
import {Preview} from "../Preview/Preview";
import {Notification} from "common";
import {NotificationSentDialog} from "../NotificationSentDialog/NotificationSentDialog";
import {useNotificationTemplatesCache} from "../../../hooks/cache/notificationTemplatesCache";
import {useNotificationConfigurationForm} from "../../../hooks/forms/useNotificationConfigurationForm";

export const Configuration = memo((props: Props): ReactElement | null => {
    const templateRef = useRef<Immutable<Notification>>();
    const [disableForm, setDisableForm] = useState(false);
    const notificationSentDialogRef = useMagicReducerRef(NotificationSentDialog);

    const {addTemplate} = useNotificationTemplatesCache();

    const notificationConfigurationForm = useNotificationConfigurationForm(disableForm);

    const [state, dispatch] = useMagicReducer(reducer({
        templateRef,
        addTemplate,
        notificationSentDialogRef,
        notificationConfigurationForm
    }), initialState, props.externalRef);

    useLayoutEffect(() => {
        setDisableForm(state.isSendingNotification || state.isAddingTemplate);
    }, [state.isSendingNotification, state.isAddingTemplate]);

    const title = notificationConfigurationForm.state.data.title ?? "";
    const subtitle = notificationConfigurationForm.state.data.subtitle ?? "";

    const disableButtons = !notificationConfigurationForm.isValid || state.isSendingNotification || state.isAddingTemplate;

    return (
        <Flex column fill className={"notification-configuration"}>
            <Flex column fill className={"overflow-scroll"} style={{gap: "10px", padding: "10px"}}>
                <Flex column gap="gap.medium" className={"no-shrink"}>
                    {notificationConfigurationForm.formItems.title}
                    {notificationConfigurationForm.formItems.subtitle}
                </Flex>
                <Flex column fill>
                    <Text content={translations.get("Preview")}/>
                    <Flex className={"notification-preview-container"} fill vAlign={"center"} hAlign={"center"}>
                        <Preview title={title} subtitle={subtitle}/>
                    </Flex>
                </Flex>
                <Divider content={translations.get("AdvancedFilters")}/>
                <Flex column gap="gap.medium" className={"no-shrink"}>
                    {notificationConfigurationForm.formItems.communities}
                    {notificationConfigurationForm.formItems.locations}
                    {notificationConfigurationForm.formItems.jobFamilies}
                </Flex>
            </Flex>
            <Divider style={{padding: 0}}/>
            <Flex gap={"gap.small"} space={"between"} style={{padding: "10px"}}>
                <Button
                    icon={<SaveIcon size={"large"} outline/>}
                    content={translations.get("SaveTemplate")}
                    disabled={disableButtons}
                    loading={state.isAddingTemplate}
                    onClick={dispatch("saveTemplate")}
                />
                <Button
                    icon={<SendIcon outline/>}
                    content={translations.get("SendNotification")} primary
                    disabled={disableButtons}
                    loading={state.isSendingNotification}
                    onClick={dispatch("sendNotification")}
                />
            </Flex>
            <NotificationSentDialog externalRef={notificationSentDialogRef}/>
        </Flex>
    )
}, CompareModule.areObjectsEqual);