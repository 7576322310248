import {Flex, Text} from "@fluentui/react-northstar"
import {SearchFilled} from "@fluentui/react-icons";
import {translations} from "../../../translations";

export const NoResultBanner = () => {
    return (
        <Flex className={"primary-color"} vAlign={"center"} gap={"gap.smaller"}
              style={{padding: "10px", opacity: 0.6}}>
            <SearchFilled fontSize={25}/>
            <Text weight={"semibold"} size={"large"} content={translations.get("NoResultsSearchMessage")}/>
        </Flex>
    )
}