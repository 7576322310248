import React, {memo, ReactElement, useEffect} from "react";
import {Props, State} from "./FilterMobileDialog.types";
import {Immutable, MagicDispatch, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./FilterMobileDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import "./FilterMobileDialog.styles.scss";
import {
    AcceptIcon,
    Accordion,
    Button,
    Checkbox,
    ChevronDownIcon,
    ChevronEndIcon,
    Divider,
    Flex,
    Input,
    RetryIcon,
    Text
} from "@fluentui/react-northstar";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {translations} from "translations";
import {IFilterItem} from "../Filter.types";

export const FilterMobileDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer(dialogRef), initialState);

    useEffect(function onToggleShow() {
        if (props.show) {
            dialogRef.dispatch("open")();
            return;
        }
        dispatch("clearOpenMenus")();
    }, [props.show]);

    return (
        <Dialog
            fullscreen
            title={translations.get("Filters")}
            externalRef={dialogRef}
            content={
                <Flex fill column styles={{padding: "0 15px"}}
                      className={"overflow-scroll"}>
                    <Flex styles={{marginLeft: "-10px", marginTop: "10px"}}>
                        <Button
                            icon={<RetryIcon/>}
                            primary
                            text
                            content={translations.get("Reset")} onClick={props.filterDispatch("handleResetFilters")}
                        />
                    </Flex>
                    <Flex fill column>
                        {props.items.map(item => renderItem({
                            item,
                            props,
                            state,
                            dispatch
                        }))}
                    </Flex>
                </Flex>
            }
        />
    );
}, CompareModule.areObjectsEqual);

const renderItem = (data: {
    item: Immutable<IFilterItem>,
    parentItem?: Immutable<IFilterItem>,
    props: Props,
    state: Immutable<State>,
    dispatch: MagicDispatch<typeof reducer>
}) => {
    const hasMenu = !!data.item.menu;
    const isMenuOpen = hasMenu && data.state.openMenuIds.includes(data.item.key);
    const unitWidth = (!!data.item.input?.unit ? data.item.input.unit.width : 0) + 15;
    const inputMaxWidth = unitWidth + 80;
    const isParentUniqueMenu = data.parentItem?.menu?.unique;
    return (
        <Flex gap="gap.small" className={"w-100 overflow-hidden"} key={data.item.key}>
            {!data.parentItem ? null : <Divider vertical/>}
            {!hasMenu ?
                <Flex className={"w-100"} vAlign={"center"}
                      onClick={data.props.filterDispatch("handleToggleItem", [data.item.key, data.parentItem?.key])}
                      styles={{minHeight: "55px"}}>
                    <Flex gap={"gap.small"} className={"w-100"} styles={{maxWidth: "calc(100% - 30px)"}}>
                        {data.item.icon}
                        <Text truncated content={data.item.label}/>
                    </Flex>
                    {!data.item.input ? null :
                        <Flex
                            className={"filter-input-container"}
                            styles={{maxWidth: inputMaxWidth + "px"}}
                            onClick={(e: React.SyntheticEvent<HTMLElement, Event>) => e.stopPropagation()}>
                            <Input
                                type={data.item.input.type ?? ""}
                                fluid
                                value={data.item.input.value}
                                input={{styles: {paddingRight: unitWidth + "px"}}}
                                className={"filter-input"}
                                icon={<Text styles={{color: "grey"}} content={data.item.input.unit.label}/>}
                                onChange={data.props.filterDispatch("handleFilterItemInputValueChange", data.item.key)}
                            />
                        </Flex>
                    }
                    {data.item.input?.hideCheckbox ? null :
                        <Flex hAlign={"end"}>
                            {isParentUniqueMenu ?
                                <AcceptIcon
                                    className={"unique-menu-item-check " + (data.item.isChecked ? "checked" : "")}/>
                                :
                                <Checkbox labelPosition={"start"} checked={data.item.isChecked ?? false}/>
                            }
                        </Flex>
                    }
                </Flex>
                :
                <Accordion
                    className={"w-100"}
                    activeIndex={isMenuOpen ? [0] : []}
                    renderPanelTitle={() => (
                        <Flex key={"accordion-title"} className={"w-100"} vAlign={"center"} styles={{minHeight: "55px"}}
                              onClick={data.dispatch("toggleMenu", data.item.key)} gap={"gap.small"}>
                            <Flex className={"w-100"} styles={{maxWidth: "calc(100% - 26px)"}}>
                                <Text truncated content={data.item.label}/>
                            </Flex>
                            {isMenuOpen ?
                                <ChevronDownIcon outline styles={{color: "#C8C6C4"}}/>
                                :
                                <ChevronEndIcon outline styles={{color: "#C8C6C4"}}/>
                            }
                        </Flex>
                    )}
                    renderPanelContent={() => (
                        <Flex key={"accordion-content"} column className={"w-100"} styles={{marginTop: "10px"}}>
                            {data.item.menu?.items.map(subItem => renderItem({
                                ...data,
                                item: subItem,
                                parentItem: data.item,
                            }))}
                        </Flex>
                    )}
                    panels={[{content: "", title: ""}]}
                />
            }
        </Flex>
    )
}