import {State} from "./NewsCarousel.types";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";

export const initialState: State = {
    container: null,
    slidesPerView: 0,
}

export const reducer = {
    containerRef: ({state, setState}, [container]: [HTMLDivElement]) => {
        if (!container) return;
        const containerWidth = container.clientWidth;
        const slidesPerView = getSlidesPerView(containerWidth);
        setState({container, slidesPerView}, state.slidesPerView === 0);
    },
    windowResize: ({state, setState}) => {
        const containerWidth = state.container?.clientWidth;
        if (!containerWidth) return;
        const slidesPerView = getSlidesPerView(containerWidth);
        if (state.slidesPerView === slidesPerView) return;
        setState({slidesPerView});
    },
} satisfies MagicReducerObject<State>;

const getSlidesPerView = (containerWidth: number) => {
    let value = Math.round(containerWidth / 800);
    if (value < 1) value = 1;
    return value;
}