import React, {ReactElement, useReducer} from "react";
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {AppView} from "types/AppView/AppView"
import {CallControlPresentNewIcon, Flex, Provider, Text, ThemeInput} from "@fluentui/react-northstar";
import {useAppInitializer} from "hooks/useAppInitializer";
import {wrapWithErrorBoundary} from "components/others/ErrorBoundary/ErrorBoundary";
import {AppViewData} from "types/AppView/AppViewData";
import {useMsTeamsSelector} from "hooks/useMsTeams";
import {translations} from "./translations";
import {AppViews} from "./const/AppViews";
import {DialogProvider} from "./services/DialogContext/DialogContext";
import {TelemetryService} from "./services/TelemetryService/TelemetryService";
import {Consent} from "./views/Consent/Consent";
import {LoadingPage} from "./views/LoadingPage/LoadingPage";
import {Navbar} from "./components/others/Navbar/Navbar";

export const App = () => {
    const [renderKey, render] = useReducer(() => Math.random(), Math.random());
    const {loaded, preparing} = useAppInitializer();
    const {
        locale,
        theme,
        isInMeetingSidePanel
    } = useMsTeamsSelector("locale", "theme", "isInMeetingSidePanel");

    const wrapWithFluentUI = getFluentUIWrapper(locale, theme);

    if (isInMeetingSidePanel) return wrapWithFluentUI(renderMeetingSidePanelMessage());

    if (preparing) return wrapWithFluentUI(<LoadingPage label={translations.get("InitializationInProgress")}/>);

    if (!loaded) return wrapWithFluentUI(<Consent/>);

    const navbar = <Navbar initialActiveItem={getActiveNavbarItem()} onRefreshLocation={render}/>;

    return wrapWithFluentUI(
        <BrowserRouter>
            <TelemetryService>
                <DialogProvider>
                    <Flex fill column className={"overflow-hidden no-select"}>
                        {navbar}
                        <Routes>
                            {renderRoutes(AppViews, renderKey)}
                            <Route path="*" element={<Navigate to={AppViews[AppView.Home].path} replace/>}/>
                        </Routes>
                    </Flex>
                </DialogProvider>
            </TelemetryService>
        </BrowserRouter>
    );
}

const renderRoutes = (appViews: Record<AppView, AppViewData>, renderKey: number) => {
    return Object.values(appViews).map(view => (
        <Route key={view.path} path={view.path} element={wrapWithErrorBoundary(<view.element renderKey={renderKey}/>)}/>
    ))
}

const getFluentUIWrapper = (locale: string, theme: ThemeInput) => (children: ReactElement) => (
    <Provider lang={locale} theme={theme}>
        {children}
    </Provider>
)

const renderMeetingSidePanelMessage = () => (
    <Text>
        {translations.get("MeetingSidePanelMessage")}
        <CallControlPresentNewIcon size={"large"} styles={{marginLeft: "5px"}} outline/>
    </Text>
)

const getActiveNavbarItem = () => {
    const path = window.location.pathname;
    const activeView = Object.values(AppViews).find(v => v.path === path);
    return activeView?.id ?? AppView.Home;
}