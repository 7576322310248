import {Dropdown, FilePicker, ImagePicker, Input, RichTextInput, useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "../../translations";
import React, {useCallback, useMemo} from "react";
import {AddIcon, Button, Flex, Text, TrashCanIcon} from "@fluentui/react-northstar";
import {ImageAddRegular} from "@fluentui/react-icons";
import {useBestPracticesCache} from "../cache/bestPracticesCache";
import {InfoTooltip} from "../../components/others/InfoTooltip/InfoTooltip";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";

export const useShareItemForm = (disabled: boolean) => {
    const {bestPractices} = useBestPracticesCache();

    const availableTopics = useMemo(() => {
        return Array.from(new Set(bestPractices?.map(bp => bp.topics).flat()));
    }, [bestPractices]);

    const detailsForm = useForm({
        disabled,
        items: {
            title: Input({
                inputMode: "text",
                label: translations.get("Title"),
                required: true,
                minLength: 5,
                placeholder: translations.get("ShareItemDialogTitlePlaceholder"),
                topRightElement: renderInfoTooltip(translations.get("ShareItemDialogTitleTooltip")),
            }),
            description: RichTextInput({
                label: translations.get("Description"),
                required: true,
                placeholder: translations.get("ShareItemDialogDescriptionPlaceholder"),
                height: "240px",
                maxHeight: "240px",
                maxLength: 800,
                topRightElement: renderInfoTooltip(translations.get("ShareItemDialogDescriptionTooltip")),
            }),
            thumbnail: ImagePicker({
                required: true,
                trigger: renderImagePicker(true),
            }),
            topic: Dropdown({
                required: true,
                label: translations.get("Topic"),
                placeholder: translations.get("SelectATopic"),
                items: availableTopics,
                topRightElement: renderInfoTooltip(translations.get("ShareItemDialogSubjectTooltip")),
            })
        }
    });

    const handleClearImage = useCallback((index: number) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        imagesForm.setFieldsValues({[`image${index}`]: undefined});
        for (let i = index; i <= 4; i++) {
            //@ts-ignore
            imagesForm.setFieldsValues({[`image${i}`]: imagesForm.state.data[`image${i + 1}`]});
        }
    }, []);

    const imagesForm = useForm({
        disabled,
        items: {
            image1: ImagePicker({trigger: renderImagePicker(false, handleClearImage(1))}),
            image2: ImagePicker({trigger: renderImagePicker(false, handleClearImage(2))}),
            image3: ImagePicker({trigger: renderImagePicker(false, handleClearImage(3))}),
            image4: ImagePicker({trigger: renderImagePicker(false, handleClearImage(4))}),
        }
    });

    const resourcesForm = useForm({
        disabled,
        items: {
            file: FilePicker({
                trigger: renderFilePicker(disabled),
                filesTypes: ["image", "excel", "pdf", "powerpoint", "word", "video"],
                maxSizeInKo: 200000,
                onError: (error) => {
                    if (error === "fileTooLarge") {
                        ErrorModule.showErrorDialog({
                            title: translations.get("AnErrorOccured"),
                            subtitle: translations.get("FileSizeErrorMessage", {limit: 200}),
                        });
                    }
                }
            }),
        }
    });

    return {
        detailsForm,
        imagesForm,
        resourcesForm,
    }
}

const renderImagePicker = (showLabel: boolean, handleClearImage?: (e: React.SyntheticEvent) => void) => (value: string | undefined) => {
    return (
        <Flex fill column className={"overflow-hidden"}>
            {showLabel &&
                <Flex style={{gap: "10px"}} vAlign={"center"}>
                    <Text className={"no-shrink"} style={{marginBottom: "4px"}}>
                        {translations.get("Thumbnail")} <span style={{color: "rgb(196, 49, 75)"}}>*</span>
                    </Text>
                    {renderInfoTooltip(translations.get("ShareItemDialogThumbnailTooltip"))}
                </Flex>
            }
            {value ?
                <Flex fill className={"pos-relative share-item-dialog-image"}>
                    <Flex fill className={"fill-absolute overflow-hidden"}>
                        <img src={value} alt={"image"}/>
                    </Flex>
                    {handleClearImage &&
                        <Button
                            className={"share-item-dialog-image-remove-btn"}
                            iconOnly
                            icon={<TrashCanIcon outline/>}
                            tinted
                            onClick={handleClearImage}
                        />
                    }
                </Flex>
                :
                <Flex fill column vAlign={"center"} hAlign={"center"} className={"share-item-dialog-image-picker"}>
                    <ImageAddRegular opacity={0.7} fontSize={60}/>
                    <Text content={translations.get("SelectAnImage")}/>
                </Flex>
            }
        </Flex>
    )
}

const renderFilePicker = (disabled: boolean) => () => {
    return (
        <Button
            disabled={disabled}
            tinted
            icon={<AddIcon/>}
            content={translations.get("AddFile")}
        />
    )
}

const renderInfoTooltip = (content: string) => {
    return (
        <Flex fill hAlign={"start"}>
            <Flex style={{height: "16px", marginLeft: "-10px", marginTop: "-8px"}}>
                <InfoTooltip content={content}/>
            </Flex>
        </Flex>
    )
}