import {ItemData} from "common";

export const SearchAlgorithm = {
    validKeywordBonus: 1000,
    maxSuggestions: 20,
    weights: {
        title: 200,
        description: 50,
        channels: 50,
        brand: 50,
        topics: 50,
        jobFamilies: 10,
        source: 10,
        affiliate: 10,
        tags: 5,
        types: 1,
    } satisfies Partial<Record<keyof ItemData, number>>,
    wordsEquivalences: {
        "ontrade": "on trade",
        "offtrade": "off trade",
        "prchina": "pr china",
        "prfrance": "pr france",
        "prf": "pr france",
        "prna": "pr north america",
        "pruk": "pr uk",
        "direct": "direct off trade",
        "indirect": "indirect off trade",
        "modern": "modern off trade",
        "ecommerce": "e commerce",
        "rtd": "ready to drinks",
        "ready": "ready to drinks",
        "dstar": "d star",
        "consumer": "consumer insights",
        "trade": "trade marketing",
        "field": "field sales",
        "pr": "pernod ricard",
        "mco": "market company",
        "bco": "brand company",
        "ihp": "international hotel program",
        "iig": "international iconic groups",
        "ifp": "international festival program",
        "pros": " pros ",
        "prof": " prof ",
        "profs": " prof ",
        "wl3f": "winning in the last three feet",
        "rpva": "relationship presence visibility activation",
        "ibp": "integrated business planning",
        "5cs": "connect convince convert consume continue",
        "pics": "international consumer segmentation",
        "mocs": "conviviality",
        "cdm": "consumer demand map",
        "wbb": "worlds best bar",
        "stardrinks": "star drinks",
        "syc": "submit your cocktail",
        "bar": "bartender",
        "bpt": "bartender professional training",
        "rtm": "route to market",
        "horeca": "hotels restaurants catering",
        "pos": "point of sale",
        "posm": "point of sale material",
        "vap": "value added pack",
        "b2b": "business to business",
        "b2c": "business to consumer",
        "gp": "gross profit",
        "cm": "contribution margin",
        "caap": "contribution after advertising and promotion",
        "net": "net profit",
        "ap": "advertising and promotion",
        "a&p": "advertising and promotion",
        "nsv": "net sales value",
        "cogs": "cost of goods",
        "yoy": "year on year",
        "fy": "fiscal year",
        "ly": "last year",
        "le": "last estimate",
        "rgm": "revenue growth management",
        "wow": "ways of working",
        "kdp": "key digital programs",
        "mooc": "training"
    }
}