import {DraftMessage, Props, State} from "./NewsDialog.types";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import React from "react";
import {MentionModule} from "../../../modules/Mention.module";
import {Guid, GuidModule, News, NewsButton, SearchDriveItemsType} from "common";
import {translations} from "../../../translations";
import {SessionCacheService} from "../../../services/CacheService/CacheService.hook";
import {Constants} from "../../../const/Constants";
import {useEditMessageForm} from "../../../hooks/forms/useEditMessageForm";
import {Dialog} from "../Dialog/Dialog";
import {GraphApi} from "../../../apis/Graph/GraphApi";
import {useAllNewsCache} from "../../../hooks/cache/useNewsCache";
import {CommunityModule} from "../../../modules/Community.module";
import {ConfirmCancelChangesDialog} from "../ConfirmCancelChangesDialog/ConfirmCancelChangesDialog";
import {FilePickerDialog} from "../FilePickerDialog/FilePickerDialog";
import {NewsApi} from "../../../apis/News/NewsApi";

export const draftMessageCacheKey = "messageOfTheDayDraft";

export const initialState: State = {
    isSaving: false,
    buttons: [],
    selectedCommunity: CommunityModule.generalNewsId,
    draftMessage: null,
    selectedMedia: undefined,
}

type MessageForm = ReturnType<typeof useEditMessageForm>["messageForm"];
type ButtonsForm = ReturnType<typeof useEditMessageForm>["buttonsForm"];

export const reducer = (config: {
    props: Props,
    news: Array<Immutable<News>> | undefined,
    dialogRef: MagicReducerRef<typeof Dialog>,
    upsertNews: ReturnType<typeof useAllNewsCache>["upsertItem"],
    confirmCancelChangesDialog: MagicReducerRef<typeof ConfirmCancelChangesDialog>,
    filePickerDialog: MagicReducerRef<typeof FilePickerDialog>,
    isAdmin: boolean | undefined,
}) => ({
    handleOpen: (reducerData, _, initialCommunity: string = CommunityModule.generalNewsId) => {
        const {dialogRef, news} = config;
        const {setState} = reducerData;
        let selectedCommunity: string | undefined = initialCommunity;
        if (!config.isAdmin && selectedCommunity === CommunityModule.generalNewsId) {
            selectedCommunity = news?.find(n => n.community !== CommunityModule.generalNewsId)?.community;
        }
        const draftMessage = SessionCacheService.getItem<DraftMessage>(draftMessageCacheKey);
        setState({
            ...initialState,
            draftMessage,
            selectedCommunity: selectedCommunity,
        });
        dialogRef.dispatch("open")();
    },
    handleClose: () => {
        const {dialogRef} = config;
        dialogRef.dispatch("close")();
    },
    handleSave: async (reducerData, _, {messageForm, buttonsForm, selectedNews}: {
        messageForm: MessageForm,
        buttonsForm: ButtonsForm,
        selectedNews: Immutable<News> | undefined,
    }) => {
        const {dialogRef} = config;
        const {state} = reducerData;
        const show = messageForm.state.data.visible as boolean;
        const message = messageForm.state.data.message as string;
        const news: News = {
            id: (selectedNews?.id ?? "") as Guid,
            show,
            message,
            buttons: reducerData.state.buttons.map(b => ({
                ...b,
                title: buttonsForm.state.data?.[b.id + "_title"] as string,
                link: buttonsForm.state.data?.[b.id + "_link"] as string,
            })),
            community: state.selectedCommunity,
            selectedMedia: state.selectedMedia,
            updatedAt: new Date().toISOString(),
            createdAt: selectedNews?.createdAt,
        }
        const {setState} = reducerData;
        setState({isSaving: true});
        await config.upsertNews(news);
        setState({isSaving: false});
        dialogRef.dispatch("close")();
        await notifyMentionedUsersInMessage(message);
        SessionCacheService.removeItem(draftMessageCacheKey);
    },
    handleAddButton: (reducerData) => {
        const {state, setState} = reducerData;
        const newButton: NewsButton = {
            id: GuidModule.generateGUID(),
            title: "",
            link: "",
            color: "#00427C"
        }
        const buttons = [...state.buttons, newButton];
        setState({buttons});
    },
    handleDeleteButton: (reducerData, _, id: string) => {
        const {state, setState} = reducerData;
        const buttons = state.buttons.filter(b => b.id !== id);
        setState({buttons});
    },
    handleSetButtonColor: (reducerData, [e]: [React.ChangeEvent<HTMLInputElement>], id: string) => {
        const {state, setState} = reducerData;
        const buttonIndex = state.buttons.findIndex(b => b.id === id);
        if (buttonIndex < 0) return;
        const newButton = {...state.buttons[buttonIndex], color: e.target.value} as Immutable<NewsButton>;
        const buttons = [...state.buttons];
        buttons.splice(buttonIndex, 1, newButton);
        setState({buttons}, false);
    },
    saveDraftMessage: ({state}, [message]: [string]) => {
        if (state.draftMessage) return;
        SessionCacheService.setItem<DraftMessage>(draftMessageCacheKey, {
            community: state.selectedCommunity,
            message,
        });
    },
    handleRetrieveDraft: ({setState}, _, messageForm: MessageForm) => {
        const draftMessage = SessionCacheService.getAndRemoveItem<DraftMessage>(draftMessageCacheKey);
        if (draftMessage) {
            messageForm.setFieldsInitialValues({message: draftMessage.message});
            messageForm.reset();
        }
        setState({draftMessage: null});
    },
    onClose: (_, __, resetForm: () => void) => {
        SessionCacheService.removeItem(draftMessageCacheKey);
        resetForm();
    },
    switchCommunity: ({setState}, [index]: [number], {canSave, menuItems}: {
        canSave: boolean,
        menuItems: { id: string }[] | undefined
    }) => {
        const activeItem = menuItems?.[index];
        if (!canSave) setState({...initialState, selectedCommunity: activeItem?.id});
        else {
            config.confirmCancelChangesDialog.dispatch("open", (confirm) => {
                if (confirm) setState({...initialState, selectedCommunity: activeItem?.id});
            })();
        }
    },
    handleSelectVideo: ({setState}) => {
        config.filePickerDialog.dispatch("open", {
            title: translations.get("SelectAMedia"),
            searchPlaceholder: translations.get("SearchAMedia"),
            types: [SearchDriveItemsType.Video, SearchDriveItemsType.Image],
            onFileSelected: (file) => {
                setState({
                    selectedMedia: {
                        name: file.name + "." + file.extension,
                        itemId: file.fileId,
                        driveId: file.driveId,
                    }
                });
            },
            search: NewsApi.searchMedia,
            upload: (file) => NewsApi.uploadMedia(file.name, file.content),
        })();
    },
    handleClearVideo: ({setState}) => {
        setState({selectedMedia: undefined});
    },
    handleSelectNews: ({setState}, _, newsId: string | undefined) => {
        const news = config.news?.find(n => n.id.toString() === newsId);
        const buttons = news?.buttons ?? [];
        const selectedMedia = news?.selectedMedia;
        setState({buttons, selectedMedia});
    }
}) satisfies MagicReducerObject<State>;

const notifyMentionedUsersInMessage = async (message: string) => {
    const mentions = MentionModule.extractMentions(message);
    if (mentions.length === 0) return;
    const userIds = mentions.map(m => m.mentionId);
    await GraphApi.sendNotification({
        appName: Constants.appName,
        userIdsOrUpns: userIds,
        title: translations.en.CheckOutMessageOfTheDay + "!",
        entityId: "starvoice",
    });
}