import {UserSettingsDialogStep, UserSettingsStepData} from "../UserSettingsDialog.types";
import {useBestPracticesCache} from "../../../../hooks/cache/bestPracticesCache";
import {useEffect, useMemo, useState} from "react";
import {BestPracticesModule} from "../../../../modules/BestPractices.module";
import {translations} from "../../../../translations";
import {useUserDataCache} from "../../../../hooks/cache/userDataCache";
import {HistoryService} from "../../../../services/HistoryService/HistoryService.hook";

const selectedItemsCacheKey = "useJobFamilyStep-selectedItems";

export const useJobFamilyStep = (): UserSettingsStepData => {
    const {userData} = useUserDataCache();
    const {bestPractices} = useBestPracticesCache();
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    useEffect(() => {
        const items = userData?.interests?.jobFamilies ?? HistoryService.getComponentState<Array<string>>(selectedItemsCacheKey) ?? [];
        setSelectedItems([...items]);
    }, [userData?.interests?.jobFamilies]);

    useEffect(() => {
        HistoryService.saveComponentState<Array<string>>(selectedItemsCacheKey, selectedItems);
    }, [selectedItems]);

    const items = useMemo(() => BestPracticesModule.getJobFamilies(bestPractices), [bestPractices]);

    return {
        step: UserSettingsDialogStep.JobFamily,
        isValid: selectedItems.length > 0,
        items,
        selectedItems,
        title: translations.get("UserSettingsJobFamilyTitle"),
        subtitle: translations.get("UserSettingsJobFamilySubtitle"),
        toggleItem: (item) => setSelectedItems((prev) => prev.includes(item) ? prev.filter((t) => t !== item) : [...prev, item]),
        fluidItems: true,
        maxSelectedItems: 5,
        searchPlaceholder: translations.get("SearchJobFamily"),
        selectedItemsLabel: translations.get("YouHaveSelectedXJobFamily", {count: selectedItems.length}),
    }
}