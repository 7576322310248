import React, {ReactElement, useEffect} from "react";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Reporting.reducer";
import "./Reporting.styles.scss";
import {Button, CalendarIcon, DownloadIcon, Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../translations";
import {Kpi} from "./Kpi/Kpi";
import {useKpisData} from "./hooks/useKpisData";
import {useBestPracticesCache} from "../../hooks/cache/bestPracticesCache";
import {useTrainingsCache} from "../../hooks/cache/trainingsCache";
import {DateRangeSelector} from "../../components/others/DateRangeSelector/DateRangeSelector";
import {useUserRolesCache} from "../../hooks/cache/useUserRoleCache";

export const Reporting = (): ReactElement => {
    const {bestPractices} = useBestPracticesCache();
    const {trainings} = useTrainingsCache();
    const {isBasicUser} = useUserRolesCache();
    const [state, dispatch, render] = useMagicReducer(reducer(bestPractices, trainings), initialState);

    useEffect(function onMount() {
        dispatch("fetchReports")();
        window.addEventListener("resize", render);
        return () => window.removeEventListener("resize", render);
    }, []);

    const kpisData = useKpisData(state.data);

    const showSkeletons = !state.data || !bestPractices || !trainings;

    const kpis: Array<ReactElement> = Object.entries(kpisData).map(([key, value]) => (
        <Kpi key={key} {...value} showSkeletons={showSkeletons}/>
    ));

    const dateRangeSelector = (
        <DateRangeSelector
            initialDateRange={state.dateRange}
            onDateRangeChange={dispatch("handleDateRangeChange")}
            trigger={date => (
                <Button
                    primary
                    className={"gradient-button"}
                    icon={<CalendarIcon outline/>}
                    content={date}
                />
            )}
        />
    )

    return (
        <Flex fill className={"reporting-view no-select"}>
            <Flex fill column className={"kpis-container"}>
                <Flex>
                    <Flex fill/>
                    <Flex column hAlign={"center"} gap={"gap.medium"} styles={{whiteSpace: "nowrap"}}>
                        <Flex column hAlign={"center"} styles={{gap: "3px"}}>
                            <Text
                                className={"brand-color-400"}
                                size={"larger"}
                                weight={"bold"}
                                content={translations.get("Reporting")}
                            />
                            <Text
                                className={"brand-color-400"}
                                content={translations.get("ReportingSubtitle")}
                            />
                        </Flex>
                        {dateRangeSelector}
                    </Flex>
                    <Flex fill vAlign={"end"} hAlign={"end"}>
                        {!isBasicUser &&
                            <Button
                                primary
                                loading={state.isDownloading}
                                disabled={state.isDownloading}
                                content={translations.get("Download")}
                                icon={<DownloadIcon/>}
                                onClick={dispatch("downloadReport")}
                            />
                        }
                    </Flex>
                </Flex>
                <Flex className={"w-100 kpis-grid"}>
                    {kpis}
                </Flex>
            </Flex>
        </Flex>
    )
}