import {HomeLogic} from "../Home.interfaces";
import {AddIcon, Button, Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../translations";
import React, {useEffect, useMemo} from "react";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";
import {useAllNewsCache} from "../../../hooks/cache/useNewsCache";
import {AutoWidthMenu} from "../../../components/others/AutoWidthMenu/AutoWidthMenu";
import {useAllCommunitiesCache} from "../../../hooks/cache/useCommunityCache";
import {CommunityModule} from "../../../modules/Community.module";
import {useDeeplinkContext} from "../../../hooks/useDeeplinkContext";
import {DeeplinkContextType} from "common";
import {useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {useUserDataCache} from "../../../hooks/cache/userDataCache";
import {NewsCarousel} from "../../../components/others/NewsCarousel/NewsCarousel";
import {ScreenModule} from "../../../modules/Screen.module";

export const News = (logic: HomeLogic) => {
    const {news} = useAllNewsCache();
    const {isAdmin, isBasicUser} = useUserRolesCache();
    const {userData} = useUserDataCache();
    const {communities} = useAllCommunitiesCache();
    const {deeplinkContext, clearDeeplinkContext} = useDeeplinkContext();
    const [activeIndex, setActiveIndex] = React.useState(0);
    const autoWidthMenuRef = useMagicReducerRef(AutoWidthMenu);

    const visibleCommunityNews = useMemo(() => {
        return news?.filter(n => n.show && (n.community === "General" || userData?.interests?.channels.includes(n.community)))
            .map(n => n.community) ?? [];
    }, [news, userData?.interests?.channels]);

    const menuItems = useMemo(() => {
        return CommunityModule.generateMenuItems(!isAdmin ? visibleCommunityNews : communities);
    }, [communities, visibleCommunityNews, isAdmin]);

    useEffect(function showUpdatedNewsFromNotification() {
        if (!menuItems?.length || !deeplinkContext) return;
        if (deeplinkContext?.type === DeeplinkContextType.NewsUpdated) {
            const index = menuItems?.findIndex(i => i.id === deeplinkContext.data) ?? 0;
            autoWidthMenuRef.dispatch("setActiveMenuIndex")(index);
            clearDeeplinkContext();
        }
    }, [deeplinkContext, menuItems]);

    const activeCommunity = menuItems?.[activeIndex]?.id;

    const activeNews = useMemo(() => {
        return news?.filter(n => n.community === activeCommunity);
    }, [activeCommunity, news]);

    if (logic.showSearchResults || (visibleCommunityNews.length === 0 && isBasicUser)) return null;

    const isSmallScreen = ScreenModule.isSmallScreen();

    return (
        <Flex column className={"items-carousel"}>
            <Flex column styles={isSmallScreen ? {padding: "0 20px"} : undefined}>
                <Flex gap={"gap.small"} vAlign={"center"}>
                    <Text
                        className={"home-view-content-title"}
                        size={"larger"}
                        weight={"bold"}
                        content={translations.get("News")}
                    />
                    {!isBasicUser &&
                        <Button
                            styles={{marginTop: "2px", marginLeft: "-2px"}}
                            tinted
                            iconOnly
                            icon={<AddIcon size={"small"}/>}
                            size={"small"}
                            onClick={logic.dispatch("handleEditMessageOfTheDay", activeCommunity)}
                        />
                    }
                </Flex>
                <Text
                    className={"home-view-content-subtitle"}
                    content={translations.get("HomeViewMessageOfTheDayPartSubtitle")}
                />
                <Flex className={"w-100"} styles={{marginBottom: "10px"}}>
                    <AutoWidthMenu
                        defaultMenuIndex={0}
                        items={menuItems}
                        onIndexChange={i => setActiveIndex(i)}
                        externalRef={autoWidthMenuRef}
                    />
                </Flex>
            </Flex>
            <NewsCarousel items={activeNews}/>
        </Flex>
    )
}