import {State} from "./Configuration.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {GuidModule, Notification} from "common";
import React from "react";
import {NotificationSentDialogRef} from "../NotificationSentDialog/NotificationSentDialog.interfaces";
import {useNotificationTemplatesCache} from "../../../hooks/cache/notificationTemplatesCache";
import {ReportingApi} from "../../../apis/Reporting/ReportingApi";
import {useNotificationConfigurationForm} from "../../../hooks/forms/useNotificationConfigurationForm";
import {NotificationsApi} from "../../../apis/Notifications/NotificationsApi";

export const initialState: State = {
    isSendingNotification: false,
    isAddingTemplate: false,
}

export const reducer = (config: {
    notificationConfigurationForm: ReturnType<typeof useNotificationConfigurationForm>,
    templateRef: React.MutableRefObject<Immutable<Notification> | undefined>,
    addTemplate: ReturnType<typeof useNotificationTemplatesCache>["addTemplate"],
    notificationSentDialogRef: NotificationSentDialogRef,
}) => ({
    applyTemplate: (_, [template]: [Immutable<Notification>]) => {
        config.notificationConfigurationForm.setFieldsInitialValues({
            title: template.title,
            subtitle: template.subtitle,
        });
        config.notificationConfigurationForm.reset();
    },
    sendNotification: async ({setState}) => {
        const {notificationConfigurationForm, notificationSentDialogRef} = config;
        setState({isSendingNotification: true});
        const notification = generateNotification(notificationConfigurationForm);
        const userIds = await ReportingApi.getSessionsUserIds();
        if (userIds.length === 0) return setState({isSendingNotification: false});
        await NotificationsApi.send({
            ...notification,
            communities: notificationConfigurationForm.state.data.communities as Array<string>,
            locations: notificationConfigurationForm.state.data.locations as Array<string>,
            jobFamilies: notificationConfigurationForm.state.data.jobFamilies as Array<string>,
        });
        setState({isSendingNotification: false});
        notificationSentDialogRef.dispatch("open")();
    },
    saveTemplate: async ({setState}) => {
        const {notificationConfigurationForm, addTemplate} = config;
        const notification = generateNotification(notificationConfigurationForm);
        setState({isAddingTemplate: true});
        await addTemplate(notification);
        setState({isAddingTemplate: false});
    }
}) satisfies MagicReducerObject<State>;

const generateNotification = (notificationConfigurationForm: ReturnType<typeof useNotificationConfigurationForm>) => {
    const {title, subtitle} = notificationConfigurationForm.state.data;
    const notification: Notification = {
        id: GuidModule.generateGUID(),
        title: title ?? "",
        subtitle: subtitle,
    }
    return notification;
}