import {HomeLogic} from "../Home.interfaces";
import {translations} from "../../../translations";
import React from "react";
import {ScreenModule} from "../../../modules/Screen.module";
import {ItemsCarousel} from "../../../components/others/ItemsCarousel/ItemsCarousel";

export const BestPractices = (logic: HomeLogic) => {
    if (logic.showSearchResults) return null;

    const isSmallScreen = ScreenModule.isSmallScreen();

    return (
        <ItemsCarousel
            title={translations.get("BestPractices")}
            subtitle={translations.get("BestPracticesSubtitle")}
            menuItems={logic.bestPracticesCategories}
            defaultMenuIndex={0}
            onChangeMenuIndex={logic.dispatch("handleClickBestPracticesMenuItem")}
            items={logic.bestPractices}
            cacheKey={"home-best-practices-carousel"}
            itemDialogRef={logic.itemDialogRef}
            headerStyles={isSmallScreen ? {padding: "0 20px"} : undefined}
        />
    )
}