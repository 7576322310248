import {Props, State} from "./Searchbar.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React from "react";
import {InputProps} from "@fluentui/react-northstar";
import {HistoryService} from "../../../services/HistoryService/HistoryService.hook";
import {FilterItemsData, FilterRef} from "../Filter/Filter.types";

export const initialState = (props: Props): State => {
    const historyState = HistoryService.getComponentState<State>(props.id + "_searchbar");
    return {
        query: historyState?.query ?? "",
        timeout: undefined,
        filterItems: undefined,
        blockClearing: true,
    }
}

const saveState = (key: string, state: State) => {
    const {query} = state;
    HistoryService.saveComponentState(key + "_searchbar", {query});
}

export const reducer = (props: Props, filterRef: FilterRef) => ({
    unblockClearing: ({setState}) => {
        setState({blockClearing: false});
    },
    handleInput: (reducerData, payload: [React.SyntheticEvent, (InputProps & { value: string }) | undefined]) => {
        const {state, setState} = reducerData;
        const [, data] = payload;
        const value = (data?.value ?? "") + "";
        setState({query: value});
        if (!props.throttling || (state.timeout === undefined && !value)) return props.onSearch(value, filterRef.state?.data);
        throttleInput(
            props.throttling,
            state.timeout,
            (timeout) => setState({timeout}, false),
            () => {
                props.onSearch(value, filterRef.state?.data);
                saveState(props.id, state);
            },
        );
    },
    handleFilterChanged: (reducerData, [data]: [FilterItemsData]) => {
        const {state, setState} = reducerData;
        setState({filterItems: data}, false);
        props.onSearch(state.query, data);
    },
    clear: (reducerData) => {
        if (reducerData.state.blockClearing) return;
        reducerData.setState({query: ""});
        props.onSearch("", filterRef.state?.data);
    }
}) satisfies MagicReducerObject<State>;

const throttleInput = (
    throttling: number,
    timeout: NodeJS.Timeout | undefined,
    updateTimeout: (timeout: NodeJS.Timeout | undefined) => void,
    notifyChange: () => void) => {
    if (timeout) clearTimeout(timeout);
    const newTimeout = setTimeout(() => {
        notifyChange();
        updateTimeout(undefined);
    }, throttling);
    updateTimeout(newTimeout)
}