import React, {memo, ReactElement} from "react";
import {Props} from "./ItemsGrid.types";
import {CompareModule} from "modules/Compare.module";
import "./ItemsGrid.styles.scss";
import {ItemCard} from "../ItemCard/ItemCard";
import {VirtualizedGrid} from "../VirtualizedGrid/VirtualizedGrid";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {useMyRecommendationsCache} from "../../../hooks/cache/recommendationsCache";
import {ScreenModule} from "../../../modules/Screen.module";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ItemsGrid.reducer";

export const ItemsGrid = memo((props: Props): ReactElement | null => {
    const {itemDialog} = useDialogContext();
    const {canRecommendItem} = useMyRecommendationsCache();
    const virtualizedGridRef = useMagicReducerRef(VirtualizedGrid);
    const [] = useMagicReducer(reducer({virtualizedGridRef}), initialState, props.externalRef);

    const isSmallScreen = ScreenModule.isSmallScreen();

    return (
        <VirtualizedGrid
            externalRef={virtualizedGridRef}
            styles={{marginTop: "-10px", marginBottom: "50px", flexShrink: 0}}
            renderSkeleton={renderSkeleton}
            itemHeight={248}
            gridGap={30}
            itemMinWidth={300}
            gridPadding={isSmallScreen ? 20 : 12}
            showSkeletons={!props.items}
            showAlwaysScrollbar
            visibleLinesOffset={0}>
            {props.items?.map(i => (
                <ItemCard
                    key={i.id} item={i}
                    itemDialogRef={itemDialog}
                    hideRecommendButton={!canRecommendItem}
                />
            ))}
        </VirtualizedGrid>
    )
}, CompareModule.areObjectsEqual);

const renderSkeleton = (key: string) => <ItemCard key={key} item={undefined} hideRecommendButton={true}/>;