import {UserSettingsDialogStep, UserSettingsStepData} from "../UserSettingsDialog.types";
import {useBestPracticesCache} from "../../../../hooks/cache/bestPracticesCache";
import {useEffect, useMemo, useState} from "react";
import {translations} from "../../../../translations";
import {BestPracticesModule} from "../../../../modules/BestPractices.module";
import {useUserDataCache} from "../../../../hooks/cache/userDataCache";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {HistoryService} from "../../../../services/HistoryService/HistoryService.hook";

const selectedItemsCacheKey = "useChannelsStep-selectedItems";

export const useChannelsStep = (jobFamilies: Immutable<Array<string>>): UserSettingsStepData => {
    const {userData} = useUserDataCache();
    const {bestPractices} = useBestPracticesCache();
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    useEffect(() => {
        const items = userData?.interests?.channels ?? HistoryService.getComponentState<Array<string>>(selectedItemsCacheKey) ?? [];
        setSelectedItems([...items]);
    }, [userData?.interests?.channels]);

    useEffect(() => {
        HistoryService.saveComponentState<Array<string>>(selectedItemsCacheKey, selectedItems);
    }, [selectedItems]);

    const items = useMemo(() => {
        const filteredBestPractices = bestPractices?.filter(bp => jobFamilies.some(jf => bp.jobFamilies.includes(jf)));
        return BestPracticesModule.getChannels(filteredBestPractices);
    }, [bestPractices, jobFamilies]);

    const maxSelectedItems = 5;

    return {
        step: UserSettingsDialogStep.Interests,
        isValid: selectedItems.length > 0 && selectedItems.length <= maxSelectedItems,
        items,
        selectedItems,
        title: translations.get("UserSettingsChannelsTitle"),
        subtitle: translations.get("UserSettingsChannelsSubtitle"),
        toggleItem: (item) => setSelectedItems((prev) => prev.includes(item) ? prev.filter((t) => t !== item) : [...prev, item]),
        fluidItems: true,
        searchPlaceholder: translations.get("SearchChannel"),
        maxSelectedItems,
        selectedItemsLabel: renderSelectedItemsLabel(items?.length ?? 0, selectedItems.length, maxSelectedItems),
    }
}

const renderSelectedItemsLabel = (itemsCount: number, selectedCount: number, max: number) => {
    let count = selectedCount + "";
    if (itemsCount > max) count += "/" + max;
    return translations.get("YouHaveSelectedXChannels", {count});
}