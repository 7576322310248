import moment from "moment/moment";
import {Flex, StarIcon} from "@fluentui/react-northstar";
import React from "react";
import {translations} from "../translations";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {ItemData} from "common";
import {IFilterItem} from "../components/others/Filter/Filter.types";

const generateFilterItems = (bestPractices: Immutable<Array<ItemData>> | undefined): Immutable<Array<IFilterItem>> => {
    if (!bestPractices) return [];

    const affiliates = Array.from(new Set(bestPractices.map(b => b.affiliate))).filter(i => i).sort((a, b) => (a + "").localeCompare(b + ""));
    const types = Array.from(new Set(bestPractices.map(b => b.types).flat())).filter(i => i).sort((a, b) => (a + "").localeCompare(b + ""));
    const jobFamilies = Array.from(new Set(bestPractices.map(b => b.jobFamilies).flat())).filter(i => i).sort((a, b) => (a + "").localeCompare(b + ""));
    const topics = Array.from(new Set(bestPractices.map(b => b.topics).flat())).filter(i => i).sort((a, b) => (a + "").localeCompare(b + ""));

    return [
        {
            key: "most-liked",
            label: translations.get("MostLiked"),
        },
        {
            key: "already-liked-or-consulted",
            label: translations.get("AlreadyLikedOrConsulted"),
        },
        {
            key: "management-recommendations",
            label: translations.get("ManagementRecommendations"),
        },
        {
            key: "affiliates",
            label: translations.get("Affiliates"),
            menu: {
                items: affiliates.map(i => ({key: i ?? "", label: i ?? ""})),
            }
        },
        {
            key: "rating",
            label: translations.get("Rating"),
            menu: {
                items: [
                    {key: "1", label: "", icon: renderFilterRatingIcon(1)},
                    {key: "2", label: "", icon: renderFilterRatingIcon(2)},
                    {key: "3", label: "", icon: renderFilterRatingIcon(3)},
                    {key: "4", label: "", icon: renderFilterRatingIcon(4)},
                    {key: "5", label: "", icon: renderFilterRatingIcon(5)},
                ]
            }
        },
        {
            key: "job-family",
            label: translations.get("JobFamily"),
            menu: {
                items: jobFamilies.map(i => ({key: i, label: i,})),
            }
        },
        {
            key: "type",
            label: translations.get("BrandCo/Brand"),
            menu: {
                items: types.map(i => ({key: i, label: i,})),
            }
        },
        {
            key: "topic",
            label: translations.get("Topic"),
            menu: {
                items: topics.map(i => ({key: i, label: i,})),
            }
        },
        {
            key: "from-date",
            label: translations.get("FromDate"),
            input: {
                unit: {
                    label: "",
                    width: 0,
                },
                type: "date",
                value: moment().startOf("day").add(-1, "year").format("YYYY-MM-DD"),
            }
        },
        {
            key: "to-date",
            label: translations.get("ToDate"),
            input: {
                unit: {
                    label: "",
                    width: 0,
                },
                type: "date",
                value: moment().startOf("day").format("YYYY-MM-DD"),
            }
        }
    ]
}

const renderFilterRatingIcon = (rating: number) => {
    return (
        <Flex gap={"gap.smaller"}>
            {Array.from({length: 5}).map((_, i) => (
                <StarIcon styles={{color: "#eb5932"}} key={i} outline={i >= rating}/>
            ))}
        </Flex>
    )
}

const getTopics = (bestPractices: Immutable<Array<ItemData>> | undefined): Immutable<Array<string>> => {
    return Array.from(new Set(bestPractices?.flatMap(bp => bp.topics) ?? [])).filter(Boolean);
}

const getJobFamilies = (bestPractices: Immutable<Array<ItemData>> | undefined): Immutable<Array<string>> => {
    return Array.from(new Set(bestPractices?.flatMap(bp => bp.jobFamilies) ?? [])).filter(Boolean);
}

const getTags = (bestPractices: Immutable<Array<ItemData>> | undefined): Immutable<Array<string>> => {
    return Array.from(new Set(bestPractices?.flatMap(bp => bp.tags) ?? [])).filter(Boolean);
}

const getChannels = (bestPractices: Immutable<Array<ItemData>> | undefined): Immutable<Array<string>> => {
    return Array.from(new Set(bestPractices?.flatMap(bp => bp.channels) ?? [])).filter(Boolean);
}

export const BestPracticesModule = {
    generateFilterItems,
    getTopics,
    getJobFamilies,
    getTags,
    getChannels,
}