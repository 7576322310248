import {CRUDApi} from "../CRUD/CRUDApi";
import {CreateFileRequest, GuidModule, News} from "common";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";
import {AxiosError} from "axios";
import {DriveItem} from "@microsoft/microsoft-graph-types";
import {FileModule} from "../../modules/File.module";

const genericApi = CRUDApi.generate<News>('news');

const searchMedia = async (query: string) => {
    try {
        const response = await AxiosConfig.instance.get(`/news/media/search?query=${encodeURIComponent(query)}`);
        return response.data.map((file: DriveItem) => FileModule.parseRawFile(GuidModule.generateGUID(), file));
    } catch (error) {
        ErrorModule.showErrorAlert("Can't search news media", error as AxiosError);
        return [];
    }
}

const uploadMedia = async (fileName: string, fileContent: string) => {
    try {
        const fileParams: CreateFileRequest = {
            filePath: fileName,
            binary64: fileContent,
        };
        const response = await AxiosConfig.instance.post(`/news/media`, fileParams);
        return FileModule.parseRawFile(GuidModule.generateGUID(), response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't upload news media", error as AxiosError);
    }
}

export const NewsApi = {
    ...genericApi,
    searchMedia,
    uploadMedia,
}