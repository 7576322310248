import {Immutable, ImmutableObject} from "@witivio_teamspro/use-reducer";
import {KpiPropsUnion} from "../Kpi/Kpi.interfaces";
import {translations} from "../../../translations";
import moment from "moment/moment";
import {TopKeywordData} from "../Kpi/KeywordsKpi/KeywordsKpi.interfaces";
import {useBestPracticesCache} from "../../../hooks/cache/bestPracticesCache";
import {useTrainingsCache} from "../../../hooks/cache/trainingsCache";
import {ItemData, ReportingData} from "common";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";
import {ScreenModule} from "../../../modules/Screen.module";

export const useKpisData = (reporting: ImmutableObject<ReportingData> | undefined) => {
    const {bestPractices} = useBestPracticesCache();
    const {trainings} = useTrainingsCache();
    const {isBasicUser} = useUserRolesCache();

    const isSmallScreen = ScreenModule.isSmallScreen();

    return {
        totalQueries: {
            type: "number",
            title: translations.get("TotalQueries"),
            subtitle: "Queries",
            value: reporting?.totalQueries,
        },
        queriesPerDay: {
            title: translations.get("QueriesPerDay"),
            type: "lineChart",
            labels: reporting?.queriesPerDay.map(i => formatReportDate(i.key)) ?? [],
            values: reporting?.queriesPerDay.map(i => i.count) ?? [],
            width: 2,
        },
        mostActiveUsers: {
            title: translations.get("MostActiveUsers"),
            type: "keywords",
            keywords: reporting?.mostActiveUsers,
            renderCustomIndicator: formatSessionTime,
            hideIndicator: isBasicUser,
        },
        visitsPerDay: {
            title: translations.get("VisitsPerDay"),
            type: "lineChart",
            labels: reporting?.visitsPerDay.map(i => formatReportDate(i.key)) ?? [],
            values: reporting?.visitsPerDay.map(i => i.count) ?? [],
            width: 2,
        },
        totalVisits: {
            title: translations.get("TotalVisits"),
            type: "number",
            value: reporting?.totalVisits,
            subtitle: translations.get((reporting?.totalVisits ?? 0) <= 1 ? "Visit" : "Visits"),
        },
        totalUniqueVisits: {
            title: translations.get("TotalUniqueVisits"),
            type: "number",
            value: reporting?.totalUniqueVisits,
            subtitle: translations.get((reporting?.totalUniqueVisits ?? 0) <= 1 ? "UniqueVisit" : "UniqueVisits"),
        },
        mostPopularQueries: {
            title: translations.get("MostPopularQueries"),
            type: "keywords",
            keywords: reporting?.mostPopularQueries,
        },
        mostOpenedFiles: {
            title: translations.get("MostOpenedFiles"),
            type: "keywords",
            keywords: reporting?.openedFiles,
            width: 2,
            onClick: openItem(bestPractices, trainings),
        },
        usersActionsPerDay: {
            title: translations.get("UsersActionsPerDay"),
            type: "lineChart",
            labels: reporting?.usersActionsPerDay.map(i => formatReportDate(i.key)) ?? [],
            values: reporting?.usersActionsPerDay.map(i => i.count) ?? [],
            width: 2,
        },
        ...(!isSmallScreen && {
            filesStatistics: {
                title: translations.get("FilesStatistics"),
                type: "itemsStats",
                width: 2,
                items: combineItemsForStats(bestPractices, trainings, reporting),
            }
        }),
        clicksPerFileType: {
            title: translations.get("ClicksPerFileType"),
            type: "pieChart",
            labels: reporting?.clicksPerFileType.map(i => translations.get(i.key + "s")) ?? [],
            values: reporting?.clicksPerFileType.map(i => i.count) ?? [],
        },
        mostClickedTopics: {
            title: translations.get("ClicksPerTopic"),
            type: "pieChart",
            labels: reporting?.mostClickedTopics.map(i => i.key) ?? [],
            values: reporting?.mostClickedTopics.map(i => i.count) ?? [],
            width: 2,
        },
        ...(!isBasicUser && {
            usagePerDepartment: {
                title: translations.get("UsagePerDepartment"),
                type: "pieChart",
                labels: reporting?.usagePerDepartment.map(i => i.key) ?? [],
                values: reporting?.usagePerDepartment.map(i => i.count) ?? [],
                width: 2,
            },
            usagePerLocation: {
                title: translations.get("UsagePerLocation"),
                type: "pieChart",
                labels: reporting?.usagePerLocation.map(i => i.key) ?? [],
                values: reporting?.usagePerLocation.map(i => i.count) ?? [],
                width: 2,
            }
        }),
    } satisfies Record<string, KpiPropsUnion>;
}

const formatReportDate = (date: string) => {
    return new Date(date).toLocaleDateString(moment.locale(), {
        day: 'numeric',
        month: 'short'
    });
}

const formatSessionTime = (unixTime: number): string => {
    const duration = moment.duration(unixTime, 'seconds');
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    let formattedTime = '';
    if (days > 0) formattedTime += `${days}j `;
    if (hours > 0 || days > 0) formattedTime += `${hours}h `;
    if (minutes > 0 || hours > 0 || days > 0) formattedTime += `${minutes}min `;
    if (seconds > 0 && days === 0) formattedTime += `${seconds}s`;
    return formattedTime.trim();
};

const combineItemsForStats = (
    bestPractices: Immutable<Array<ItemData>> | undefined,
    trainings: Immutable<Array<ItemData>> | undefined,
    reportingData: Immutable<ReportingData> | undefined,
): Immutable<Array<ItemData>> => {
    if (!bestPractices || !trainings) return [];
    return [...bestPractices, ...trainings].map(i => ({
        ...i,
        id: i.id + "_" + i.type + "_" + i.title,
        clicks: reportingData?.openedFiles.find(f => f.id === i.id && f.name === i.title)?.count ?? 0,
        returnRate: reportingData?.openedFilesReturnRate.find(f => f.id === i.id && f.name === i.title)?.count ?? 0,
    }));
}

const openItem = (
    bestPractices: Immutable<ItemData>[] | undefined,
    trainings: Immutable<ItemData>[] | undefined,
) => (item: Immutable<TopKeywordData>) => {
    if (!bestPractices || !trainings) return;
    let link = bestPractices.find(i => i.id === item.id)?.link;
    if (!link) link = trainings.find(i => i.id === item.id)?.link;
    if (!link) return;
    window.open(link, "_blank");
}