import {memo, ReactElement, useEffect, useMemo, useState} from "react";
import {Props} from "./GraphVideoPlayer.types";
import {CompareModule} from "modules/Compare.module";
import "./GraphVideoPlayer.styles.scss";
import {useGraphVideoCache} from "../../../hooks/cache/useGraphVideoCache";
import {Flex, Loader} from "@fluentui/react-northstar";
import {FileModule} from "../../../modules/File.module";
import {FileType} from "common";

export const GraphVideoPlayer = memo((props: Props): ReactElement | null => {
    const [isLoading, setIsLoading] = useState(true);
    const {src} = useGraphVideoCache(props.itemId, props.driveId);

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => setIsLoading(false), 1200);
    }, [props.driveId, props.itemId]);

    const isMediaImage = useMemo(() => {
        const extension = FileModule.getFileExtension(props.name);
        const fileType = FileModule.getFileType(extension);
        return fileType === FileType.Image;
    }, [props.name]);

    return (
        <Flex className={"pos-relative"}
              style={{
                  minHeight: props.height,
                  minWidth: props.width, ...(props.style ?? {}),
                  pointerEvents: isMediaImage ? "none" : "auto"
              }}>
            <iframe
                title={"News Video"}
                className={"graph-video-player"}
                src={src}
                allowFullScreen
            />
            {isLoading &&
                <Flex className={"graph-video-loader"}>
                    <Loader secondary/>
                </Flex>
            }
        </Flex>
    )
}, CompareModule.areObjectsEqual);