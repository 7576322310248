import React, {ReactElement, useMemo} from "react";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Launchpad.reducer";
import "./Launchpad.styles.scss";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import {useAllLaunchpadAppsCache} from "../../hooks/cache/launchpadAppCache";
import {translations} from "../../translations";
import {LaunchpadApp} from "../../components/others/Launchpad/LaunchpadApp/LaunchpadApp";

export const Launchpad = (): ReactElement => {
    const {apps, isLoading} = useAllLaunchpadAppsCache();
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    const appsContainer = useMemo(() => (
        isLoading ?
            <Loader/>
            :
            !apps?.length ?
                <Flex fill vAlign={"center"} hAlign={"center"} style={{height: "100px"}}>
                    <Text content={translations.get("NoApp")}/>
                </Flex>
                :
                <Flex className={"launchpad-apps-container"} column gap={"gap.small"}>
                    {apps?.map(app => <LaunchpadApp key={app.id.toString()} appId={app.id}/>)}
                </Flex>
    ), [apps, isLoading]);

    return (
        <Flex fill className={"launchpad"} style={{paddingTop: "20px"}}>
            <Flex fill className={"launchpad-content"}>
                {appsContainer}
            </Flex>
        </Flex>
    );
}