import React, {ReactElement, useEffect, useMemo} from "react";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {generateFilterItems, generateSearchResultsCategories, initialState, reducer} from "./Home.reducer";
import "./Home.styles.scss";
import {Flex} from "@fluentui/react-northstar";
import {ItemDataModule} from "../../modules/ItemData.module";
import {Searches} from "./parts/Searches";
import {News} from "./parts/News";
import {BestPractices} from "./parts/BestPractices";
import {SearchResults} from "./parts/SearchResults";
import {HomeLogic} from "./Home.interfaces";
import moment from "moment";
import {ScreenModule} from "../../modules/Screen.module";
import {AppViewElementProps} from "../../types/AppView/AppViewData";
import {useMsTeamsSelector} from "hooks/useMsTeams";
import {useBestPracticesCache} from "../../hooks/cache/bestPracticesCache";
import {useTrainingsCache} from "../../hooks/cache/trainingsCache";
import {Carousel} from "components/others/Carousel/Carousel";
import {AutoWidthMenu} from "../../components/others/AutoWidthMenu/AutoWidthMenu";
import {ItemDialog} from "../../components/dialogs/ItemDialog/ItemDialog";
import {Searchbar} from "../../components/others/Searchbar/Searchbar";
import {useDialogContext} from "../../services/DialogContext/DialogContext";
import {useUserDataCache} from "../../hooks/cache/userDataCache";
import {useDeeplinkContext} from "../../hooks/useDeeplinkContext";
import {DeeplinkContextType} from "common";

export const Home = (props: AppViewElementProps): ReactElement => {
    const {userId, userMail} = useMsTeamsSelector("userId", "userMail");
    const {bestPractices} = useBestPracticesCache();
    const {trainings} = useTrainingsCache();
    const {userData} = useUserDataCache();
    const {newsDialog, welcomeDialog, top3ContentDialog} = useDialogContext();
    const {deeplinkContext, clearDeeplinkContext} = useDeeplinkContext();
    const carouselRef = useMagicReducerRef(Carousel);
    const searchesMenuRef = useMagicReducerRef(AutoWidthMenu);
    const bestPracticesMenuRef = useMagicReducerRef(AutoWidthMenu);
    const itemDialogRef = useMagicReducerRef(ItemDialog);
    const searchBarRef = useMagicReducerRef(Searchbar);

    useEffect(() => {
        if (!userData || userData.interests?.topics) return;
        welcomeDialog.dispatch("open")();
    }, [userData]);

    useEffect(() => {
        if (deeplinkContext?.type !== DeeplinkContextType.Top3Content) return;
        top3ContentDialog.dispatch("open")();
        clearDeeplinkContext();
    }, [deeplinkContext]);

    const isSmallScreen = ScreenModule.isSmallScreen();

    const bestPracticesCategories = useMemo(() => ItemDataModule.generateCategories(bestPractices), [bestPractices]);

    const [state, dispatch] = useMagicReducer(reducer(
        bestPractices,
        trainings,
        newsDialog,
        bestPracticesCategories,
        searchesMenuRef,
        userId,
        userMail
    ), initialState());

    useEffect(() => {
        searchBarRef.dispatch("clear")();
    }, [props.renderKey]);

    useEffect(() => {
        window.addEventListener("resize", dispatch("windowResize"));
        return () => window.removeEventListener("resize", dispatch("windowResize"));
    }, []);

    useEffect(() => {
        if (searchBarRef.state?.query)
            dispatch("handleSearch")(searchBarRef.state?.query, searchBarRef.state?.filterItems);
    }, [bestPractices, trainings]);

    let selectedFromDate = (searchBarRef.state?.filterItems?.["from-date"]?.inputValue ?? "") + "";
    if (!selectedFromDate) selectedFromDate = moment().startOf("day").add(-1, "year").format("YYYY-MM-DD");
    let selectedToDate = (searchBarRef.state?.filterItems?.["to-date"]?.inputValue ?? "") + "";
    if (!selectedToDate) selectedToDate = moment().startOf("day").format("YYYY-MM-DD");

    const filterItems = useMemo(() => (
        generateFilterItems(bestPractices, trainings, selectedFromDate, selectedToDate)
    ), [bestPractices, trainings, selectedFromDate, selectedToDate]);

    const searchResultsCategories = useMemo(generateSearchResultsCategories, []);

    const showSearchResults = !!searchBarRef.state?.query && searchBarRef.state?.query?.length > 0;

    const filteredBestPractices = useMemo(() => {
        if (!bestPractices) return undefined;
        let items = [...bestPractices];
        if (state.selectedBestPracticesCategoryIndex !== 0) {
            const selectedCategory = bestPracticesCategories?.[state.selectedBestPracticesCategoryIndex];
            if (selectedCategory) items = items.filter(bp => bp.topics.includes(selectedCategory.key));
        }
        return items;
    }, [state.selectedBestPracticesCategoryIndex, bestPractices]);

    const showSkeletons = !bestPractices || !trainings;

    const logic: HomeLogic = {
        ...state,
        bestPracticesCategories,
        itemDialogRef,
        carouselRef,
        searchBarRef,
        showSearchResults,
        searchResultsCategories,
        filterItems,
        bestPractices: filteredBestPractices,
        dispatch,
        searchesMenuRef,
        bestPracticesMenuRef,
        showSkeletons,
    }

    return (
        <Flex fill column className={"home-view no-select"}>
            <Flex fill column={isSmallScreen} className={"home-view-content"}>
                <Flex className={"home-view-left-content"} fill column>
                    <Searches {...logic}/>
                    <News {...logic}/>
                    <BestPractices {...logic}/>
                    <SearchResults {...logic}/>
                </Flex>
            </Flex>
            <ItemDialog externalRef={logic.itemDialogRef}/>
        </Flex>
    )
}