import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./Launchpad.types";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Launchpad.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Launchpad.styles.scss";
import {PopupMenuButton} from "../../buttons/PopupMenuButton/PopupMenuButton";
import {Button, Flex, Loader, Text} from "@fluentui/react-northstar";
import {AppsRegular} from "@fluentui/react-icons";
import {translations} from "../../../translations";
import {useAllLaunchpadAppsCache} from "../../../hooks/cache/launchpadAppCache";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {LaunchpadApp} from "./LaunchpadApp/LaunchpadApp";

export const Launchpad = memo((props: Props): ReactElement | null => {
    const {launchpadAppsListDialog} = useDialogContext();
    const {apps, isLoading} = useAllLaunchpadAppsCache();
    const {isAdmin} = useUserRolesCache();
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    const trigger = useMemo(() => (
        <Flex className={"launchpad-button"} column hAlign={"center"} vAlign={"center"}>
            <AppsRegular fontSize={50}/>
            <Text content={translations.get("Launchpad")}/>
        </Flex>
    ), []);

    const appsContainer = useMemo(() => (
        isLoading ?
            <Loader/>
            :
            !apps?.length ?
                <Flex fill vAlign={"center"} hAlign={"center"} style={{height: "100px"}}>
                    <Text content={translations.get("NoApp")}/>
                </Flex>
                :
                <Flex className={"launchpad-apps-container"} column gap={"gap.small"}>
                    {apps?.map(app => <LaunchpadApp key={app.id.toString()} appId={app.id}/>)}
                </Flex>
    ), [apps, isLoading]);

    const manageButton = useMemo(() => !isAdmin ? null : (
        <Button
            fluid
            tinted
            content={translations.get("ManageApps")}
            icon={<AppsRegular className={"custom-icon"}/>}
            onClick={launchpadAppsListDialog.dispatch("open")}
        />
    ), []);

    const contentWidth = getLaunchpadContentWidth(apps?.length ?? 0);

    return (
        <PopupMenuButton
            className={"launchpad"}
            position={"below"}
            align={"center"}
            on={"click"}
            trigger={trigger}
            menu={{
                className: "no-select",
                items: [{
                    key: "apps-container",
                    children: (
                        <Flex fill column gap={"gap.small"}>
                            <Flex fill vAlign={"center"} hAlign={"center"} style={{width: contentWidth + "px"}}
                                  className={"launchpad-content"}>
                                {appsContainer}
                            </Flex>
                            {manageButton}
                        </Flex>
                    ),
                    className: "no-select",
                    disabled: true,
                }]
            }}
        />
    )
}, CompareModule.areObjectsEqual)

const getLaunchpadContentWidth = (appsCount: number): number => {
    const appWidth = 100;
    let columns = 1;
    if (appsCount <= 4) columns = 2;
    else if (appsCount <= 9) columns = 3;
    else if (appsCount <= 16) columns = 4;
    else columns = 5;
    return (columns * appWidth) + 60;
}