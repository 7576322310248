import React, {memo, ReactElement, useMemo} from "react";
import {Props, UserSettingsDialogStep, UserSettingsStepData} from "./UserSettingsDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./UserSettingsDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {ArrowRightIcon, Button, Flex, SaveIcon, SearchIcon, Text} from "@fluentui/react-northstar";
import "./UserSettingsDialog.styles.scss";
import {translations} from "../../../translations";
import {useUserDataCache} from "../../../hooks/cache/userDataCache";
import {Input, useForm} from "@witivio_teamspro/northstar-form";
import {TopicButton} from "./TopicButton/TopicButton";
import {useBestPracticesCache} from "../../../hooks/cache/bestPracticesCache";
import {useChannelsStep} from "./hooks/useChannelsStep";
import {useInterestsStep} from "./hooks/useInterestsStep";
import {useJobFamilyStep} from "./hooks/useJobFamilyStep";
import {useTopicsStep} from "./hooks/useTopicsStep";

const stepsLength = Object.keys(UserSettingsDialogStep).filter((key: string | number) => !isNaN(Number(key))).length;

export const UserSettingsDialog = memo((props: Props): ReactElement | null => {
    const {refetch: refetchBestPractices} = useBestPracticesCache();
    const dialogRef = useMagicReducerRef(Dialog);
    const {userData, updateUserData} = useUserDataCache();
    const jobFamilyStep = useJobFamilyStep();
    const channelsStep = useChannelsStep(jobFamilyStep.selectedItems);
    const topicsStep = useTopicsStep(jobFamilyStep.selectedItems, channelsStep.selectedItems);
    const interestsStep = useInterestsStep(jobFamilyStep.selectedItems, channelsStep.selectedItems, topicsStep.selectedItems);
    const [state, dispatch, render] = useMagicReducer(reducer({
        dialogRef,
        updateUserData,
        refetchBestPractices,
        channelsStep,
        interestsStep,
        jobFamilyStep,
        topicsStep,
    }), initialState, props.externalRef);

    let activeStep: UserSettingsStepData;
    switch (state.step) {
        case UserSettingsDialogStep.JobFamily:
            activeStep = jobFamilyStep;
            break;
        case UserSettingsDialogStep.PrimaryChannels:
            activeStep = channelsStep;
            break;
        case UserSettingsDialogStep.Topics:
            activeStep = topicsStep;
            break;
        case UserSettingsDialogStep.Interests:
            activeStep = interestsStep;
            break;
    }

    const form = useForm({
        disabled: state.isSaving,
        items: {
            search: Input({
                inputMode: "text",
                inputIcon: <SearchIcon outline/>,
                placeholder: activeStep.searchPlaceholder,
            })
        }
    });

    const searchQuery = form.state.data.search;

    const filteredItems = useMemo(() => {
        if (!searchQuery) return activeStep.items;
        return activeStep.items.filter(i => i.toLowerCase().includes(searchQuery.toLowerCase()));
    }, [searchQuery, activeStep.items]);

    const canSelectNewItems = activeStep.selectedItems.length < activeStep.maxSelectedItems;

    const canSave = !state.isSaving && activeStep.isValid;

    const hideCloseButton = !userData?.interests;

    const isLastStep = state.step === stepsLength - 1;

    return (
        <Dialog
            externalRef={dialogRef}
            width={600}
            height={600}
            noCloseButton={hideCloseButton}
            closeOnOutsideClick={!hideCloseButton}
            title={activeStep.title}
            className={"user-settings-dialog"}
            onMenuItemChange={render}
            content={
                <Flex fill column gap={"gap.small"}>
                    <Text content={activeStep.subtitle}/>
                    {form.formItems.search}
                    <Flex wrap className={"user-settings-dialog-items-container"}>
                        {filteredItems.map(item => (
                            <TopicButton
                                key={item}
                                title={item}
                                isSelected={activeStep.selectedItems.includes(item)}
                                onClick={activeStep.toggleItem}
                                disabled={state.isSaving || (!canSelectNewItems && !activeStep.selectedItems.includes(item))}
                                fluid={activeStep.fluidItems}
                            />
                        ))}
                    </Flex>
                </Flex>
            }
            footerShadow
            footer={
                <Flex space={"between"} vAlign={"center"}>
                    <Text
                        className={"primary-color"}
                        content={activeStep.selectedItemsLabel}
                    />
                    {isLastStep ?
                        <Button
                            primary
                            content={translations.get("Save")}
                            icon={<SaveIcon size={"large"} outline/>}
                            onClick={dispatch("save")}
                            disabled={!canSave}
                            loading={state.isSaving}
                        />
                        :
                        <Button
                            primary
                            content={translations.get("Next")}
                            icon={<ArrowRightIcon outline/>}
                            iconPosition={"after"}
                            onClick={dispatch("nextStep")}
                            disabled={!canSave}
                            loading={state.isSaving}
                        />
                    }
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////