import React, {ReactElement, useEffect, useMemo} from "react";
import {ImmutableObject, MagicReducerRef, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ItemsView.reducer";
import "./ItemsView.styles.scss";
import {ItemDataModule} from "../../modules/ItemData.module";
import {Flex, SearchIcon, Text} from "@fluentui/react-northstar";
import {translations} from "../../translations";
import moment from "moment";
import {ScreenModule} from "../../modules/Screen.module";
import {useMsTeamsSelector} from "hooks/useMsTeams";
import {ItemDialog} from "../../components/dialogs/ItemDialog/ItemDialog";
import {Searchbar} from "../../components/others/Searchbar/Searchbar";
import {AutoWidthMenu} from "../../components/others/AutoWidthMenu/AutoWidthMenu";
import {useUserDataCache} from "../../hooks/cache/userDataCache";
import {ItemData} from "common";
import {ItemsGrid} from "../../components/others/ItemsGrid/ItemsGrid";
import {Props} from "./ItemsView.types";
import {IFilterItem} from "../../components/others/Filter/Filter.types";
import {useMyManagerRecommendationsCache} from "../../hooks/cache/recommendationsCache";
import {NoResultBanner} from "../../components/others/NoResultBanner/NoResultBanner";

export const ItemsView = (props: Props): ReactElement | null => {
    const {userId, userMail} = useMsTeamsSelector("userId", "userMail");
    const [state, dispatch] = useMagicReducer(reducer(userId, props.cacheKey), initialState(props.cacheKey));
    const itemDialogRef = useMagicReducerRef(ItemDialog);
    const searchBarRef = useMagicReducerRef(Searchbar);
    const menuRef = useMagicReducerRef(AutoWidthMenu);
    const itemsGridRef = useMagicReducerRef(ItemsGrid);
    const {hasUserVisitedItem} = useUserDataCache();
    const {managerRecommendations} = useMyManagerRecommendationsCache();

    useEffect(() => {
        itemsGridRef.dispatch("scrollTop")();
    }, [state.selectedCategoryIndex]);

    const isSmallScreen = ScreenModule.isSmallScreen();

    const categories = useMemo(() => ItemDataModule.generateCategories(props.items), [props.items]);

    let selectedFromDate = (searchBarRef.state?.filterItems?.["from-date"]?.inputValue ?? "") + "";
    if (!selectedFromDate) selectedFromDate = moment().startOf("day").add(-1, "year").format("YYYY-MM-DD");
    let selectedToDate = (searchBarRef.state?.filterItems?.["to-date"]?.inputValue ?? "") + "";
    if (!selectedToDate) selectedToDate = moment().startOf("day").format("YYYY-MM-DD");

    const filters = useMemo(() => {
        const clonedFilterItems = [...props.filterItems] as Array<IFilterItem>;
        const fromDateItem = clonedFilterItems.find(i => i.key === "from-date");
        if (fromDateItem?.input) {
            fromDateItem.input.max = selectedToDate;
            fromDateItem.input.value = selectedFromDate;
        }
        const toDateItem = clonedFilterItems.find(i => i.key === "to-date");
        if (toDateItem?.input) {
            toDateItem.input.min = selectedFromDate;
            toDateItem.input.value = selectedToDate;
        }
        return clonedFilterItems;
    }, [props.items, selectedFromDate, selectedToDate]);

    const query = (searchBarRef.state?.query ?? "").toLowerCase();

    const filterItems = searchBarRef.state?.filterItems;

    const filteredBestPractices = useMemo(() => {
        const selectedCategory = state.selectedCategoryIndex !== 0 ? categories?.[state.selectedCategoryIndex] : undefined;
        return ItemDataModule.filterItems({
            items: props.items,
            query,
            filterItems,
            selectedCategoryKey: selectedCategory?.key,
            hasUserVisitedItem,
            userUpn: userMail,
            managerRecommendations,
        });
    }, [state.selectedCategoryIndex, props.items, categories, query, filterItems, managerRecommendations]);

    const itemDialog = <ItemDialog externalRef={itemDialogRef}/>;

    const header = (
        <Flex className={"w-100"} column gap={"gap.medium"}>
            {!isSmallScreen &&
                <Flex column hAlign={"center"} styles={{gap: "3px"}}>
                    <Text
                        className={"brand-color-400"}
                        size={"larger"}
                        weight={"bold"}
                        content={props.title}
                    />
                    <Text
                        className={"brand-color-400"}
                        content={props.subtitle}
                    />
                </Flex>
            }
            <Flex column className={"w-100"} hAlign={"center"}
                  styles={isSmallScreen ? {padding: "0 20px"} : undefined}
                  gap={isSmallScreen ? "gap.medium" : "gap.small"}>
                <Searchbar
                    id={props.cacheKey + "searchbar"}
                    throttling={600}
                    externalRef={searchBarRef}
                    styles={isSmallScreen ? {width: "100%"} : {minWidth: "500px", maxWidth: "800px"}}
                    placeholder={translations.get("SearchByKeyword") + "..."}
                    onSearch={dispatch("handleSearch")}
                    filterItems={filters}
                />
                <Flex className={"w-100"}>
                    <AutoWidthMenu
                        externalRef={menuRef}
                        align={"center"}
                        defaultMenuIndex={state.selectedCategoryIndex}
                        items={categories}
                        onIndexChange={dispatch("handleClickMenuItem", categories)}
                    />
                </Flex>
            </Flex>
        </Flex>
    )

    return (
        <Flex fill column className={"items-view no-select"}>
            <Flex fill hAlign={"center"} column className={"items-view-content"}>
                {header}
                {filteredBestPractices.areSuggestions && <NoResultBanner/>}
                {renderItemsList(filteredBestPractices.items, itemsGridRef)}
            </Flex>
            {itemDialog}
        </Flex>
    )
}

const renderItemsList = (
    filteredBestPractices: readonly ImmutableObject<ItemData>[],
    itemsGridRef: MagicReducerRef<typeof ItemsGrid>,
) => {
    return (
        <Flex column fill hAlign={"center"} vAlign={"center"} className={"items-grid-container"}>
            {filteredBestPractices?.length === 0 ?
                <Flex column hAlign={"center"} vAlign={"center"} gap={"gap.small"} styles={{marginTop: "50px"}}>
                    <SearchIcon outline size={"largest"}/>
                    <Text size={"large"} content={translations.get("NoResults")}/>
                </Flex>
                :
                <ItemsGrid externalRef={itemsGridRef} items={filteredBestPractices}/>
            }
        </Flex>
    )
}