import React, {ReactElement, useMemo} from "react";
import {translations} from "../../translations";
import {ItemsView} from "../ItemsView/ItemsView";
import {TrainingsModule} from "../../modules/Trainings.module";
import {useTrainingsCache} from "../../hooks/cache/trainingsCache";

export const trainingsViewCacheKey = "trainings-view";

export const Trainings = (): ReactElement => {
    const {trainings} = useTrainingsCache();

    const filterItems = useMemo(() => {
        return TrainingsModule.generateFilterItems(trainings);
    }, [trainings]);

    return (
        <ItemsView
            title={translations.get("Trainings")}
            subtitle={translations.get("TrainingsSubtitle")}
            items={trainings}
            cacheKey={trainingsViewCacheKey}
            filterItems={filterItems}
        />
    )
}