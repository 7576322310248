import React, {memo, ReactElement, useEffect, useLayoutEffect, useMemo, useRef} from "react";
import {NavbarItem, Props} from "./Navbar.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {generateItems, initialState, reducer} from "./Navbar.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Navbar.styles.scss";
import {useNavigate} from "react-router-dom";
import {Button, Flex, Image, MenuIcon, Text} from "@fluentui/react-northstar";
import StarvoiceLogo from "assets/images/starvoice_logo.svg";
import {translations} from "translations";
import {SideMenu} from "./SideMenu/SideMenu";
import {ScreenModule} from "../../../modules/Screen.module";
import {getAppVersion} from "../../../services/ConfigurationService/ConfigurationService";
import {AppView} from "../../../types/AppView/AppView";
import {AppViews} from "../../../const/AppViews";
import {HomeRegular, LightbulbFilamentRegular, QuestionCircleRegular} from "@fluentui/react-icons";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {Launchpad} from "../Launchpad/Launchpad";
import {useMsTeamsSelector} from "../../../hooks/useMsTeams";

export const Navbar = memo((props: Props): ReactElement | null => {
    const {isOnMobile} = useMsTeamsSelector("isOnMobile");
    const {shareItemDialog} = useDialogContext();
    const sideMenuRef = useMagicReducerRef(SideMenu);
    const buttonsParentContainerRef = useRef<HTMLDivElement | null>(null);
    const buttonsContainerRef = useRef<HTMLDivElement | null>(null);

    const [state, dispatch] = useMagicReducer(reducer({
        props,
        buttonsParentContainerRef,
        buttonsContainerRef,
        isOnMobile
    }), initialState(props));

    const navigate = useNavigate();

    const {isAdmin, isCommunityManager} = useUserRolesCache();

    useLayoutEffect(() => {
        dispatch("windowResize")();
        window.addEventListener("resize", dispatch("windowResize"));
        return () => window.removeEventListener("resize", dispatch("windowResize"));
    }, []);

    useEffect(dispatch("onMount", navigate), []);

    const isSmallScreen = ScreenModule.isSmallScreen();

    const generatedItems = useMemo(() => generateItems(isSmallScreen, isAdmin, isCommunityManager), [isAdmin, isCommunityManager]);

    const appVersionLabel = useMemo(() => getAppVersion(), []);

    const appVersion = (
        <Text className={"navbar-app-version"} title={appVersionLabel} size={"smaller"} content={appVersionLabel}/>
    )

    if (isSmallScreen) return <>
        <SideMenu
            onItemSelected={dispatch("handleMenuItemSelected", navigate)}
            defaultSelectedView={AppView.Home}
            externalRef={sideMenuRef}
            items={generatedItems}
        />
        <Flex className={"navbar"} vAlign={"center"}>
            <Button
                styles={{color: "white !important", marginLeft: "-10px"}} text iconOnly
                icon={<MenuIcon size={"large"}/>}
                onClick={sideMenuRef.dispatch("handleOpen")}
            />
            <Text
                truncated
                styles={{color: "white"}}
                size={"larger"}
                weight={"semibold"}
                content={translations.get(AppViews[sideMenuRef.state?.selectedView ?? AppView.Home].titleId)}
            />
            {appVersion}
        </Flex>
    </>

    const logo = (
        <Image
            className={"starvoice-logo"}
            src={StarvoiceLogo}
            style={{cursor: "pointer"}}
            onClick={dispatch("handleNavigateToView", {navigate, id: AppView.Home})}
        />
    )

    const items = (
        <Flex fill styles={{overflow: "inherit"}} vAlign={"center"} ref={buttonsParentContainerRef}>
            <Flex vAlign={"center"} styles={{gap: "25px"}} ref={buttonsContainerRef}>
                {generatedItems.map(i => renderItem(
                    i,
                    state.activeItem === i.id,
                    dispatch("handleNavigateToView", {navigate, id: i.id, clearCachedStates: true}),
                    state.smallButtons
                ))}
            </Flex>
        </Flex>
    )

    const homeButton = (
        <Flex className={"home-button"} column hAlign={"center"} vAlign={"center"} title={translations.get("Home")}
              onClick={dispatch("handleNavigateToView", {navigate, id: AppView.Home})}>
            <HomeRegular fontSize={40}/>
        </Flex>
    )

    const enrichLibraryButton = (
        <Flex className={"enrich-library-button"} column hAlign={"center"} vAlign={"center"}
              onClick={shareItemDialog.dispatch("open")}>
            <LightbulbFilamentRegular fontSize={50}/>
            <Text styles={{}} content={translations.get("EnrichLibrary")}/>
        </Flex>
    )

    const helpButton = (
        <Flex className={"help-button"} column hAlign={"center"} vAlign={"center"} onClick={dispatch("openHelpPage")}>
            <QuestionCircleRegular fontSize={50}/>
            <Text content={translations.get("Help")}/>
        </Flex>
    )

    return (
        <Flex className={"navbar"}>
            {logo}
            {homeButton}
            {items}
            <Launchpad/>
            {enrichLibraryButton}
            {helpButton}
            {appVersion}
        </Flex>
    )
}, CompareModule.areObjectsEqual);

const renderItem = (item: NavbarItem, isActive: boolean, onClick: () => void, smallButton: boolean) => {
    const className = [
        "navbar-button",
        isActive && "navbar-button-active"
    ].filter(i => i).join(" ");
    return (
        <Button
            title={item.label}
            className={className}
            key={item.id}
            content={smallButton ? "" : item.label}
            iconOnly={smallButton}
            icon={<item.icon/>}
            onClick={onClick}
        />
    )
}