import React, {memo, ReactElement} from "react";
import {Props} from "./Home.types";
import {CompareModule} from "modules/Compare.module";
import {Button, DownloadIcon, Flex, Image, Text} from "@fluentui/react-northstar";
import {translations} from "../../../../../translations";
import "./Home.styles.scss";
import BPProcessImage from "assets/images/bp_process.webp";
import {
    CheckmarkCircleRegular,
    FlashRegular,
    FluentIcon,
    ScalesRegular,
    TargetArrowRegular,
    TrophyRegular
} from "@fluentui/react-icons";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Home.reducer";

export const Home = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    return (
        <Flex fill className={"share-item-dialog-home-view"} style={{gap: "20px"}} vAlign={"center"} hAlign={"center"}>
            <Image fluid src={BPProcessImage} alt={"Best Practice Process"}/>
            <Flex fill column style={{gap: "15px"}}>
                <Flex column gap={"gap.small"}>
                    <Text className={"primary-color"} size={"large"} weight={"semibold"}
                          content={translations.get("ShareItemDialogProcessTitle1")}/>
                    <Text weight={"semibold"} content={translations.get("ShareItemDialogProcessTitle2")}/>
                    <Flex column style={{gap: "2px"}}>
                        {renderProcessListItem(1, FlashRegular)}
                        {renderProcessListItem(2, TargetArrowRegular)}
                        {renderProcessListItem(3, TrophyRegular)}
                        {renderProcessListItem(4, ScalesRegular)}
                    </Flex>
                </Flex>
                <Flex column gap={"gap.small"}>
                    <Text className={"primary-color"} size={"large"} weight={"semibold"}
                          content={translations.get("ShareItemDialogProcessTitle3")}/>
                    <Flex gap={"gap.medium"}>
                        <Flex gap={"gap.medium"} className={"pos-relative"}>
                            {[1, 2, 3].map(renderProcessStep)}
                            <div className={"share-item-dialog-home-view-line"}/>
                            <div className={"share-item-dialog-home-view-arrow"}/>
                        </Flex>
                        <Flex column hAlign={"center"} gap={"gap.smaller"} style={{color: "#36bb36"}}>
                            <CheckmarkCircleRegular fontSize={38} style={{marginTop: "-4px", marginLeft: "-4px"}}/>
                            <Text weight={"semibold"} align={"center"}
                                  content={translations.get("ShareItemDialogProcessStep4")}/>
                        </Flex>
                    </Flex>
                    <Flex>
                        <Button
                            style={{maxWidth: "300px"}}
                            icon={<DownloadIcon outline/>}
                            content={translations.get("DownloadPowerpointTemplate")}
                            tinted
                            onClick={dispatch("downloadTemplate")}
                            loading={state.isDownloadingTemplate}
                            disabled={state.isDownloadingTemplate}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);

const renderProcessStep = (step: number) => (
    <Flex key={"process" + step} column hAlign={"center"} gap={"gap.smaller"}>
        <Flex className={"share-item-dialog-home-view-step-badge"}>{step}</Flex>
        <Text weight={"semibold"} align={"center"} content={translations.get("ShareItemDialogProcessStep" + step)}/>
    </Flex>
)

const renderProcessListItem = (index: number, Icon: FluentIcon) => (
    <Flex vAlign={"center"} style={{gap: "4px"}}>
        <Icon fontSize={18} className={"primary-color"} style={{marginTop: "2px"}}/>
        <Text content={translations.get("ShareItemDialogProcessListItem" + index)}/>
    </Flex>
)