import {UserApi} from "../../apis/User/UserApi";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {UserReportCacheActions} from "./userReportCache";
import {ObjectModule} from "../../modules/Object.module";
import {Guid, ItemDataType, UserData, UserVisitedItems} from "common";
import {LocalCacheService} from "../../services/CacheService/CacheService.hook";
import {GraphApi} from "../../apis/Graph/GraphApi";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";
import {QueryClient, useQuery, useQueryClient} from "@tanstack/react-query";

export const userDataCacheKey = "current_user_data";

export const useUserDataCache = (fetchData: boolean = true) => {
    const queryClient = useQueryClient();

    const {data: userData} = useQuery({
        queryKey: [userDataCacheKey],
        queryFn: fetchUserData,
        staleTime: Infinity,
        enabled: fetchData,
    });

    return {
        userData,
        hasUserVisitedItem: hasUserVisitedItem(userData),
        updateUserData: updateUserData(queryClient),
    };
};

export const useUserVisitedItemCache = (type: ItemDataType | undefined, itemId: string | undefined) => {
    const queryClient = useQueryClient();
    const {userData} = useUserDataCache();

    return {
        isVisited: hasUserVisitedItem(userData)(type, itemId),
        markItemVisited: markItemVisitedByUser(queryClient)(type, itemId),
    };
};

const getOldCachedVisitedItems = (): UserVisitedItems => {
    const cachedVisitedItems = LocalCacheService.getItem<Array<string>>("visited_items") ?? [];
    const bestPractices = cachedVisitedItems
        .filter(item => item.startsWith(ItemDataType.BestPractice + "_"))
        .map(item => item.split("_")[1] ?? "")
        .filter(i => i);
    const trainings = cachedVisitedItems
        .filter(item => item.startsWith(ItemDataType.Training + "_"))
        .map(item => item.split("_")[1] ?? "")
        .filter(i => i);
    return {
        bestPractices,
        trainings,
    }
}

const fetchUserData = async (): Promise<Immutable<UserData> | undefined> => {
    let userData = await UserApi.getUserData();
    if (userData) return userData;
    const graphUserData = await GraphApi.me();
    if (!graphUserData) {
        ErrorModule.showErrorAlert("Can't get user data");
        return undefined;
    }
    userData = await UserApi.createUserData({
        id: graphUserData.id as Guid,
        upn: graphUserData.userPrincipalName.toLowerCase(),
        name: graphUserData.displayName,
        jobTitle: graphUserData.jobTitle,
        department: graphUserData.department,
        country: graphUserData.country,
        visitedItems: getOldCachedVisitedItems(),
        interests: undefined,
        lastConnection: new Date().toISOString(),
    });
    return userData;
}

const getUserData = (queryClient: QueryClient): Immutable<UserData> | undefined => {
    return queryClient.getQueryData<Immutable<UserData>>([userDataCacheKey]);
}

const updateUserData = (queryClient: QueryClient) => async (properties: Partial<UserData>) => {
    const userData = getUserData(queryClient);
    const newUserData = {...userData, ...properties} as UserData;
    queryClient.setQueryData([userDataCacheKey], newUserData);
    await UserApi.updateUserData(newUserData);
};

const hasUserVisitedItem = (userData: Immutable<UserData> | undefined) => (type: ItemDataType | undefined, itemId: string | undefined): boolean => {
    if (type === undefined || itemId === undefined) return false;
    if (!userData) return false;
    switch (type) {
        case ItemDataType.BestPractice:
            return userData.visitedItems.bestPractices.includes(itemId);
        case ItemDataType.Training:
            return userData.visitedItems.trainings.includes(itemId);
    }
}

const markItemVisitedByUser = (queryClient: QueryClient) => (type: ItemDataType | undefined, itemId: string | undefined) => () => {
    if (type === undefined || itemId === undefined) return;
    const userData = getUserData(queryClient);
    if (!userData) return ErrorModule.showErrorAlert("Can't mark item as visited");
    const clonedUserData = ObjectModule.deepClone<UserData>(userData) as UserData;
    switch (type) {
        case ItemDataType.BestPractice:
            if (!clonedUserData.visitedItems.bestPractices.includes(itemId)) {
                clonedUserData.visitedItems.bestPractices.push(itemId);
                UserReportCacheActions.incrementReportValue("exploredBestPractices");
            }
            break;
        case ItemDataType.Training:
            if (!clonedUserData.visitedItems.trainings.includes(itemId)) {
                clonedUserData.visitedItems.trainings.push(itemId);
                UserReportCacheActions.incrementReportValue("exploredTrainings");
            }
            break;
    }
    updateUserData(queryClient)(clonedUserData).then();
}