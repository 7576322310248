import {State} from "./Home.interfaces";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {ItemDataModule} from "../../modules/ItemData.module";
import {BestPracticesModule} from "../../modules/BestPractices.module";
import {TrainingsModule} from "../../modules/Trainings.module";
import {HistoryService} from "../../services/HistoryService/HistoryService.hook";
import {ReportingModule} from "../../modules/Reporting.module";
import {Guid, ItemData, ItemDataType} from "common";
import {AutoWidthMenu} from "../../components/others/AutoWidthMenu/AutoWidthMenu";
import {AppView} from "../../types/AppView/AppView";
import {FilterItemsData, IFilterItem} from "../../components/others/Filter/Filter.types";
import {DialogContextValue} from "../../services/DialogContext/DialogContext.types";

export const homeViewCacheKey = "home-view";

export const initialState = (): State => {
    const historyState = HistoryService.getComponentState<State>(homeViewCacheKey);
    if (historyState) return {
        ...historyState,
        slidesPerView: getSlidesPerView(),
    }
    return {
        slidesPerView: getSlidesPerView(),
        selectedBestPracticesCategoryIndex: 0,
        selectedSearchResultsCategoryIndex: 0,
        searchResults: undefined,
        areSearchResultsSuggestions: false,
    }
}

const saveState = (state: Immutable<State>) => {
    const {selectedBestPracticesCategoryIndex, selectedSearchResultsCategoryIndex} = state;
    HistoryService.saveComponentState(homeViewCacheKey, {
        selectedBestPracticesCategoryIndex,
        selectedSearchResultsCategoryIndex,
    });
}

export const reducer = (
    bestPractices: Immutable<Array<ItemData>> | undefined,
    trainings: Immutable<Array<ItemData>> | undefined,
    newsDialog: DialogContextValue["newsDialog"],
    categories: Array<{ key: string, content: string }> | undefined,
    searchesMenuRef: MagicReducerRef<typeof AutoWidthMenu>,
    userId: Guid,
    userUpn: string,
) => ({
    handleEditMessageOfTheDay: (_, __, community: string | undefined) => {
        newsDialog.dispatch("handleOpen", community)();
    },
    handleClickBestPracticesMenuItem: (reducerData, [index]: [number]) => {
        const selectedItem = categories?.[index];
        if (!selectedItem) return;
        const {state, setState} = reducerData;
        if (state.selectedBestPracticesCategoryIndex === index) return;
        setState({selectedBestPracticesCategoryIndex: index});
        saveState(reducerData.state);
        ReportingModule.notifyTopicSelected(userId, selectedItem.key, AppView.Home).then();
    },
    handleClickSearchResultsMenuItem: (reducerData, [index]: [number]) => {
        const selectedItem = categories?.[index];
        if (!selectedItem) return;
        const {setState} = reducerData;
        setState({selectedSearchResultsCategoryIndex: index});
        saveState(reducerData.state);
    },
    handleSearch: (reducerData, [value, filterItems]: [string, FilterItemsData | undefined]) => {
        ReportingModule.notifyQuery(userId, value, AppView.Home).then();
        const {setState} = reducerData;
        setState({searchResults: undefined});
        const {items: filteredBestPractices, areSuggestions: areBPSuggestions} = ItemDataModule.filterItems({
            items: bestPractices,
            query: value,
            filterItems,
            userUpn,
        }) ?? [];
        const {items: filteredTrainings, areSuggestions: areTrainingsSuggestions} = ItemDataModule.filterItems({
            items: trainings,
            query: value,
            filterItems,
            userUpn,
        }) ?? [];
        const areSearchResultsSuggestions = areBPSuggestions && areTrainingsSuggestions;
        const searchResults: Array<Immutable<ItemData>> = [];
        if (areSearchResultsSuggestions) {
            searchResults.push(...filteredBestPractices);
            searchResults.push(...filteredTrainings);
        } else {
            if (!areBPSuggestions) searchResults.push(...filteredBestPractices);
            if (!areTrainingsSuggestions) searchResults.push(...filteredTrainings);
        }
        setState({searchResults, areSearchResultsSuggestions});
    },
    handleOpenLink: (_, __, link: string) => {
        window.open(link, "_blank");
    },
    render: (reducerData) => {
        reducerData.render();
    },
    showSearchMoreItems: (_, __, type: ItemDataType) => {
        let index = 0;
        switch (type) {
            case ItemDataType.BestPractice:
                index = 1;
                break;
            case ItemDataType.Training:
                index = 2;
                break;
        }
        searchesMenuRef.dispatch("setActiveMenuIndex")(index);
    },
    windowResize: ({state, setState}) => {
        const slidesPerView = getSlidesPerView();
        if (state.slidesPerView === slidesPerView) return;
        setState({slidesPerView});
    },
}) satisfies MagicReducerObject<State>;

export const generateSearchResultsCategories = () => {
    return [
        {key: "all", content: "All"},
        {key: "best-practices", content: "Best Practices"},
        {key: "trainings", content: "Trainings"},
    ]
}

export const getSlidesPerView = () => {
    return Math.round(window.innerWidth / 450);
}

export const generateFilterItems = (
    bestPractices: Immutable<Array<ItemData>> | undefined,
    trainings: Immutable<Array<ItemData>> | undefined,
    selectedFromDate: string,
    selectedToDate: string,
): Array<IFilterItem> => {
    const bpFilters = BestPracticesModule.generateFilterItems(bestPractices) as Array<IFilterItem>;
    const trainingsFilters = TrainingsModule.generateFilterItems(trainings) as Array<IFilterItem>;
    const mergeFilterItems = (filterKey: string): IFilterItem | undefined => {
        const items = bpFilters.find(f => f.key === filterKey);
        const trainingsFilter = trainingsFilters.find(f => f.key === filterKey);
        if (!items && !trainingsFilter) return undefined;
        const existingItemKeys = items?.menu?.items?.map(i => i.key) ?? [];
        const trainingsItems = (trainingsFilter?.menu?.items ?? []) as Array<IFilterItem>;
        const newItems = trainingsItems.filter(i => !existingItemKeys.includes(i.key));
        if (items?.menu?.items) items.menu.items = items.menu?.items.concat(newItems);
        return items;
    };
    const fromDateFilter = bpFilters.find(f => f.key === "from-date");
    if (fromDateFilter?.input) {
        fromDateFilter.input.max = selectedToDate;
        fromDateFilter.input.value = selectedFromDate;
    }
    const toDateFilter = bpFilters.find(f => f.key === "to-date");
    if (toDateFilter?.input) {
        toDateFilter.input.min = selectedFromDate;
        toDateFilter.input.value = selectedToDate;
    }
    return [
        mergeFilterItems("job-family"),
        mergeFilterItems("topic"),
        fromDateFilter,
        toDateFilter,
    ].filter(Boolean) as Array<IFilterItem>;
}