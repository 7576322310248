import {UserSettingsDialogStep, UserSettingsStepData} from "../UserSettingsDialog.types";
import {useBestPracticesCache} from "../../../../hooks/cache/bestPracticesCache";
import {useEffect, useMemo, useState} from "react";
import {BestPracticesModule} from "../../../../modules/BestPractices.module";
import {translations} from "../../../../translations";
import {useUserDataCache} from "../../../../hooks/cache/userDataCache";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {HistoryService} from "../../../../services/HistoryService/HistoryService.hook";

const selectedItemsCacheKey = "useTopicsStep-selectedItems";

export const useTopicsStep = (jobFamilies: Immutable<Array<string>>, channels: Immutable<Array<string>>): UserSettingsStepData => {
    const {userData} = useUserDataCache();
    const {bestPractices} = useBestPracticesCache();
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    useEffect(() => {
        const items = userData?.interests?.topics ?? HistoryService.getComponentState<Array<string>>(selectedItemsCacheKey) ?? [];
        setSelectedItems([...items]);
    }, [userData?.interests?.topics]);

    useEffect(() => {
        HistoryService.saveComponentState<Array<string>>(selectedItemsCacheKey, selectedItems);
    }, [selectedItems]);

    const items = useMemo(() => {
        const filteredBestPractices = bestPractices?.filter(bp => (
            jobFamilies.some(jf => bp.jobFamilies.includes(jf)) &&
            channels.some(c => bp.channels.includes(c))
        ));
        return BestPracticesModule.getTopics(filteredBestPractices)
    }, [bestPractices, jobFamilies, channels]);

    const maxSelectedItems = 5;

    return {
        step: UserSettingsDialogStep.Topics,
        isValid: selectedItems.length > 0 && selectedItems.length <= maxSelectedItems,
        items,
        selectedItems,
        title: translations.get("UserSettingsTopicsTitle"),
        subtitle: translations.get("UserSettingsTopicsSubtitle"),
        toggleItem: (item) => setSelectedItems((prev) => prev.includes(item) ? prev.filter((t) => t !== item) : [...prev, item]),
        maxSelectedItems,
        searchPlaceholder: translations.get("SearchTopic"),
        selectedItemsLabel: translations.get("YouHaveSelectedXTopics", {count: selectedItems.length + "/" + maxSelectedItems}),
    }
}