import React, {memo, ReactElement, useCallback, useEffect, useMemo} from "react";
import {Props} from "./NewsDialog.types";
import {Immutable, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./NewsDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {AddIcon, Button, Divider, Flex, RetryIcon, SaveIcon, SendIcon, TrashCanIcon} from "@fluentui/react-northstar";
import "./NewsDialog.styles.scss";
import {useAllNewsCache} from "../../../hooks/cache/useNewsCache";
import {ScreenModule} from "../../../modules/Screen.module";
import {useEditMessageForm} from "../../../hooks/forms/useEditMessageForm";
import {translations} from "../../../translations";
import {useAllCommunitiesCache} from "../../../hooks/cache/useCommunityCache";
import {NewsDialogButton} from "./NewsDialogButton/NewsDialogButton";
import {CommunityModule} from "../../../modules/Community.module";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {VideoAddRegular} from "@fluentui/react-icons";
import {useUserDataCache} from "../../../hooks/cache/userDataCache";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";
import {AutoWidthMenu} from "../../others/AutoWidthMenu/AutoWidthMenu";
import {FileModule} from "../../../modules/File.module";
import {Dropdown, useForm} from "@witivio_teamspro/northstar-form";
import {News} from "common";
import moment from "moment";
import {NewsModule} from "../../../modules/News.module";

export const NewsDialog = memo((props: Props): ReactElement | null => {
    const {confirmCancelChangesDialog, filePickerDialog} = useDialogContext();
    const {isAdmin} = useUserRolesCache();
    const {userData} = useUserDataCache();
    const {news, upsertItem: upsertNews} = useAllNewsCache();
    const {communities} = useAllCommunitiesCache();
    const dialogRef = useMagicReducerRef(Dialog);
    const reducerConfig = {props, dialogRef, news, upsertNews, confirmCancelChangesDialog, filePickerDialog, isAdmin};
    const [state, dispatch] = useMagicReducer(reducer(reducerConfig), initialState, props.externalRef);

    const visibleCommunityNews = useMemo(() => {
        return news?.filter(n =>
            n.community !== CommunityModule.generalNewsId &&
            n.show &&
            userData?.interests?.channels.includes(n.community)
        ).map(n => n.community) ?? [];
    }, [news, userData?.interests?.channels]);

    const communitiesItems = useMemo(() => {
        return CommunityModule.generateMenuItems(isAdmin ? communities : visibleCommunityNews);
    }, [communities, visibleCommunityNews, isAdmin]);

    const communityNews = useMemo(() => {
        return NewsModule.sortNews(news ?? []).filter(n => n.community === state.selectedCommunity)
    }, [state.selectedCommunity, news]);

    const activeIndex = communitiesItems?.findIndex(item => item.id === state.selectedCommunity);

    const newsTitles = useMemo(() => getCommunityNewsTitles(communityNews), [communityNews]);

    const form = useForm({
        items: {
            newsId: Dropdown({
                items: communityNews?.map(n => n.id.toString()) ?? [],
                renderSelectedItem: (id) => newsTitles?.find(n => n.id.toString() === id)?.title ?? "?",
                placeholder: translations.get("UpdateANews"),
            })
        }
    });

    const selectedNewsId = form.state.data.newsId;

    useEffect(function onSelectedNewsIdChange() {
        dispatch("handleSelectNews", selectedNewsId)();
    }, [selectedNewsId]);

    useEffect(function onSelectedCommunityChange() {
        resetAllForms();
    }, [state.selectedCommunity]);

    const selectedNews = communityNews.find(n => n.id.toString() === selectedNewsId);

    const {messageForm, buttonsForm} = useEditMessageForm(selectedNews, state.buttons, state.isSaving);

    const currentMessage = messageForm.state.data.message ?? "";

    useEffect(function onCurrentMessageChange() {
        if (!dialogRef.state?.isOpen) return;
        dispatch("saveDraftMessage")(currentMessage);
    }, [currentMessage]);

    const hasVideoChanged = state.selectedMedia?.itemId !== selectedNews?.selectedMedia?.itemId;

    const canSave = !state.isSaving && messageForm.isValid && buttonsForm.isValid
        && (messageForm.hasChanged || buttonsForm.hasChanged || hasVideoChanged);

    const canAddButton = !state.isSaving && state.buttons.length < 5;

    const canRetrieveDraft = !state.isSaving && !!state.draftMessage && state.draftMessage.community === state.selectedCommunity;

    const willPublishNews = messageForm.state.data.visible;

    const MediaIcon = useMemo(() => {
        return getMediaIcon(state.selectedMedia?.name ?? "");
    }, [state.selectedMedia]);

    const isSmallScreen = ScreenModule.isSmallScreen();

    const resetAllForms = useCallback(() => {
        form.reset();
        messageForm.reset();
        buttonsForm.reset();
    }, [form.reset, messageForm.reset, buttonsForm.reset]);

    const menu = (
        <AutoWidthMenu
            className={"news-dialog-menu"}
            defaultMenuIndex={activeIndex}
            items={communitiesItems}
            onIndexChange={dispatch("switchCommunity", {canSave, menuItems: communitiesItems})}
        />
    )

    const mediaButton = (
        <Flex gap={"gap.smaller"}>
            <Button
                fluid
                icon={!state.selectedMedia ?
                    <VideoAddRegular className={"custom-icon"}/> :
                    <MediaIcon size={"large"}/>
                }
                content={state.selectedMedia?.name || translations.get("SelectAMedia")}
                onClick={dispatch("handleSelectVideo")}
                primary={!!state.selectedMedia}
                disabled={state.isSaving}
            />
            {state.selectedMedia &&
                <Button
                    styles={{marginRight: "-6px"}}
                    iconOnly
                    text
                    icon={<TrashCanIcon outline/>}
                    onClick={dispatch("handleClearVideo")}
                    disabled={state.isSaving}
                />
            }
        </Flex>
    )

    const buttons = (
        <Flex fill column className={"overflow-hidden"} gap={"gap.small"}>
            <Flex className={"no-shrink"}>
                <Button
                    fluid icon={<AddIcon outline/>}
                    content={translations.get("AddButton")}
                    onClick={dispatch("handleAddButton")}
                    disabled={!canAddButton}
                />
            </Flex>
            <Flex fill column className={"overflow-scroll"} gap={"gap.small"}>
                {state.buttons.map((b, index) => (
                    <NewsDialogButton
                        key={b.id}
                        button={b}
                        label={translations.get("CustomButton") + " " + (index + 1)}
                        titleInput={buttonsForm.formItems[b.id + "_title"]}
                        linkInput={buttonsForm.formItems[b.id + "_link"]}
                        onChangeColor={dispatch("handleSetButtonColor", b.id)}
                        onDelete={dispatch("handleDeleteButton", b.id)}
                        disabled={state.isSaving}
                    />
                ))}
            </Flex>
        </Flex>
    );

    const footer = (
        <Flex className={"w-100"} space={"between"} vAlign={"center"} gap={"gap.small"}>
            <Flex fill styles={{maxWidth: "400px"}}>
                {form.formItems.newsId}
            </Flex>
            <Button
                primary={willPublishNews}
                tinted={!willPublishNews}
                icon={willPublishNews ? <SendIcon outline/> : <SaveIcon size={"large"} outline/>}
                content={translations.get(willPublishNews ? "Publish" : "Save")}
                onClick={dispatch("handleSave", {messageForm, buttonsForm, selectedNews})}
                disabled={!canSave}
                loading={state.isSaving}
            />
        </Flex>
    )

    return (
        <Dialog
            title={translations.get("News")}
            width={1000}
            noPadding
            footerShadow
            externalRef={dialogRef}
            closeOnOutsideClick={false}
            fullscreen={isSmallScreen}
            onClose={dispatch("onClose", resetAllForms)}
            content={
                <Flex fill column gap={"gap.medium"} className={"news-dialog"}>
                    {menu}
                    <Flex fill column={isSmallScreen} gap={"gap.medium"} className={"overflow-hidden"}
                          styles={{height: isSmallScreen ? "100%" : "330px", padding: "0 25px 10px 25px"}}>
                        <Flex fill column gap={"gap.small"}>
                            {canRetrieveDraft &&
                                <Flex>
                                    <Button
                                        icon={<RetryIcon outline/>} fluid
                                        content={translations.get("RetrieveDraft")}
                                        onClick={dispatch("handleRetrieveDraft", messageForm)}
                                    />
                                </Flex>
                            }
                            <Flex fill column gap={"gap.small"}>
                                <Flex fill>
                                    {messageForm.formItems["message"]}
                                </Flex>
                                {mediaButton}
                                {messageForm.formItems["visible"]}
                            </Flex>
                        </Flex>
                        <Divider vertical={!isSmallScreen}/>
                        {buttons}
                    </Flex>
                </Flex>
            }
            footer={footer}
        />
    )
}, CompareModule.areObjectsEqual);

const getMediaIcon = (name: string) => {
    const extension = FileModule.getFileExtension(name);
    const type = FileModule.getFileType(extension);
    return FileModule.getFileIcon(type);
}

const getCommunityNewsTitles = (news: Immutable<Array<News>>) => {
    return news.map(n => {
        const doc = new DOMParser().parseFromString(n.message, 'text/html');
        return {
            id: n.id,
            title: doc.body.textContent?.slice(0, 30) ?? translations.get("News") + " " + moment(n.createdAt).format("LLL"),
        }
    });
}