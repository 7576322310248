import {Configuration} from "common";
import {ConfigurationApi} from "apis/Configuration/ConfigurationApi";
import {AxiosConfig} from "apis/AxiosConfig/AxiosConfig";
import {LocalCacheService, SessionCacheService} from "../CacheService/CacheService.hook";

export const getConfig = async () => {
    const configuration: Configuration | null = await ConfigurationApi.getConfiguration();
    if (!configuration) return null;
    SessionCacheService.setItem("app_configuration", configuration);
    const apiBaseUrl = `https://${window.location.host}/api/${configuration?.apiVersion}`;
    AxiosConfig.setAxiosInstanceApiBaseUrl(apiBaseUrl);
    return configuration;
};

export const getAppConfiguration = () => {
    return SessionCacheService.getItem<Configuration>("app_configuration");
}

export const getAppVersion = (): string => {
    const version = LocalCacheService.getItem<string>("app_version") ?? "";
    if (!version) return "";
    return "v" + version;
}